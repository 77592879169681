import React from "react";
import { Link } from "react-router-dom";
import Table from "rc-table";
import styled from "styled-components";

import {
  Column,
  Row,
  Circle,
  MainWrapper,
  Header,
  Content,
  H3,
  H4,
  Label,
  Avatar,
  Anchor,
  List,
  Dropdown,
  Spinner,
  DateRange,
  Card,
  RowToColumn,
  Tabs,
  Icon,
  AutoCompleteWithResponses,
  Pill,
  HR,
  Grid,
  NoContent,
  Menu,
  Filters,
  Summary,
  Toast,
  ExportModal,
  FilterModal,
  Blured,
  Dictionary,
  Metadata,
  TweetCard,
  Checkbox,
} from "../../components";
import { WorldMapChart } from "../../components/WorldMapChart";
import { tweetHighlight, formatNumberWithCommas } from "../../utils";
import { Color } from "../../utils/variables";

const TopSection = styled(Column)`
  background: ${Color("gray", "0.5")};
  padding: 20px;
  margin: -20px -20px 0;
  border-bottom: 1px solid ${Color("gray", "3")};
`;

export const view = ({
  isAdmin,
  isRegistered,
  userType,
  hcp,
  isFetchingHCP,
  contentRef,
  tabs,
  activeTab,
  handleSelectedTab,

  followedRef,
  followed, // account followed by
  followedNextPage,
  followedLoading,
  followedByCountry,

  follows, // account follows
  followsRef,
  followsNextPage,
  followsLoading,
  followsByCountry,

  showExport,
  expotVisibility,
  toggleExportVisibility,
  showFilter,
  filterVisibility,
  toggleFilterVisibility,
  exportCSVFollowed,
  exportCSVFollows,
  exportCSVForTweets,

  loading,
  tweetsReady,
  showCalendar,
  setShowCalendar,
  tweetsRef,
  tweetsNextPage,
  tweets,
  tweetsCount,
  counter,
  activeFilters,

  dates,
  handleDateChange,

  sortByOptions,
  sortBy,
  handleSortByChange,

  tags,
  searchTag,
  handleSearchTag,
  clearTag,
  selectedTags,
  addSelectedTag,
  removeSelectedTag,
  tagsInfinteScroll,
  handleClearFilters,
  allKeywords,
  mainKeywords,
  products,
  loadingProducts,
  sortTableBy,
  sortTableDirection,
  handleSortTableBy,
  handleSortTableDirection,

  npiTaxonomies,

  showArticles,
  loadingTopArticles,
  topArticles,

  sentimentTypes,
  selectedSentiment,
  handleSelectedSentiment,

  tweetTypes,
  selectedTweetType,
  handleTweetTypeChange,

  handleSignUpClick,

  AIKeywords,
  isFetchingAI,
  aiResponse,
  hideAI,
  handleAiRefresh,
  isNewAI,
  setIsNewAI,
  refreshAI,
  isAISuccess,

  savedOnly,
  handleSavedOnly,

  visible,
  toggleVisibility,
  errorMessage,
  error,

  showToast,
  toggleToast,
  tagSummary,
  accountAttributes,
}) => (
  <MainWrapper drawer>
    <Metadata
      title={
        hcp?.accountType === "PERSON"
          ? `Dr. ${hcp?.name} ${
              tagSummary && `- top influencer in ${tagSummary}`
            }`
          : `${hcp?.name} ${tagSummary && `- top institution in ${tagSummary}`}`
      }
      description={hcp?.description}
      image={hcp?.imageURL?.replace("_bigger", "")}
    />
    <Header
      drawer
      search
      back
      login
      user
      signUpClick={handleSignUpClick}
      source={`account`}
    />
    <Menu />
    <Content ref={contentRef}>
      <Column>
        <Row gap="20" marginBottom middle>
          {hcp.imageURL && (
            <Avatar
              large
              isFetching={isFetchingHCP}
              imageUrl={hcp.imageURL}
              name={hcp.name}
            />
          )}
          <Column left gap="2">
            {hcp.name && <H3 bold>{hcp.name}</H3>}
            {hcp.username && (
              <Anchor
                label={`@${hcp.username}`}
                blank
                to={`https://twitter.com/${hcp.username}`}
              />
            )}
          </Column>
          {isAdmin && (
            <Row fit right noShrink>
              <Anchor bold label="Edit" to={`/editAccount/${hcp.id}`} />
            </Row>
          )}
        </Row>
        <Tabs active={activeTab} {...{ tabs }} onChange={handleSelectedTab} />{" "}
        <Column border="gray" fade="3" paddingAll>
          {activeTab === "summary" && (
            <SummarySection
              {...{
                isAdmin,
                userType,
                hcp,
                counter,
                products,
                loadingProducts,
                handleSelectedTab,
                contentRef,
                addSelectedTag,
                dates,
                handleDateChange,
                showCalendar,
                setShowCalendar,
                npiTaxonomies,
                showArticles,
                loadingTopArticles,
                topArticles,
                accountAttributes,
              }}
            />
          )}

          {activeTab === "charts" && (
            <Tweets
              {...{
                isRegistered,
                isAdmin,
                userType,
                hcp,
                loading,
                tweetsReady,
                showCalendar,
                setShowCalendar,
                tweetsRef,
                tweetsNextPage,
                tweets,
                tweetsCount,
                counter,
                activeFilters,
                dates,
                handleDateChange,
                sortByOptions,
                sortBy,
                handleSortByChange,
                tags,
                searchTag,
                handleSearchTag,
                clearTag,
                selectedTags,
                addSelectedTag,
                removeSelectedTag,
                tagsInfinteScroll,
                handleClearFilters,
                allKeywords,
                mainKeywords,
                sentimentTypes,
                selectedSentiment,
                handleSelectedSentiment,
                tweetTypes,
                selectedTweetType,
                handleTweetTypeChange,
                showExport,
                expotVisibility,
                toggleExportVisibility,
                showFilter,
                toggleFilterVisibility,
                exportCSVForTweets,
                savedOnly,
                handleSavedOnly,
                AIKeywords,
                isFetchingAI,
                aiResponse,
                hideAI,
                handleAiRefresh,
                isNewAI,
                setIsNewAI,
                refreshAI,
                isAISuccess,
              }}
            />
          )}
          {activeTab === "followed" && (
            <>
              {followed.length > 0 ? (
                <Column>
                  <Row right noShrink>
                    <Anchor
                      bold
                      label="Export"
                      // icon="download"
                      onClick={() =>
                        showExport
                          ? exportCSVFollowed()
                          : toggleExportVisibility(true)
                      }
                    />
                  </Row>
                  <WorldMapChart
                    id={`worldmapChart-1`}
                    data={followedByCountry}
                    handleNameClick={() => {}}
                  />
                  <Hcps
                    data={followed}
                    loading={followedLoading}
                    nextPage={followedNextPage}
                    hcpRef={followedRef}
                    {...{
                      sortTableBy,
                      sortTableDirection,
                      handleSortTableBy,
                      handleSortTableDirection,
                    }}
                  />
                </Column>
              ) : (
                <>
                  {!followedLoading && (
                    <Row expand paddingAll center>
                      <Label gray>No data found</Label>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
          {activeTab === "follows" && (
            <>
              {follows.length > 0 ? (
                <Column>
                  <Row right noShrink>
                    <Anchor
                      bold
                      label="Export"
                      // icon="download"
                      onClick={() =>
                        showExport
                          ? exportCSVFollows()
                          : toggleExportVisibility(true)
                      }
                    />
                  </Row>
                  <WorldMapChart
                    id={`worldmapChart-2`}
                    data={followsByCountry}
                    handleNameClick={() => {}}
                  />
                  <Hcps
                    data={follows}
                    loading={followsLoading}
                    nextPage={followsNextPage}
                    hcpRef={followsRef}
                    {...{
                      sortTableBy,
                      sortTableDirection,
                      handleSortTableBy,
                      handleSortTableDirection,
                    }}
                  />
                </Column>
              ) : (
                <>
                  {!followsLoading && (
                    <Row expand paddingAll center>
                      <Label gray>No data found</Label>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </Column>
        <Toast
          {...{ visible, toggleVisibility }}
          warning={error}
          expiration={4000}
        >
          <Row gap={error ? "10" : "5"} middle>
            <Icon name={error ? "warning" : "check"} />
            <Label>{errorMessage}</Label>
          </Row>
        </Toast>
        <FilterModal
          visible={filterVisibility}
          toggleVisibility={() => toggleFilterVisibility(!filterVisibility)}
        />
      </Column>
    </Content>
    <ExportModal
      visible={expotVisibility}
      toggleVisibility={() => toggleExportVisibility(!expotVisibility)}
    />
    <Toast
      visible={showToast}
      toggleVisibility={toggleToast}
      warning
      expiration={3000}
    >
      <Row gap="10" middle>
        <Icon color="white" name="warning" />
        <Label color="white" bold>
          You can only select 5 keywords
        </Label>
      </Row>
    </Toast>
  </MainWrapper>
);

const SummarySection = ({
  isAdmin,
  userType,
  hcp,
  counter,
  products,
  loadingProducts,
  handleSelectedTab,
  contentRef,
  addSelectedTag,
  dates,
  handleDateChange,
  showCalendar,
  setShowCalendar,
  npiTaxonomies,
  showArticles,
  loadingTopArticles,
  topArticles,
  accountAttributes,
}) => {
  const navigateToChart = (product, disease) => {
    if (disease) {
      addSelectedTag(
        [
          {
            id: product.id,
            label: product.name,
          },
          {
            id: disease.id,
            label: disease.name,
          },
        ],
        true
      );
    } else {
      addSelectedTag([{ id: product.id, label: product.name }], true);
    }
    setTimeout(() => {
      handleSelectedTab("charts");
      contentRef.current?.scrollTo(0, 0);
    }, 200);
  };

  return (
    <Column>
      {hcp && (
        <Column>
          <TopSection>
            {hcp.institutionName && (
              <Row marginBottom="10">
                <Label bold>{hcp.institutionName}</Label>
              </Row>
            )}

            {hcp.description && (
              <Label>{tweetHighlight({ text: hcp.description })}</Label>
            )}

            {hcp.institution?.name && (
              <Row gap="10" marginTop="10">
                <Label bold>{hcp?.institution?.name}</Label>
              </Row>
            )}

            {(hcp.institution?.displayLocation || hcp.displayLocation) && (
              <Row gap="10" marginTop="10">
                <Label>
                  {hcp?.institution?.displayLocation || hcp?.displayLocation}
                </Label>
              </Row>
            )}

            {/* {(hcp.npiId || hcp.npiTaxonomies?.length > 0) && (
              <Column marginTop="30">
                <H4 color="gray" fade="6" bold large>
                  NPI
                </H4>
                <Row gap="30" marginTop="10" middle>
                  {hcp.npiId && (
                    <>
                      <Label color="gray" fade="6">Id:</Label>
                      <Label>{hcp.npiId}</Label>
                    </>
                  )}
                  {hcp.npiTaxonomies?.length > 0 && (
                    <>
                      <Label color="gray" fade="6">Taxonomy:</Label>
                      <Label>{npiTaxonomies.find(n => n.id === hcp.npiTaxonomies[0].taxonomyCode)?.name || ""}</Label>
                    </>
                  )}
                </Row>
              </Column>
            )} */}
            {hcp.username && (
              <Column marginTop="30">
                <H4 color="gray" fade="6" bold large>
                  Contact
                </H4>
                <Row gap="30" marginTop="10" middle>
                  <Link
                    to={{ pathname: `https://twitter.com/${hcp.username}` }}
                    target="_blank"
                  >
                    <Icon name="twitter" color="green" fade="6" />
                  </Link>
                  {hcp.linkedInURL && (
                    <Link
                      to={{
                        pathname: `https://www.linkedin.com/in/${hcp.linkedInURL}`,
                      }}
                      target="_blank"
                    >
                      <Icon name="linkedin" color="green" fade="6" />
                    </Link>
                  )}
                  {hcp.youtubeChannel?.channelHandle && (
                    <Link
                      to={{
                        pathname: `https://www.youtube.com/${hcp.youtubeChannel?.channelHandle}`,
                      }}
                      target="_blank"
                    >
                      <Icon name="yt" size="32" color="green" fade="6" />
                    </Link>
                  )}
                  {hcp.url && (
                    <Link to={{ pathname: hcp.url }} target="_blank">
                      <Icon name="link" color="green" fade="6" />
                    </Link>
                  )}
                </Row>
              </Column>
            )}

            <Row gap="40" marginTop>
              {hcp.numFollowers && (
                <Column gap="5">
                  <H4 color="gray" fade="6" bold large>
                    Followers
                  </H4>
                  <Label bold></Label>
                  <H4 bold>{formatNumberWithCommas(hcp.numFollowers, 1)}</H4>
                </Column>
              )}
              {hcp.numFollowers && (
                <Column gap="5">
                  <H4 color="gray" fade="6" bold large>
                    Following
                  </H4>
                  <H4 bold>{formatNumberWithCommas(hcp.numFollowing, 1)}</H4>
                </Column>
              )}
              {hcp.numTweets && (
                <Column gap="5">
                  <H4 color="gray" fade="6" bold large>
                    Posts
                  </H4>
                  <H4 bold>{formatNumberWithCommas(hcp.numTweets, 1)}</H4>
                </Column>
              )}
            </Row>
          </TopSection>

          {userType !== "OTHER" && userType !== "HCP" && (
            <Column marginTop>
              <Filters
                {...{ counter }}
                noToggle
                datePicker={
                  dates.start && (
                    <Row gap="5" middle>
                      {counter && (
                        <Row marginLeft="5">
                          <Label>from</Label>
                        </Row>
                      )}
                      <DateRange
                        noBackground
                        id="date-range"
                        period
                        initialDate={dates.start}
                        endDate={dates.end}
                        onChange={handleDateChange}
                        noFutureDates
                        {...{ showCalendar, setShowCalendar }}
                      />
                    </Row>
                  )
                }
              />
            </Column>
          )}

          <Card border marginTop="20" paddingAll="40">
            <Column gap="5">
              <H4 bold>
                <Dictionary word={showArticles ? "Top articles" : "Products"} />
              </H4>
              {hcp.accountType === "PERSON" ? (
                <Label color="gray" fade="7">
                  Dr. {hcp.lastName || hcp.name} posts about these{" "}
                  <Dictionary word={showArticles ? "articles" : "products"} />:
                </Label>
              ) : (
                <Label color="gray" fade="7">
                  {hcp.name} posts about these{" "}
                  {showArticles ? "articles" : "products"}:
                </Label>
              )}

              {!showArticles && (
                <>
                  {loadingProducts ? (
                    <Row expand paddingAll center>
                      <Spinner />
                    </Row>
                  ) : (
                    <>
                      {products.length > 0 ? (
                        <>
                          {products.map((prod, i) => (
                            <Column key={prod.id} marginTop>
                              {i !== 0 && (
                                <Column marginBottom="20">
                                  <HR />
                                </Column>
                              )}
                              <Grid threeToOne>
                                <H4 bold>{prod.name}</H4>
                                {prod?.sentimentAverage ||
                                prod?.sentimentAverage === 0 ? (
                                  <Sentiment value={prod?.sentimentAverage} />
                                ) : (
                                  <Row show="tablet" />
                                )}
                                <Row show="tablet" right>
                                  <Anchor
                                    label="See Posts"
                                    onClick={() =>
                                      navigateToChart({
                                        id: prod.id,
                                        name: prod.name,
                                      })
                                    }
                                  />
                                </Row>
                                <Row hide="tablet">
                                  <Anchor
                                    label="See Posts"
                                    onClick={() =>
                                      navigateToChart({
                                        id: prod.id,
                                        name: prod.name,
                                      })
                                    }
                                  />
                                </Row>
                              </Grid>
                              {prod?.otherTags?.map((tag) => (
                                <Column key={tag.id} marginTop>
                                  <Grid threeToOne>
                                    <Label bold>{tag.name}</Label>
                                    <Row>
                                      <Sentiment
                                        value={tag?.sentimentAverage}
                                      />
                                    </Row>
                                    {/* <Row show="tablet" right>
                                      <Anchor
                                        label="See Posts"
                                        onClick={() =>
                                          navigateToChart(
                                            {
                                              id: prod.id,
                                              name: prod.name,
                                            },
                                            tag
                                          )
                                        }
                                      />
                                    </Row>
                                    <Row hide="tablet" marginTop="-10">
                                      <Anchor
                                        label="See Posts"
                                        onClick={() =>
                                          navigateToChart(
                                            {
                                              id: prod.id,
                                              name: prod.name,
                                            },
                                            tag
                                          )
                                        }
                                      />
                                    </Row> */}
                                  </Grid>
                                </Column>
                              ))}
                            </Column>
                          ))}
                        </>
                      ) : (
                        <Row paddingTop expand center>
                          <Label>No products found</Label>
                        </Row>
                      )}
                    </>
                  )}
                </>
              )}

              {showArticles && (
                <>
                  {loadingTopArticles ? (
                    <Row expand paddingAll center>
                      <Spinner />
                    </Row>
                  ) : (
                    <>
                      {topArticles.length > 0 ? (
                        <>
                          {topArticles.map((arc, i) => (
                            <Column key={arc.externalTweetId} marginTop>
                              {i !== 0 && (
                                <Column marginBottom="30">
                                  <HR />
                                </Column>
                              )}
                              <Row middle marginBottom="5">
                                <Label>{i + 1}.&nbsp;</Label>
                                <Link
                                  to={{
                                    pathname:
                                      arc.url.indexOf("%3A") > -1
                                        ? decodeURIComponent(arc.url)
                                        : arc.url,
                                  }}
                                  target="_blank"
                                >
                                  <Label color="green">
                                    {arc.ogTitle.length > 110
                                      ? arc.ogTitle.substring(0, 105) + "..."
                                      : arc.ogTitle}
                                  </Label>
                                </Link>
                              </Row>
                              <Row marginBottom="5">
                                <Label color="gray" fade="0.3" bold>
                                  {
                                    new URL(
                                      arc.url.indexOf("%3A") > -1
                                        ? decodeURIComponent(arc.url)
                                        : arc.url
                                    ).hostname
                                  }
                                </Label>
                              </Row>
                              {/* <Row marginBottom="20">
                                  <Label>{arc.url.indexOf("%3A") > -1 ? decodeURIComponent(arc.url) : arc.url}</Label>
                                </Row> */}
                              {(arc.totalTweets > 0 ||
                                arc.totalLikes > 0 ||
                                totalViews > 0) && (
                                <Row gap="20" marginBottom="20">
                                  {arc.totalTweets > 0 && (
                                    <Label color="gray" fade="0.3" bold>
                                      {arc.totalTweets}+ posts
                                    </Label>
                                  )}
                                  {arc.totalLikes > 0 && (
                                    <Label color="gray" fade="0.3" bold>
                                      {arc.totalTweets}+ likes
                                    </Label>
                                  )}
                                  {arc.totalViews > 0 && (
                                    <Label color="gray" fade="0.3" bold>
                                      {arc.totalTweets}+ views
                                    </Label>
                                  )}
                                </Row>
                              )}
                              {arc.tweet && (
                                <Link
                                  to={`/post/${arc.tweet.externalId}?source=account`}
                                >
                                  <TweetCard data={arc.tweet} />
                                </Link>
                              )}
                            </Column>
                          ))}
                        </>
                      ) : (
                        <Row paddingTop expand center>
                          <Label>No articles found</Label>
                        </Row>
                      )}
                    </>
                  )}
                </>
              )}
            </Column>
          </Card>

          {isAdmin && accountAttributes.length > 0 && (
            <Card border marginTop="20" paddingAll="40">
              <Column gap="5">
                <H4 bold>Attributes</H4>
                {accountAttributes.map((attr, i) => (
                  <Column key={attr.id} marginTop>
                    <Row left>
                      <Label color="gray" bold fade="7">
                        {attr.attributeName.replaceAll("_", " ")}
                      </Label>
                    </Row>
                    <Row right>
                      <Label bold>{attr.attributeValue}</Label>
                    </Row>
                  </Column>
                ))}
              </Column>
            </Card>
          )}
        </Column>
      )}
    </Column>
  );
};

const Sentiment = ({ value }) => (
  <>
    {value > 0 && (
      <Row gap="5" middle>
        <Circle radius="8" bkg="green" />
        <Label>Advocate</Label>
      </Row>
    )}
    {value === 0 && (
      <Row gap="5" middle>
        <Circle radius="8" bkg="gray" />
        <Label>Undecided</Label>
      </Row>
    )}
    {value < 0 && (
      <Row gap="5" middle>
        <Circle radius="8" bkg="red" />
        <Label>Detractor</Label>
      </Row>
    )}
  </>
);

const Tweets = ({
  isRegistered,
  isAdmin,
  userType,
  hcp,
  loading,
  tweetsReady,
  showCalendar,
  setShowCalendar,
  tweetsRef,
  tweetsNextPage,
  tweets,
  counter,
  activeFilters,
  dates,
  handleDateChange,
  sortByOptions,
  sortBy,
  handleSortByChange,
  tags,
  searchTag,
  handleSearchTag,
  clearTag,
  selectedTags,
  addSelectedTag,
  removeSelectedTag,
  tagsInfinteScroll,
  handleClearFilters,
  allKeywords,
  sentimentTypes,
  selectedSentiment,
  handleSelectedSentiment,
  tweetTypes,
  selectedTweetType,
  handleTweetTypeChange,
  showExport,
  expotVisibility,
  toggleExportVisibility,
  showFilter,
  toggleFilterVisibility,
  exportCSVForTweets,

  savedOnly,
  handleSavedOnly,

  AIKeywords,
  isFetchingAI,
  aiResponse,
  hideAI,
  handleAiRefresh,
  isNewAI,
  setIsNewAI,
  refreshAI,
  isAISuccess,
}) => (
  <Column>
    <Column marginBottom="10">
      <Filters
        counter={userType !== "OTHER" && userType !== "HCP" && counter}
        {...{ activeFilters }}
        onClearFilters={handleClearFilters}
        datePicker={
          userType !== "OTHER" &&
          userType !== "HCP" &&
          dates.start && (
            <Row gap="5" middle>
              {counter && (
                <Row marginLeft="5">
                  <Label>from</Label>
                </Row>
              )}
              <DateRange
                noBackground
                id="date-range"
                period
                initialDate={dates.start}
                endDate={dates.end}
                onChange={handleDateChange}
                noFutureDates
                {...{ showCalendar, setShowCalendar }}
              />
            </Row>
          )
        }
        footer={
          selectedTags.length > 0 && (
            <Row flexWrap marginTop="10" marginLeft>
              {selectedTags.map((t, index) => (
                <Row>
                  {index !== 0 && (
                    <Row marginRight="8" marginLeft="-2" marginTop="3">
                      <Label color="gray" fade="7">
                        or
                      </Label>
                    </Row>
                  )}
                  <Pill
                    key={t.id}
                    bkg="green"
                    fade="2"
                    label={t.label}
                    close
                    onClose={() => removeSelectedTag({ id: t.id })}
                  />
                </Row>
              ))}
            </Row>
          )
        }
      >
        <Grid columns={2}>
          <Column gap="10">
            <Label bold>Keywords</Label>

            <div
              onClick={(e) => {
                if (!showFilter) {
                  toggleFilterVisibility(true);
                  e.stopPropagation();
                }
              }}
              className="pointer"
            >
              {showFilter ? (
                <AutoCompleteWithResponses
                  showOnEmptyTerm
                  searchResults={tags}
                  icon="search"
                  placeholder="Biomarker or other"
                  searchTerm={searchTag}
                  setSearchTerm={handleSearchTag}
                  selectedItems={selectedTags}
                  addItem={addSelectedTag}
                  removeItem={removeSelectedTag}
                  clearSearchResults={clearTag}
                  searchLoading={false}
                  infiniteScroll={tagsInfinteScroll}
                  hidePills
                  hasClearButton
                  showSuggestion
                />
              ) : (
                <div
                  onClick={(e) => {
                    if (!showFilter) {
                      toggleFilterVisibility(true);
                      e.stopPropagation();
                    }
                  }}
                  className="pointer"
                >
                  <Dropdown
                    expand
                    placeholder="Biomarker or other"
                    value={[]}
                    items={null}
                    disabled
                    onChange={() => {}}
                  />
                </div>
              )}
            </div>
          </Column>
          <Column gap="10">
            <Label bold>Sentiment</Label>
            <div
              onClick={(e) => {
                if (!showFilter) {
                  toggleFilterVisibility(true);
                  e.stopPropagation();
                }
              }}
              className="pointer"
            >
              <Dropdown
                select
                expand
                items={sentimentTypes}
                value={selectedSentiment}
                onChange={handleSelectedSentiment}
                disabled={!showFilter}
              />
            </div>
          </Column>

          <Column gap="10">
            <Checkbox
              label="Only show saved posts"
              checked={savedOnly}
              onChange={handleSavedOnly}
            />
          </Column>

          {/* <Column gap="10">
            <Label bold>Type</Label>
            <Dropdown
              right
              select
              value={selectedTweetType}
              items={tweetTypes}
              onChange={(e) => handleTweetTypeChange(e)}
            />
          </Column> */}
        </Grid>
      </Filters>
    </Column>
    {isRegistered && !hideAI && (
      <Column marginBottom>
        <Summary
          keywords={AIKeywords}
          isFetching={isFetchingAI}
          isSuccess={isAISuccess}
          response={aiResponse}
          {...{
            addSelectedTag,
            handleAiRefresh,
            isNewAI,
            setIsNewAI,
            refreshAI,
          }}
        />
      </Column>
    )}

    {tweets.length > 0 && (
      <Column marginY>
        <H4 bold>Posts</H4>
        <Row marginTop expand middle>
          <Row middle gap="40">
            <Dropdown
              select
              value={sortBy}
              label="Sort By"
              items={sortByOptions}
              onChange={(e) => handleSortByChange(e)}
            />

            {userType !== "OTHER" && userType !== "HCP" && (
              <Row noShrink>
                <Anchor
                  bold
                  label="Export"
                  // icon="download"
                  onClick={() =>
                    showExport
                      ? exportCSVForTweets()
                      : toggleExportVisibility(true)
                  }
                />
              </Row>
            )}
          </Row>
        </Row>
      </Column>
    )}

    <Column maxWidth="700">
      <List>
        {tweets.map((t, index) => {
          const hasQuote = t?.quoted?.length > 0;

          return (
            <Column
              stretch
              id={`tweet-${t.externalId}`}
              key={t.externalId}
              marginBottom
            >
              <Blured
                enabled={isRegistered}
                text={
                  index === 0 && (
                    <Label>
                      <Anchor bold label="Sign in" to="/login" />
                      <Row marginX="8">
                        <Label bold>or</Label>
                      </Row>
                      <Row marginRight="8">
                        <Anchor bold label="Sign up" to="/" />
                      </Row>
                      <Label bold>to see posts</Label>
                    </Label>
                  )
                }
              >
                <Link
                  to={`/post/${
                    t.externalId
                  }?source=${hcp?.accountType?.toLowerCase()}`}
                >
                  <Card border={hasQuote} paddingAll={!hasQuote ? "0" : "20"}>
                    <TweetCard
                      data={t}
                      {...{ hasQuote }}
                      keywords={allKeywords}
                    />
                    {hasQuote && (
                      <Column marginTop="30">
                        <TweetCard
                          key={t.quoted[0].externalId}
                          data={t.quoted[0]}
                        />
                      </Column>
                    )}
                  </Card>
                  {/* {t?.medias?.length > 0 && (
                    <Row marginTop="10">
                      <TweetMedia medias={t.medias} grid />
                    </Row>
                  )}
                  {t?.quoted?.length > 0 && (
                    <Column>
                      <Row flexWrap expand>
                        {t?.quoted.map((i) => (
                          <TweetCard
                            id={`quoted-tweet-${t.externalId}`}
                            key={i.externalId}
                            data={i}
                          />
                        ))}
                      </Row>
                    </Column>
                  )}
                </TweetCard> */}
                </Link>
              </Blured>
            </Column>
          );
        })}
      </List>
      {(loading || tweetsNextPage) && isRegistered ? (
        <Row expand paddingAll="40" center ref={tweetsRef}>
          <Spinner />
        </Row>
      ) : (
        <>
          {tweets.length === 0 && tweetsReady && (
            <NoContent
              onDateClick={() => setShowCalendar(true)}
              onClearFilters={handleClearFilters}
            />
          )}
        </>
      )}
    </Column>
  </Column>
);

const Hcps = ({
  data,
  loading,
  nextPage,
  hcpRef,
  sortTableBy,
  sortTableDirection,
  handleSortTableBy,
  handleSortTableDirection,
}) => {
  const getClassNames = (title) => {
    if (sortTableBy === title) {
      return sortTableDirection === "asc" ? "sort-asc" : "sort-desc";
    }
    return "";
  };

  const headerActions = (key) => ({
    onClick() {
      if (sortTableBy === key) {
        handleSortTableDirection(sortTableDirection === "asc" ? "desc" : "asc");
      } else {
        handleSortTableBy(key);
      }
    },
  });

  const columns = [
    {
      title: "Physicians",
      key: "name",
      render: (cell) => (
        <Row
          middle
          gap="10"
          onClick={() => (window.location = `/account/${cell?.id}?tab=summary`)}
        >
          <Avatar imageUrl={cell?.imageURL} name={cell?.name} />
          <Label bold>{cell?.name}</Label>
        </Row>
      ),
      className: getClassNames("name"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Location",
      key: "location",
      render: (cell) => (
        <Label>
          {cell.institution?.displayLocation || cell?.displayLocation}
        </Label>
      ),
      className: getClassNames("location"),
      // NOTE : sorting unavailable on location name !!!
      // onHeaderCell: (header) => headerActions(header.key),
    },
    // {
    //   title: "Country",
    //   key: "country",
    //   render: (cell) => (
    //     <Label>
    //       {cell?.country !== "Unknown" && cell?.country !== "Undetermined"
    //         ? cell?.country
    //         : ""}
    //     </Label>
    //   ),
    //   className: getClassNames("country"),
    //   onHeaderCell: (header) => headerActions(header.key),
    // },
    {
      title: "Institution",
      key: "institutionName",
      render: (cell) => <Label>{cell?.institutionName}</Label>,
      className: getClassNames("institutionName"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    /*
    {
      title: "Mentions",
      key: "mentions",
      render: (cell) => <Label>{cell?.mentions}</Label>,
      // className: getClassNames("mentions"),
      // onHeaderCell: (header) => headerActions(header.key),
    },
    */
    {
      title: "Audience",
      key: "numFollowers",
      render: (cell) => (
        <Label>{formatNumberWithCommas(cell?.numFollowers)}</Label>
      ),
      className: getClassNames("numFollowers"),
      onHeaderCell: (header) => headerActions(header.key),
    },
  ];

  return (
    <Column marginTop>
      <Table
        rowKey={"id"}
        className="sortable"
        {...{ data, columns }}
        style={{ maxWidth: 938 }}
        scroll={{ x: 936 }}
      />
      {(loading || nextPage) && (
        <Row expand paddingAll="40" center ref={hcpRef}>
          <Spinner />
        </Row>
      )}
    </Column>
  );
};
