import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Color } from "../utils/variables";
import { Icon } from "./Icon";
import { Row, Column, Circle } from "./Layout";
import { Label } from "./Typography";

const LabelContainer = styled.label`
  ${(props) => !props.disabled && `cursor: pointer;`}
  display: flex;
  ${(props) => props.expand && `width: 100%`};
`;

const FlexContainer = styled(Row)`
  height: 50px;
`;

const StyledCheckbox = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid ${Color("gray", 7)};
  border-radius: 3px;
  margin-right: 10px;
  transition: all 0.2s ease;
  ${(props) => props.disabled && `opacity: 0.4`};

  & > * {
    position: absolute;
    color: white;
    opacity: 0;
    top: -3px;
    left: -4px;
    pointer-events: none;
    transition: all 0.2s ease 0.2s;
  }

  &.checked {
    background: ${Color("green", "6")};
    border-color: transparent;

    & > * {
      opacity: 1;
    }
  }
`;

const StyledRadio = styled(StyledCheckbox)`
  border-radius: 11px;
  & > div {
    margin-top: 7px;
    margin-left: 8px;
  }
`;

const StyledCheckboxChecked = styled(StyledCheckbox)`
  background: ${Color("green", "6")};
  border-color: ${Color("green", "6")};
`;

const StyledRadioChecked = styled(StyledRadio)`
  background: ${Color("green", "6")};
  border-color: ${Color("green", "6")};
`;

const StyledSwitch = styled.div`
  display: block;
  position: relative;
  width: 30px;
  height: 12px;
  background: ${Color("gray", 4)};
  border-radius: 6px;
  margin-left: 3px;
  ${(props) => props.disabled && `opacity: 0.4`};
`;

const StyledSwitchThumb = styled.div`
  position: absolute;
  top: -5px;
  left: 0;
  width: 22px;
  height: 22px;
  background: ${Color("gray", 6)};
  border-radius: 11px;
  margin-right: 10px;
  color: white;
  transition: all 0.1s ease;
  transform: translateX(-3px);

  &.checked {
    background: ${Color("green", "6")};
    transform: translateX(11px);
  }
`;

export const StatelessCheckbox = (props) => {
  return (
    <FlexContainer expand={props.expand} onClick={(e) => e.preventDefault()}>
      <LabelContainer
        expand={props.expand}
        disabled={props.disabled}
        data-name={props.dataName || "checkbox-choice"}
        onClick={(e) => {
          e.preventDefault();
          !props.disabled && props.onChange();
        }}
      >
        <Row middle expand={props.expand}>
          <StyledCheckbox
            disabled={props.disabled}
            className={props.selected ? "checked" : ""}
          >
            {props.selected && <Icon name="check" color="white" />}
          </StyledCheckbox>
          {props.children ? (
            props.children
          ) : (
            <Label bold={props.bold}>{props.label}</Label>
          )}
        </Row>
      </LabelContainer>
    </FlexContainer>
  );
};

export const Checkbox = (props) => {
  const [checked, setChecked] = React.useState(props.checked);

  const toggleCheck = React.useCallback(() => {
    setChecked(!checked);
    props.onChange && props.onChange(!checked);
  });

  React.useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <StatelessCheckbox
      onChange={toggleCheck}
      selected={checked}
      label={props.label}
      bold={props.bold}
      disabled={props.disabled || (props.disableUnselected && !checked)}
    />
  );
};

export const StaticCheckbox = (props) => (
  <FlexContainer>
    <Row middle>
      <Icon name="multipleChoiceReadOnly" />
      <Label bold={props.bold}>{props.label}</Label>
    </Row>
  </FlexContainer>
);

export const StaticRadio = (props) => (
  <FlexContainer>
    <Row middle>
      <Icon name="singleChoiceReadOnly" />
      <Label bold={props.bold}>{props.label}</Label>
    </Row>
  </FlexContainer>
);

export const StatelessRadio = (props) => {
  return (
    <FlexContainer middle>
      <LabelContainer
        data-name={props.dataName || "radio-choice"}
        onClick={props.onChange}
      >
        <Row middle>
          <StyledRadio className={props.selected ? "checked" : ""}>
            {props.selected && <Icon name="check" color="white" />}
          </StyledRadio>
          <Label bold={props.bold}>{props.label}</Label>
        </Row>
      </LabelContainer>
    </FlexContainer>
  );
};

export const Radio = (props) => {
  const [checked, setChecked] = React.useState(props.checked);

  const toggleCheck = React.useCallback(() => {
    setChecked(!checked);
    props.onChange(!checked);
  });

  return (
    <StatelessRadio
      onChange={toggleCheck}
      selected={checked}
      label={props.label}
    />
  );
};

export const StatelessSwitch = (props) => {
  return (
    <FlexContainer marginY={props.marginY || "0"} expand={props.expand}>
      <LabelContainer
        expand={props.expand}
        disabled={props.disabled}
        onClick={() => !props.disabled && props.onChange()}
      >
        <Row middle reverse={props.right} expand={props.expand}>
          <StyledSwitch disabled={props.disabled}>
            <StyledSwitchThumb className={props.selected ? "checked" : ""} />
          </StyledSwitch>
          <Row marginLeft="15" flex={1} />
          {props.children ? (
            props.children
          ) : (
            <Column>
              <Label bold={!props.disabled}>{props.label}</Label>
              {props.selected && props.labelOn && (
                <Label bold>{props.labelOn}</Label>
              )}
              {!props.selected && props.labelOff && (
                <Label bold>{props.labelOff}</Label>
              )}
            </Column>
          )}
        </Row>
      </LabelContainer>
    </FlexContainer>
  );
};

export const SwitchButton = (props) => {
  return (
    <FlexContainer marginY={props.marginY || "0"} expand={props.expand}>
      <LabelContainer
        expand={props.expand}
        disabled={props.disabled}
        onClick={() => !props.disabled && props.onClick()}
      >
        <Row middle reverse={props.right} expand={props.expand}>
          <StyledSwitch disabled={props.disabled}>
            <StyledSwitchThumb className={props.selected ? "checked" : ""} />
          </StyledSwitch>
          <Row marginLeft="10" flex={1} />
          {props.children ? (
            props.children
          ) : (
            <Row>
              <Label bold={!props.disabled}>{props.label}</Label>
              {props.selected && props.labelOn && (
                <Label bold>{props.labelOn}</Label>
              )}
              {!props.selected && props.labelOff && (
                <Label bold>{props.labelOff}</Label>
              )}
            </Row>
          )}
        </Row>
      </LabelContainer>
    </FlexContainer>
  );
};

export const Switch = (props) => {
  const [checked, setChecked] = React.useState(props.checked);

  React.useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const toggleCheck = React.useCallback(() => {
    setChecked(!checked);
    props.onChange(!checked);
  });

  return (
    <StatelessSwitch
      expand={props.expand}
      right={props.right}
      onChange={toggleCheck}
      selected={checked}
      label={props.label}
      labelOn={props.labelOn}
      labelOff={props.labelOff}
      disabled={props.disabled}
    />
  );
};

export const Checkboxes = (props) => {
  const [items, updateItems] = React.useState([]);

  React.useEffect(() => {
    updateItems(props.items);
  }, [props.items]);

  const itemOnChange = (e, value) => {
    let temp = items;
    let index = temp.findIndex((a) => a.value === value);
    temp[index].checked = !temp[index].checked;
    updateItems(temp.concat([]));
    props.onChange(
      props.returnSelected ? { items: temp, selected: value } : temp
    );
  };

  return (
    <Column>
      {items?.map((a) => (
        <Row key={a.id} expand>
          {props.template === "LabelDate" ? (
            <StatelessCheckbox
              expand
              selected={a.checked}
              disabled={a.disabled}
              onChange={(e) => itemOnChange(e, a.value)}
            >
              <Row expand>
                <Label bold={props.bold}>{a.label}</Label>
                <Row fit marginRight="10" />
                <Label noShrink>{a.date}</Label>
              </Row>
            </StatelessCheckbox>
          ) : (
            <StatelessCheckbox
              label={a.label}
              bold={props.bold}
              selected={a.checked}
              disabled={a.disabled}
              onChange={(e) => itemOnChange(e, a.value)}
            />
          )}
        </Row>
      ))}
    </Column>
  );
};

export const Radios = (props) => {
  const [items, updateItems] = React.useState([]);

  React.useEffect(() => {
    updateItems(props.items);
  }, [props.items]);

  const itemOnChange = (value) => {
    let temp = items;
    for (let i in temp) {
      temp[i].checked = temp[i].value === value;
    }
    updateItems(temp.concat([]));
    props.onChange(temp);
  };
  return (
    <Row gap="40">
      {items?.map((a) => (
        <Row key={a.id}>
          {props.disabled ? (
            <Column>
              {a.checked && (
                <StaticRadio label={a.label} selected={a.checked} />
              )}
            </Column>
          ) : (
            <StatelessRadio
              label={a.label}
              selected={a.checked}
              onChange={() => itemOnChange(a.value)}
            />
          )}
        </Row>
      ))}
    </Row>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkboxes.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};

Radio.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Radios.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  right: PropTypes.bool,
  disabled: PropTypes.bool,
};

StaticCheckbox.propTypes = {
  label: PropTypes.string,
};

StaticRadio.propTypes = {
  label: PropTypes.string,
};
