import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";
import { TWEET_ACTIONS, tweetActions } from "../actions";

// We should delete this when using final endpoints
// import { useMock } from "../mocks/mocks";
// const getApiPath = "";
// useMock("tweets");

export let cancelTweetRequests = new AbortController();

function* handleRequest(action) {
  const {
    tagIds, // to OR keywords
    addTagIds, // to AND keywords
    externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    mwUserId,
    pageNum,
    startDate,
    endDate,
    sortBy,
    maxResult,
    regionName,
    source,
    withTags,
    includeDiseasesForConference,
    savedOnly,
  } = action.payload;
  let tagIdList = "";
  if (tagIds?.length > 0) {
    tagIds.forEach((tagId) => {
      tagIdList += `tagId=${tagId}&`;
    });
  }
  let addTagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      addTagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }

  const params = objectToUrlEncoded({
    authorId: externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    mwUserId,
    source,
    startDate,
    endDate,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    sortBy,
    sortOrder: "desc",
    withTags,
    includeDiseasesForConference,
    savedOnly,
  });
  try {
    cancelTweetRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/tweets?${tagIdList}${addTagIdList}${regionList}${params}`,
      { signal: cancelTweetRequests.signal }
    );
    yield put(tweetActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(tweetActions.failure({ error: { ...e } }));
  }
}

function* handleRequestMultiple(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { ids, originalOnly, includeReplies, sortBy } = action.payload;
    const params = objectToUrlEncoded({
      originalOnly: originalOnly || "false",
      includeReplies: includeReplies || true,
      includeImpression: true,
      analyzed: true,
      sortBy: sortBy || "date",
      sortOrder: "desc",
      maxResult: -1,
    });
    const tweetIds = ids.join("&id=");
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweets?id=${tweetIds}&${params}`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.success({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(tweetActions.failure({ error: { ...e } }));
  }
}

// get one tweet
function* handleRequestOne(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id, mwUserId } = action.payload;
    const params = mwUserId ? "?mwUserId="+mwUserId : "";
    const { data } = yield call(axios.get, `${getApiPath(false)}/tweet/${id}${params}`, {
      signal: cancelTweetRequests.signal,
    });
    yield put(tweetActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(tweetActions.oneFailure({ error: { ...e } }));
  }
}

// get replies to a tweet
function* handleTweetReplies(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweet/${id}/replies`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.tweetRepliesSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(tweetActions.tweetRepliesFailure({ error: { ...e } }));
  }
}

// get count of tweets identified by criteria
function* handleTweetsCountRequest(action) {
  const {
    tagIds, // to OR keywords
    addTagIds, // to AND keywords
    externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    mwUserId,
    startDate,
    endDate,
    regionName,
    source,
    withTags,
    savedOnly,
  } = action.payload;
  let tagIdList = "";
  if (tagIds?.length > 0) {
    tagIds.forEach((tagId) => {
      tagIdList += `tagId=${tagId}&`;
    });
  }
  let addTagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      addTagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId: externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    mwUserId,
    startDate,
    endDate,
    source,
    withTags,
    savedOnly,
  });
  try {
    cancelTweetRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/tweets/count?${tagIdList}${addTagIdList}${regionList}${params}`,
      { signal: cancelTweetRequests.signal }
    );
    yield put(tweetActions.tweetsCountSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.tweetsCountFailure({ error: { ...e } }));
  }
}

// get all tweet-tag associations
function* handleRequestTagsAssociation(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath()}/tweet/${id}/tagsAssociated`,
      {
        headers: authHeader(),
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.tagSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.tagFailure({ error: { ...e } }));
  }
}

function* handleRequestTagsAssociationWithSentiments(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweet/${id}/tagsAssociatedWithSentiments`,
      {
        headers: authHeader(),
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.tagSentimentSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.tagSentimentFailure({ error: { ...e } }));
  }
}

// create tweet-tag association
function* handleRequestCreateTagAssociation(action) {
  const { tweetId, tagId } = action.payload.data;
  try {
    cancelTweetRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/tweet/${tweetId}/associate/${tagId}`,
      null,
      {
        headers: authHeader(),
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.tagSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.tagFailure({ error: { ...e } }));
  }
}

// delete tweet-tag association
function* handleRequestDeleteTagAssociation(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { tweetId, tagId } = action.payload.data;
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/tweet/${tweetId}/dissociate/${tagId}`,
      {
        headers: authHeader(),
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.tagSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.tagFailure({ error: { ...e } }));
  }
}

// get urls for tweet replies
function* handleRequestReplyUrls(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { ids } = action.payload;
    const externalIds = ids.join("&externalId=");

    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweets/url?externalId=${externalIds}`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.replyUrlsSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.replyUrlsFailure({ error: { ...e } }));
  }
}

// get accounts who replied a tweet
function* handleRequestAccountsReplied(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;

    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweet/${id}/accountsReplied`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.accountsRepliedSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.accountsRepliedFailure({ error: { ...e } }));
  }
}

// get accounts who retweeted a tweet
function* handleRequestAccountsRetweeted(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;

    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweet/${id}/accountsRetweeted`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.accountsRetweetedSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.accountsRetweetedFailure({ error: { ...e } }));
  }
}

// get accounts who quoted a tweet
function* handleRequestAccountsQuoted(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;

    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweet/${id}/accountsQuoted`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.accountsQuotedSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.accountsQuotedFailure({ error: { ...e } }));
  }
}

// get accounts which retweeted a tweet
function* handleRequestImpressions(action) {
  try {
    cancelTweetRequests = new AbortController();
    const { id } = action.payload;

    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tweet/${id}/impression`,
      {
        signal: cancelTweetRequests.signal,
      }
    );
    yield put(tweetActions.impressionsSuccess({ data }));
  } catch (e) {
    yield put(tweetActions.impressionsFailure({ error: { ...e } }));
  }
}

export const getImpressionByCountry = ({ id }) => {
  return axios.get(
    `${getApiPath(false)}/tweet/${id}/impressionByCountry?maxResult=-1`
  );
};

export const getImpressionByCity = ({ id }) => {
  return axios.get(
    `${getApiPath(false)}/tweet/${id}/impressionByCity?maxResult=3`
  );
};

export const getImpressionByInstitution = ({ id }) => {
  return axios.get(
    `${getApiPath(false)}/tweet/${id}/impressionByInstitution?maxResult=3`
  );
};

export const getTimeSegmentedSummary = ({
  tagIds,
  authorId,
  term,
  originalOnly,
  includeReplies,
  withSentiment,
  sentiment,
  startDate,
  endDate,
  segment,
  regionName,
  source,
  withTags,
}) => {
  let tagIdList = "";
  if (tagIds?.length > 0) {
    tagIds.forEach((tagId) => {
      tagIdList += `tagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    startDate,
    endDate,
    segment,
    regionName,
    source,
    withTags,
  });

  return axios.get(
    `${getApiPath(false)}/tweets/timeSegmentedSummary?${tagIdList}${params}`
  );
};

export const getTweets = ({
  tagIds, // to OR keywords
  addTagIds, // to AND keywords
  externalId,
  term,
  originalOnly,
  includeReplies,
  withSentiment,
  mwUserId,
  sentiment,
  pageNum,
  startDate,
  endDate,
  source,
  sortBy,
  maxResult,
  savedOnly,
}) => {
  let tagIdList = "";
  if (tagIds?.length > 0) {
    tagIds.forEach((tagId) => {
      tagIdList += `tagId=${tagId}&`;
    });
  }
  let addTagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      addTagIdList += `addTagId=${tagId}&`;
    });
  }

  const params = objectToUrlEncoded({
    authorId: externalId,
    term,
    originalOnly,
    includeReplies,
    withSentiment,
    sentiment,
    mwUserId,
    startDate,
    endDate,
    source,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    sortBy,
    sortOrder: "desc",
    savedOnly,
  });

  return axios.get(
    `${getApiPath(false)}/tweets?${tagIdList}${addTagIdList}${params}`
  );
};

function* watchTweetSagas() {
  yield all([
    takeLatest(TWEET_ACTIONS.REQUEST, handleRequest),
    takeLatest(TWEET_ACTIONS.MULTIPLE_REQUEST, handleRequestMultiple),
    takeLatest(TWEET_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(TWEET_ACTIONS.TWEET_REPLIES_REQUEST, handleTweetReplies),
    takeLatest(TWEET_ACTIONS.TWEETS_COUNT_REQUEST, handleTweetsCountRequest),
    takeLatest(TWEET_ACTIONS.TAG_REQUEST, handleRequestTagsAssociation),
    takeLatest(
      TWEET_ACTIONS.TAG_SENTIMENT_REQUEST,
      handleRequestTagsAssociationWithSentiments
    ),
    takeLatest(TWEET_ACTIONS.TAG_CREATE, handleRequestCreateTagAssociation),
    takeLatest(TWEET_ACTIONS.TAG_DELETE, handleRequestDeleteTagAssociation),
    takeLatest(TWEET_ACTIONS.REPLY_URLS_REQUEST, handleRequestReplyUrls),
    takeLatest(
      TWEET_ACTIONS.ACCOUNTS_REPLIED_REQUEST,
      handleRequestAccountsReplied
    ),
    takeLatest(
      TWEET_ACTIONS.ACCOUNTS_RETWEETED_REQUEST,
      handleRequestAccountsRetweeted
    ),
    takeLatest(
      TWEET_ACTIONS.ACCOUNTS_QUOTED_REQUEST,
      handleRequestAccountsQuoted
    ),
    takeLatest(TWEET_ACTIONS.IMPRESSIONS_REQUEST, handleRequestImpressions),
  ]);
}

export default watchTweetSagas;
