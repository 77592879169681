import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { navActions } from "../store/actions";

import { Column, Row, HR } from "./Layout";
import { Anchor } from "./Button";
import { Color, device } from "../utils/variables";
import { List, ListItem } from "./List";

const DrawerOverlay = styled.div`
  background: ${Color("gray", "8")};
  position: fixed;
  top: 63px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s;
  transition: z-index 0s;
  z-index: -1;
  cursor: pointer;

  ${(props) =>
    props.showDrawer &&
    `
    z-index: 70;
    opacity: 0.6;
  `};
  ${(props) =>
    !props.headerMenu
      ? css`
          @media ${device.tabletL} {
            display: none;
          }
        `
      : css`
          @media ${device.laptop} {
            display: none;
          }
        `}
`;

export const DrawerWrapper = styled(Column)`
  ${(props) =>
    props.white
      ? "background: white;"
      : css`
          background: ${Color("gray", "1")};
          border-right: 1px solid ${Color("gray", "4")};
        `};
  color: white;
  position: fixed;
  top: 63px;
  left: 0;
  bottom: 0;
  z-index: 71;
  width: 300px;
  transition: transform 0.4s;
  transform: translateX(-100%);

  ${(props) =>
    props.showDrawer &&
    `
    transform: translateX(0);
    `};

  ${(props) =>
    !props.headerMenu
      ? css`
          @media ${device.tabletL} {
            transform: translateX(0);
            grid-area: drawer;
            position: static;
            z-index: 0;
          }
        `
      : css`
          @media ${device.laptop} {
            display: none;
          }
        `};

  ${List} {
    a {
      width: 100%;

      &:hover {
        background: transparent;
        ${ListItem} {
          ${(props) =>
            props.white
              ? `background: ${Color("gray", "1")}`
              : `background: ${Color("gray", "3")}`};
        }
      }

      &.active {
        background: transparent;
        ${ListItem} {
          ${(props) =>
            props.white
              ? `background: ${Color("green", "1")}`
              : `background: ${Color("green", "3")}`};
        }
      }
    }

    .active {
      ${ListItem} {
        background: ${Color("green", "3")};
        border-radius: 10px;

        &:hover {
          background: ${Color("green", "3")};
        }
      }
    }

    .hover {
      ${ListItem} {
        border-radius: 10px;

        &:hover {
          background: ${Color("gray", "3")};
        }
      }
    }

    ${ListItem} {
      transition: all 0.3s ease;
      border-radius: 10px;
      cursor: pointer;
      color: black;
      padding: 12px 20px;
      margin: 0 0 5px;

      &:hover {
        background: transparent;
      }
    }
  }
`;

export const Drawer = ({
  headerMenu,
  items,
  active,
  itemOnChange,
  children,
  user,
  login,
  pricing,
  signUpClick,
  white,
}) => {
  const dispatch = useDispatch();

  const showDrawer = useSelector((state) => state.nav.showDrawer);
  const auth = useSelector((state) => state.auth.data);

  const closeDrawer = () => {
    dispatch(navActions.set({ showDrawer: false }));
  };

  return (
    <>
      <DrawerWrapper
        noScroll
        {...{ showDrawer, headerMenu, white }}
        onClick={() => (showDrawer ? closeDrawer() : {})}
      >
        <Column scroll paddingTop="10" paddingX="10" paddingBottom="30">
          {children}
          {items && (
            <List marginTop="20">
              {items.map((item) => (
                <Link
                  id={`drawer-${item.id}`}
                  key={item.id}
                  className={active === item.id ? "active" : ""}
                  target={item.blank ? "_blank" : undefined}
                  // href={item.to || "#"}
                  to={item.to}
                >
                  <ListItem
                    onClick={() => (item.blank ? {} : itemOnChange?.(item))}
                  >
                    {item.label}
                  </ListItem>
                </Link>
              ))}
            </List>
          )}

          {(login || pricing || (user && auth.active)) && (
            <Column marginTop="10" marginX="-5">
              <Row hide="mobileXL">
                <HR />
              </Row>
              <Column marginTop="30" marginLeft="15" gap="20">
                {login && !auth.active && (
                  <Column gap="20" hide="mobileXL">
                    <Anchor
                      id="button-login"
                      secondary
                      label="Sign in"
                      to="/login"
                    />
                    <Anchor
                      id="button-create-account"
                      label="Sign up"
                      to="/"
                      onClick={() => {
                        if (signUpClick) signUpClick();
                      }}
                    />
                  </Column>
                )}
                {pricing && (
                  <Column gap="20" hide="mobileXL">
                    <Anchor
                      id="button-login"
                      secondary
                      label="Sign in"
                      to="/login"
                    />
                    <Anchor
                      id="button-create-account"
                      label="Sign up"
                      to="/pricing"
                      onClick={() => {
                        if (signUpClick) signUpClick();
                      }}
                    />
                  </Column>
                )}
                {user && auth.active && (
                  <>
                    <Anchor to="/preferences" label="Manage preferences" />
                    <Column gap="20" hide="mobileXL">
                      {auth?.mwRole === "ADMIN" && (
                        <Anchor to="/admin/highlights" label="Admin settings" />
                      )}
                      <Anchor to="/logout" label="Logout" />
                    </Column>
                  </>
                )}
                <Column gap="20" hide="mobileXL">
                  <Anchor to="/search" label="Search" />
                </Column>
              </Column>
            </Column>
          )}
        </Column>
      </DrawerWrapper>
      <DrawerOverlay
        {...{ showDrawer, headerMenu }}
        onClick={() => closeDrawer()}
      />
    </>
  );
};
