import React, { useRef, useState } from "react";
import useDynamicRefs from "use-dynamic-refs";
import PropTypes from "prop-types";
import * as _ from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Color } from "../utils/variables";
import useKeyPress from "../utils/useKeyPress";
import { Input, Error } from "./Input";
import { Icon } from "./Icon";
import styled from "styled-components";
import { Column, Row, HR } from "./Layout";
import { Spinner } from "./Spinner";
import { Pill } from "./Button";
import { Label } from "./Typography";

// This function will return another function to filter the resultset based on the searchTerm value
const autocompleteFilter = (nameAttr = "label", searchTerm = "") => {
  return (elem) =>
    elem[nameAttr]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    !searchTerm;
};

const ResultsContainer = styled(Column)`
  border-radius: 15px;
  background-color: white;
  position: absolute;
  z-index: 12;
  width: 100%;
  top: -2px;
  opacity: 0;
  transition: all 0.2s ease;
  pointer-events: none;
  max-height: 280px;
  overflow-y: auto;
  transform: translateY(20px);
  border: 1px solid ${Color("gray", "1")};
  box-shadow: 0px 10px 10px rgba(54, 65, 241, 0.1),
    0px 5px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
  pointer-events: none;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &.visible {
    opacity: 1;
    transform: translateY(
      ${(props) => (props.medium || props.large ? "64px" : "46px")}
    );
    pointer-events: all;
  }

  .inputWrapper {
    padding: 10px 20px;

    &:hover {
      background: ${Color("gray", "1")};
    }
  }

  input {
    background: transparent;
    border: none;
    color: black;
    font-size: 16px;
    padding: 0;
    width: 100%;
    cursor: pointer;
    line-height: 1.3;

    &:focus {
      outline: none;
      color: ${Color("green", "6")};
    }
  }
`;

const CloseWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 10;
`;

const ButtonContainer = styled(Row)`
  cursor: pointer;
  position: relative;
  z-index: 11;
  // ${(props) => props.error && `border: 1px solid ${Color("red")}`};

  &:hover {
    background: ${Color("gray", "3")};
    .input-wrapper {
      background: ${Color("gray", "3")};
    }
  }
`;

export const AutoComplete = (props) => {
  const {
    infiniteScroll,
    excludeItems,
    noDuplicates,
    displayTextFromSelected = false,
    errorMessage,
  } = props;

  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [allResults, showAllResults] = useState(false);
  const [itemFocused, setItemFocused] = useState(-1);
  const [getRef, setRef] = useDynamicRefs();

  const suggestions = useRef([]);

  const buildResultSet = React.useCallback(() => {
    let resultSet = props.searchResults || [];
    const selectedItems = props.selectedItems || [];
    if (excludeItems?.length > 0) {
      excludeItems.forEach((item) => {
        resultSet = resultSet.filter((r) => r.id !== item.id);
      });
    }

    let results = resultSet;
    if (props.hideSelected && selectedItems.length > 0) {
      results = resultSet.filter(
        (r) =>
          !_.includes(
            selectedItems?.map((st) => st.id?.toString()),
            r.id?.toString()
          )
      );
    }

    if (!infiniteScroll && !allResults) {
      results = results.filter(autocompleteFilter("label", props.searchTerm));
    }

    results = _.uniqBy(results, "id");

    if (props.showSuggestion) {
      if (!!props.searchTerm) {
        suggestions.current = results.filter((a) => a.suggestion);
        results = results.filter((a) => !a.suggestion);
      } else {
        suggestions.current = [];
      }
    }

    return noDuplicates ? _.uniqBy(results, "label") : results;
  }, [props.selectedItems, props.searchResults, props.searchTerm, allResults]);

  const results = buildResultSet();
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  // const tabPress = useKeyPress("Tab");

  React.useEffect(() => {
    if (upPress && visible) {
      if (itemFocused > 0) {
        setItemFocused(itemFocused - 1);
        getRef(`resultItem-${itemFocused - 1}`).current.focus();
      }
      if (itemFocused === 0) {
        setVisible(false);
      }
    }
  }, [upPress]);
  React.useEffect(() => {
    if (downPress && visible && itemFocused < results.length - 1) {
      setItemFocused(itemFocused + 1);
      getRef(`resultItem-${itemFocused + 1}`).current.focus();
    }
  }, [downPress]);
  React.useEffect(() => {
    if (enterPress && visible) {
      clickItem(results[itemFocused]);
    }
  }, [enterPress]);
  // React.useEffect(() => {
  //   if (tabPress && visible) {
  //     setVisible(false);
  //   }
  // }, [tabPress]);

  const onFocus = (e) => {
    showAllResults(
      props.searchTerm?.toLowerCase() === e.target.value?.toLowerCase()
    );
    clearError();

    if (props.showOnEmptyTerm) {
      setVisible(true);
      props.onFocus && props.onFocus();
    }
  };

  const showError = (e) => {
    if (props.isValid) {
      setError(e);
      props.isValid(false);
    }
  };

  const clearError = () => {
    if (props.isValid && error !== "") {
      setError("");
      props.isValid(true);
    }
  };

  const onBlur = () => {
    if (props.clearSearchTerm) {
      props.setSearchTerm("");
    }
    const selectedItems = props.selectedItems || [];
    let results = props.searchResults;
    if (props.hideSelected) {
      results = props.searchResults?.filter(
        (r) =>
          !_.includes(
            selectedItems?.map((st) => st.id?.toString()),
            r.id?.toString()
          )
      );
    }

    // commented to fix:
    // if (
    //   props.searchTerm &&
    //   results[0]?.label?.toLowerCase() === props.searchTerm?.toLowerCase()
    // ) {
    //   props.addItem(results[0]);
    //   clearError();
    // } else {
    //   showError("Plese select one item from  the list");
    // }

    if (infiniteScroll && !!props.withResponses) {
      // perform initial search
      setTimeout(() => {
        props.setSearchTerm("");
      }, 200);
    }
  };

  const handleMouseLeave = () => {
    if (visible) {
      props.onBlur?.();
      setTimeout(() => {
        setVisible(false);
        if (props.hideSelected) {
          props.setSearchTerm("");
        }
      }, 500);
    }
  };

  const clearSearchResults = () => {
    clearError();
    props.clearSearchResults();
    props.setSearchTerm("");
    suggestions.current = [];
    if (visible) {
      setVisible(false);
    }
  };

  const clickItem = (item) => {
    if (item) {
      props.addItem(item);
      clearError();
      if (displayTextFromSelected) {
        !props.clearSearchTerm && props.setSearchTerm(item.label);
      } else {
        props.setSearchTerm("");
      }
      setVisible(false);
      setItemFocused(-1);
    }
  };

  const inputOnChange = (e) => {
    showAllResults(
      props.searchTerm?.toLowerCase() === e.target.value?.toLowerCase()
    );
    props.setSearchTerm(e.target.value);
    if (!props.showOnEmptyTerm) {
      setVisible(!!e.target.value);
    }
  };

  let infiniteScrollRef;
  if (infiniteScroll) {
    [infiniteScrollRef] = useInfiniteScroll({
      loading: infiniteScroll.loading,
      hasNextPage: infiniteScroll.hasNextPage,
      onLoadMore: infiniteScroll.onLoadMore,
    });
  }

  return (
    <>
      {props.editMode ? (
        <>{props.searchTerm && <Pill label={props.searchTerm} />}</>
      ) : (
        <Column
          onMouseLeave={handleMouseLeave}
          ref={containerRef}
          style={{ position: "relative", flex: "1 1 auto" }}
          maxWidth="400"
        >
          <ButtonContainer
            bkg="gray"
            fade="2"
            radius="15"
            middle
            // error={!!errorMessage}
          >
            {props.label && (
              <Row marginLeft onClick={() => setVisible(!visible)}>
                <Label bold noWrap>
                  {props.label}
                </Label>
              </Row>
            )}
            <Input
              inputRef={inputRef}
              autoComplete={props.autoComplete || "off"}
              small
              label={props.label}
              // small={props.small}
              // large={props.large}
              // medium={props.medium}
              data-name={props.dataNameInput || "search-input"}
              placeholder={props.placeholder || "Search"}
              expand
              value={props.searchTerm}
              onChange={inputOnChange}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={props.disabled}
              reverse
              truncate
              icon={
                props.hasClearButton && !!props.searchTerm
                  ? "close"
                  : props.icon || ""
              }
              iconAction={clearSearchResults}
              iconSize="16"
            />
            {!props.disabled && (
              <Row
                noShrink
                middle
                marginRight="10"
                onClick={() => {
                  // visible ? containerRef.current.focus() : inputRef.current.focus()
                  setVisible(!visible);
                  props.arrowOnClick?.();
                }}
                className="hover"
              >
                <Icon
                  name="arrowDropDown"
                  color="gray"
                  fade="8"
                  className={visible ? "rotate180" : ""}
                />
              </Row>
            )}
          </ButtonContainer>
          {/* eslint-disable-next-line no-nested-ternary */}
          {(props.searchTerm || props.showOnEmptyTerm) &&
            (results.length === 0 && suggestions.current.length === 0 ? (
              <Column>
                {!props.hideNoResults && (
                  <ResultsContainer
                    small={props.small}
                    large={props.large}
                    medium={props.medium}
                    className={visible ? "visible" : ""}
                  >
                    <Row paddingAll>
                      {props.noResultsMessage || <Label>No results...</Label>}
                    </Row>
                  </ResultsContainer>
                )}
              </Column>
            ) : (
              <ResultsContainer
                scroll
                small={props.small}
                large={props.large}
                medium={props.medium}
                className={visible ? "visible" : ""}
              >
                <Column paddingY="10">
                  {results.map((item, index) => (
                    <Row
                      className="inputWrapper"
                      data-name={props.dataNameItems}
                      key={item.id}
                      onClick={() => {
                        clickItem(item);
                      }}
                    >
                      <input
                        tabIndex="-1"
                        ref={setRef(`resultItem-${index}`)}
                        value={item.label}
                        readOnly
                      />
                    </Row>
                  ))}
                  {(infiniteScroll?.loading || infiniteScroll?.hasNextPage) && (
                    <Row
                      expand
                      paddingAll="20"
                      center
                      ref={infiniteScroll && visible ? infiniteScrollRef : null}
                    >
                      <Spinner small />
                    </Row>
                  )}
                  {suggestions.current.length > 0 && (
                    <Column paddingY="10">
                      {results.length > 0 && (
                        <Row marginBottom="15">
                          <HR />
                        </Row>
                      )}
                      <Row marginX marginY="5">
                        <Label color="gray" fade="6" small>
                          Did you mean:
                        </Label>
                      </Row>
                      {suggestions.current.map((item, index) => (
                        <Row
                          className="inputWrapper"
                          data-name={`suggestion-${props.dataNameItems}`}
                          key={`suggestion-${item.id}`}
                          onClick={() => {
                            clickItem(item);
                          }}
                        >
                          <Label>{item.label}</Label>
                          {/* <input
                          tabIndex="-1"
                          ref={setRef(`resultItem-${index + 1000}`)}
                          value={item.label}
                          readOnly
                        /> */}
                        </Row>
                      ))}
                    </Column>
                  )}
                </Column>
                {props.searchLoading && (
                  <Row expanded center>
                    <Spinner small />
                  </Row>
                )}
              </ResultsContainer>
            ))}
          {error && <Error>{error}</Error>}
          {errorMessage && <Error>{errorMessage}</Error>}
          {visible && (
            <CloseWrapper
              onClick={() => {
                setVisible(false);
              }}
            />
          )}
        </Column>
      )}
    </>
  );
};

AutoComplete.propTypes = {
  icon: PropTypes.string,
  showOnEmptyTerm: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  searchTerm: PropTypes.string,
  searchResults: PropTypes.array,
  selectedItems: PropTypes.array,
  clearSearchResults: PropTypes.func,
  arrowOnClick: PropTypes.func,
  setSearchTerm: PropTypes.func,
  searchLoading: PropTypes.bool,
  noDuplicates: PropTypes.bool,
  addItem: PropTypes.func,
};

/*
wrapper of autocomplete with responses
 */
export const AutoCompleteWithResponses = (props) => {
  return (
    <Column stretch>
      <AutoComplete {...props} hideSelected withResponses />
      {props.selectedItems.length > 0 && !props.hidePills && (
        <Row multiline marginTop={!props.editMode} flexWrap>
          {props.selectedItems?.map((item) => (
            <Row key={item.id}>
              <Pill
                disbaled={props.disabled}
                label={item.label}
                bkg="green"
                fade="2"
                close={!props.disabled && !props.editMode}
                data-name={props.dataNameSelected || "selected-item"}
                onClose={() => {
                  props.removeItem(item);
                }}
              />
            </Row>
          ))}
        </Row>
      )}
    </Column>
  );
};
