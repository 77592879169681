import { useState, useEffect, useRef } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import zipcelx from "zipcelx";

import { getRandomColor, MAX_RESULTS } from "../../utils/variables";
import { setWebEvent } from "../../store/queries/events";
import {
  diseaseActions,
  // highlightActions,
  settingsActions,
  conferenceActions,
} from "../../store/actions";
import {
  getDiseaseTweets,
  getDiseasePowerPoint,
} from "../../store/sagas/diseaseSagas";
import {
  logAnalyticEvent,
  getISOFormattedDateTimeString,
  formatNumberWithCommas,
} from "../../utils";
import useDebounce from "../../utils/useDebounce";
import { view } from "./DiseaseView";

export const Disease = ({ props }) => {
  const {
    homeSettings,
    auth,
    isRegistered,
    handleSignUpClick,
    noFetching,
    contentRef,
    downloadRef,
    handleScroll,
    monitors,
    selectedMonitor,
    setSelectedMonitor,
    dates,
    setDates,
    handleDateChange,

    tags,
    searchTag,
    selectedTags,
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll,

    regions,
    selectedRegion,
    setSelectedRegion,
    handleRegionChange,
    regionName,

    clearFilters,
    handleInfluencersChartClick,

    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange,
    filterTip,
    closeFilterTip,
    urlTab,
    activeTab,
    handleActiveTab,
    clearSection,
    showCalendar,
    setShowCalendar,
    whitelist,
    showExport,
    showFilter,
    filterVisibility,
    toggleFilterVisibility,
    showToast,
    toggleToast,
  } = props;

  const tabs = [
    { id: "tweets", label: "Posts" },
    { id: "influencers", label: "Top Influencers" },
    // { id: "summary", label: "Highlights" },
  ];

  const influencerTypes = [
    { id: "all", label: "All" },
    { id: "high", label: "High" },
    { id: "medium", label: "Medium" },
    { id: "low", label: "Low" },
  ];

  const dispatch = useDispatch();

  // --> mapStateToProp
  const disease = useSelector((state) => state.disease.one);
  const diseaseTags = useSelector((state) => state.disease.tags);
  const AIResponse = useSelector((state) => state.disease.gpt);
  const aiSettings = useSelector((state) => state.settings?.ai);
  const initialHcps = useSelector((state) => state.disease.dol.data);
  const loadingHcps = useSelector((state) => state.disease.dol.fetching);
  const hcpsReady = useSelector((state) => state.disease.dol.success);
  const diseaseTweets = useSelector((state) => state.disease.tweets);
  const diseaseTweetsCount = useSelector(
    (state) => state.disease.tweetsCount.data
  );

  const isTweetsCountLoading = useSelector(
    (state) => state.disease.tweetsCount.fetching
  );
  const isTweetsCountReady = useSelector(
    (state) => state.disease.tweetsCount.success
  );

  const loading = diseaseTweets.fetching;
  const isReady = diseaseTweets.success;

  const conferences = useSelector((state) => state.conference.all);
  const userPlans = useSelector((state) => state.plan.userPlans);
  // <-- mapStateToProp

  // --> STATE
  const [isNewAI, setIsNewAI] = useState(false);
  const [refreshAI, setRefreshAI] = useState(false);
  const [pageDiseaseTweets, setPageDiseaseTweets] = useState(0);
  const [tweetsCounter, setTweetsCounter] = useState(0);
  const [hcps, setHcps] = useState([]);
  const [hcpsChartData, setHcpsChartData] = useState([]);
  const [selectedHcp, setSelectedHcp] = useState(
    homeSettings.hcpName?.[selectedMonitor.id] || "all"
  );
  const [sortTableBy, setSortTableBy] = useState("score");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedInfluence, setSelectedInfluence] = useState(
    homeSettings.influence?.[selectedMonitor.id] || "all"
  );
  const [pageConferences, setPageConferences] = useState(0);
  const [searchConference, setSearchConference] = useState(
    homeSettings.conference?.[selectedMonitor.id]?.[0]?.label || ""
  );
  const debounceSearchConference = useDebounce(searchConference, 700);
  const [selectedConference, setSelectedConference] = useState(
    homeSettings.conference?.[selectedMonitor.id] || null
  );
  const [savedOnly, setSavedOnly] = useState(
    homeSettings.savedOnly?.[selectedMonitor.id] || false
  );

  const [prevDates, setPrevDates] = useState({
    start: null,
    end: null,
    selectedPeriod: "0",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [visible, toggleVisibility] = useState(false);
  const [error, setError] = useState(false);
  // <-- STATE

  const cookies = useSelector((state) => state.settings.cookies);

  const getUserType = () => {
    return (
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL"
    );
  };

  useEffect(() => {
    handleActiveTab(
      urlTab || homeSettings.activeTabs?.[selectedMonitor.id] || "tweets"
    );
    return () => {
      dispatch(conferenceActions.clearAll());
    };
  }, []);

  useEffect(() => {
    if (selectedConference?.[0]) {
      setPrevDates({ ...dates });
      setDates({
        start: selectedConference[0].monitorStartTime,
        end: selectedConference[0].monitorEndTime,
        selectedPeriod: "0",
      });

      // log event
      logEvent("Conference Selected", [
        {
          name: "conference",
          value: selectedConference[0].primaryName,
        },
        // {
        //   name: "startDate",
        //   value: getISOFormattedDateTimeString(selectedConference[0].monitorStartTime),
        // },
        // {
        //   name: "endDate",
        //   value: getISOFormattedDateTimeString(selectedConference[0].monitorEndTime),
        // }
      ]);
    }
  }, [selectedConference]);

  useEffect(() => {
    if (selectedMonitor?.name && !selectedMonitor?.id) {
      dispatch(
        diseaseActions.tagsRequest({
          ignore: false,
          term: selectedMonitor.name,
        })
      );
    }
    if (selectedMonitor?.id && !selectedMonitor?.name) {
      // fetch details: We allways need to fetch that one as it's cleared
      // after pressing back in a tweet
      dispatch(diseaseActions.oneRequest(selectedMonitor.id));
      setSelectedInfluence(
        homeSettings.influence?.[selectedMonitor.id] || "all"
      );
    }
  }, [selectedMonitor]);

  useEffect(() => {
    if (diseaseTags?.data.length > 0) {
      const tempId = diseaseTags?.data?.find(
        (k) => k.name?.toLowerCase() === selectedMonitor.name?.toLowerCase()
      )?.diseaseId;
      if (tempId) {
        dispatch(diseaseActions.oneRequest(tempId));
        setSelectedInfluence(homeSettings.influence?.[tempId] || "all");
        setSelectedMonitor({ ...selectedMonitor, id: tempId });
      }
    }
  }, [diseaseTags]);

  useEffect(() => {
    if (AIResponse.data?.response) {
      let responseTags = [];

      for (const key in AIResponse.data.tweetTagMap) {
        const kws = AIResponse.data.tweetTagMap[key]?.map((t) => ({
          id: t.id,
          name: t.name,
        }));
        responseTags.push(kws);
      }

      setIsNewAI(true);
      setRefreshAI(false);
      dispatch(
        settingsActions.set({
          ai: {
            ...aiSettings,
            [selectedMonitor.id]: {
              response: AIResponse.data.response,
              keywords: responseTags.flat(),
              timestamp: getISOFormattedDateTimeString(moment()),
              startDate: dates.start,
              endDate: dates.end,
              tags: selectedTags?.map((t) => t.id),
              conference: selectedConference?.[0]?.id,
              sortBy,
              regionName,
            },
          },
        })
      );
    }
  }, [AIResponse.data]);

  const hasAISettingsChanged = () => {
    let hasChanged = false;
    const aiMonitor = aiSettings?.[selectedMonitor.id];
    if (aiMonitor) {
      const hasTagsChanged =
        aiMonitor.tags?.sort()?.join(",") !==
        selectedTags
          ?.map((t) => t.id)
          ?.sort()
          ?.join(",");

      const hasDatesChanged =
        moment(aiMonitor.startDate).format("YYYY-MM-DD") !==
          moment(dates.start).format("YYYY-MM-DD") ||
        moment(aiMonitor.endDate).format("YYYY-MM-DD") !==
          moment(dates.end).format("YYYY-MM-DD");
      const hasConferenceChanged =
        aiMonitor.conference !== selectedConference?.[0]?.id;
      const hasSortByChanged = aiMonitor.sortBy !== sortBy;
      const tempStoredRegionName = aiMonitor.regionName || [];
      const tempRegionName = regionName || [];
      const hasRegionChanged =
        tempStoredRegionName.toString() !== tempRegionName.toString();

      hasChanged =
        savedOnly ||
        savedOnly === false ||
        hasDatesChanged ||
        hasTagsChanged ||
        hasConferenceChanged ||
        hasSortByChanged ||
        hasRegionChanged;
    }
    return hasChanged;
  };

  useEffect(() => {
    if (!noFetching.current) {
      setRefreshAI(hasAISettingsChanged());
    }
  }, [dates, selectedTags, selectedConference, sortBy, regionName, savedOnly]);

  useEffect(() => {
    if (noFetching.current) {
      return;
    }
    if (isRegistered) {
      let hasChanged = true;
      const aiMonitor = aiSettings?.[selectedMonitor.id];
      let hasExpired = true;
      if (aiMonitor) {
        const hasResponse = aiMonitor.response;
        if (aiMonitor.timestamp) {
          hasExpired =
            getISOFormattedDateTimeString(moment()) >
            getISOFormattedDateTimeString(
              moment(aiMonitor.timestamp).add(12, "hours")
            );
        }
        hasChanged = !hasResponse || hasExpired;
      }

      if (hasChanged) {
        requestGptQuery();
      }
    }
  }, [aiSettings]);

  useEffect(() => {
    if (
      !disease.fetching &&
      !_.isEmpty(disease.data) &&
      selectedMonitor?.id === disease.data.id
    ) {
      let event = "Dashboard - " + selectedMonitor.type;
      if (activeTab) {
        const t = tabs.filter((t) => t.id === activeTab);
        if (t.length > 0) event = "Disease Monitor - " + t[0].label;
      }
      logEvent(event);
    }
  }, [disease.data]);

  useEffect(() => {
    if (noFetching.current && activeTab === "influencers") {
      return;
    }
    if (
      noFetching.current &&
      activeTab === "tweets" &&
      diseaseTweets.data.length > 0
    ) {
      return;
    }
    if (selectedMonitor?.id && dates.start && regionName !== null) {
      const startDate = dates.start;
      const endDate = dates.end;
      setPageDiseaseTweets(0);
      switch (activeTab) {
        case "influencers":
          requestDiseaseDol({ startDate, endDate });
          break;
        case "tweets":
          requestDiseaseTweets(0);
          requestDiseaseTweetsCount(0);
          break;
      }
    }
  }, [
    selectedMonitor?.id,
    dates,
    selectedTags,
    activeTab,
    regionName,
    savedOnly,
  ]);

  useEffect(() => {
    if (auth?.active && selectedMonitor?.id) {
      setWebEvent({
        userId: auth.id,
        eventType:
          activeTab === "tweets" ? "DISEASE_POSTS" : "DISEASE_INFLUENCERS",
        objectId: selectedMonitor.id,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setTweetsCounter(diseaseTweetsCount);
  }, [diseaseTweetsCount]);

  useEffect(() => {
    let people = initialHcps.filter(
      (hcp) => (hcp.account.accountType = "PERSON")
    );

    switch (selectedInfluence) {
      case "high":
        people = people.filter((p) => p.dolScore > 300);
        break;

      case "medium":
        people = people.filter((p) => p.dolScore <= 300 && p.dolScore >= 200);
        break;

      case "low":
        people = people.filter((p) => p.dolScore < 200);
        break;
    }

    const tempHcps = people.map((hcp) => {
      return {
        ...hcp.account,
        label: hcp.account.name,
        mentions: hcp.mentions,
        score: hcp.dolScore,
        audience: hcp.account.numFollowers,
        influence:
          hcp.dolScore > 300 ? "High" : hcp.dolScore > 200 ? "Medium" : "Low",
      };
    });

    // Let's limit the amount of HCPs in the chart

    let tempHcpsChart = people.map((hcp) => {
      return {
        ...hcp.account,
        x: hcp.dolScore,
        y: hcp.mentions,
        bullet: hcp.account.imageURL,
        value: 0,
        color: getRandomColor(),
      };
    });

    const sortName = [
      (a) =>
        typeof a[sortTableBy] === "string"
          ? a[sortTableBy]?.toLowerCase()
          : a[sortTableBy],
    ];
    setHcps(
      sortDirection === "desc"
        ? _.sortBy(tempHcps, sortName)
        : _.sortBy(tempHcps, sortName).reverse()
    );

    setHcpsChartData(tempHcpsChart);
  }, [initialHcps, sortTableBy, sortDirection, selectedInfluence]);

  useEffect(() => {
    if (auth.active) {
      requestConferences(0);
    }
  }, [debounceSearchConference]);

  const requestDiseaseDol = ({ startDate, endDate }) => {
    if (loadingHcps) return;
    const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
    dispatch(
      diseaseActions.dolRequest({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        startDate,
        endDate,
        ...(regionName && regionName.length > 0 && { regionName }),
        ...(auth?.id &&
          selected?.monitorRules?.length > 0 && { userId: auth.id }),
      })
    );
  };

  const requestDiseaseTweets = (pageNum, sort) => {
    if (loading || !selectedMonitor.id) return;
    const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
    dispatch(
      diseaseActions.tweetsRequest({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        ...(auth?.id &&
          selected?.monitorRules?.length > 0 && { userId: auth.id }),
        ...(auth?.id && { mwUserId: auth.id }),
        startDate: dates.start,
        endDate: dates.end,
        sortBy: sort || sortBy,
        pageNum,
        maxResult: savedOnly ? -1 : MAX_RESULTS, // fetch all posts when 'savedOnly' is true
        ...(regionName && regionName.length > 0 && { regionName }),
        savedOnly: savedOnly,
      })
    );
  };

  const requestGptQuery = () => {
    if (selectedMonitor.id) {
      dispatch(
        diseaseActions.gtpRequest({
          id: selectedMonitor.id,
          startDate: dates.start,
          endDate: dates.end,
          pageNum: pageDiseaseTweets,
          sortBy: sortBy,
          addTagIds: selectedTags?.map((a) => a.id),
          // maxResult: MAX_RESULTS,
          maxResult: 20,
          // q,
          ...(regionName && regionName.length > 0 && { regionName }),
          source: "X",
          userType: getUserType(),
          savedOnly: savedOnly,
        })
      );
    }
  };

  const requestDiseaseTweetsCount = (pageNum) => {
    if (isTweetsCountLoading || !selectedMonitor.id) return;
    // get count only for page 1
    if (pageNum === 0) {
      const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
      dispatch(
        diseaseActions.tweetsCountRequest({
          id: selectedMonitor.id,
          addTagIds: selectedTags?.map((t) => t.id) || [],
          ...(auth?.id &&
            selected?.monitorRules?.length > 0 && { userId: auth.id }),
          ...(auth?.id && { mwUserId: auth.id }),
          startDate: dates.start,
          endDate: dates.end,
          ...(regionName && regionName.length > 0 && { regionName }),
          savedOnly: savedOnly,
        })
      );
    }
  };

  const requestConferences = (pageNum) => {
    dispatch(
      conferenceActions.request({
        pageNum,
        maxResult: MAX_RESULTS,
        ignore: "false",
        ...(searchConference !== "" && { term: searchConference }),
      })
    );
  };

  const tabOnChange = (id) => {
    handleActiveTab(id);
    contentRef.current.scrollTo(0, 0);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          activeTabs: {
            ...homeSettings.activeTabs,
            [selectedMonitor.id]: id,
          },
        },
      })
    );
    // log event
    const selectedTab = tabs.filter((t) => t.id === id);
    logEvent("Disease Monitor - " + (selectedTab?.[0]?.label || ""), [
      {
        name: "activeTab",
        value: id,
      },
    ]);
  };

  const handleMoreDiseaseTweets = () => {
    if (!loading) {
      const newPage = pageDiseaseTweets + 1;
      requestDiseaseTweets(newPage);
      requestDiseaseTweetsCount(newPage);
      setPageDiseaseTweets(newPage);
    }
  };

  const [diseaseTweetsRef] = useInfiniteScroll({
    loading,
    hasNextPage: savedOnly ? false : !diseaseTweets.isLastPage,
    onLoadMore: handleMoreDiseaseTweets,
  });

  const handleClearFilters = () => {
    if (activeTab === "influencers") {
      setSelectedHcp("all");
      setSelectedInfluence("all");
      setSelectedRegion([]);
      setSavedOnly(false);
      dispatch(
        settingsActions.set({
          home: {
            ...homeSettings,
            hcpName: {
              ...homeSettings.hcpName,
              [selectedMonitor.id]: "all",
            },
            influence: {
              ...homeSettings.influence,
              [selectedMonitor.id]: "all",
            },
            regions: {
              ...homeSettings.regions,
              [selectedMonitor.id]: [],
            },
            savedOnly: {
              ...homeSettings.savedOnly,
              [selectedMonitor.id]: false,
            },
          },
        })
      );
    } else {
      setSavedOnly(false);
      clearFilters();
    }

    if (selectedConference?.[0]) {
      clearConference();
    }

    // log event
    logEvent("Clear Filters");
  };

  const handleSelectedHcp = (e) => {
    setSelectedHcp(e.id);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          hcpName: {
            ...homeSettings.hcpName,
            [selectedMonitor.id]: e.id,
          },
        },
      })
    );
  };

  const handleSelectedInfluence = (e) => {
    setSelectedInfluence(e.id);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          influence: {
            ...homeSettings.influence,
            [selectedMonitor.id]: e.id,
          },
        },
      })
    );

    // log event
    logEvent("Influence Change", [
      {
        name: "influence",
        value: e.id,
      },
    ]);
  };

  const handleDateChangeInt = (e) => {
    handleDateChange(e);

    const period =
      e.selectedPeriod === "1"
        ? "Last 7 Days"
        : e.selectedPeriod === "2"
        ? "Last 30 Days"
        : "Custom";
    // log event
    logEvent(`Date Change - ${period}`, [
      {
        name: "startDate",
        value: getISOFormattedDateTimeString(e.startDate),
      },
      {
        name: "endDate",
        value: getISOFormattedDateTimeString(e.endDate),
      },
    ]);
  };

  const getChildConferences = () => {
    // Refer : https://github.com/kernelnetworks/medicalwatch-backend/issues/451
    // do not show conferences more than a year old
    const yearAgo = moment().subtract(1, "year");
    const children = conferences.data
      .filter((c) => c.parentId)
      .filter((c) => moment(c.monitorEndTime) > yearAgo)
      .map((k) => {
        return {
          ...k,
          label: k.primaryName,
        };
      });
    return children;
  };

  const handleSearchConference = (e) => {
    setSearchConference(e);
  };

  const addSelectedConference = (e) => {
    setSelectedConference([e]);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          conference: {
            [selectedMonitor.id]: [e],
          },
        },
      })
    );
  };

  const clearConference = () => {
    setDates(prevDates);
    setSearchConference("");
    addSelectedConference(null);
  };

  const handleMoreConferences = () => {
    if (!conferences.fetching) {
      const newPage = pageConferences + 1;
      setPageConferences(newPage);
      requestConferences(newPage);
    }
  };

  const exportCSVForTweets = async (isXlsx) => {
    if (selectedMonitor.id) {
      // check for time period, should not be more than 30 days
      const start = moment(dates.start);
      const end = moment(dates.end);
      const diff = end.diff(start, "days");
      if (diff > 31) {
        setErrorMessage(
          "Only 30 days of data can be exported at a time. Please adjust your date range or contact support@medical.watch for assistance."
        );
        toggleVisibility(true);
        setError(true);
        return;
      }

      // get all tweets for the period
      const selected = monitors.filter((m) => m.id === selectedMonitor.id)?.[0];
      const tweets = await getDiseaseTweets({
        id: selectedMonitor.id,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        ...(auth?.id &&
          selected?.monitorRules?.length > 0 && { userId: auth.id }),
        ...(auth?.id && { mwUserId: auth.id }),
        startDate: dates.start,
        endDate: dates.end,
        sortBy: sortBy,
        pageNum: 0,
        maxResult: -1,
        ...(regionName && regionName.length > 0 && { regionName }),
        savedOnly: savedOnly,
      });

      if (tweets.data?.length > 0) {
        if (isXlsx) {
          let xlsxContent = [];
          const s = "string";
          const n = "number";
          xlsxContent.push([
            { value: "Date", type: s },
            { value: "Content", type: s },
            { value: "Source", type: s },
            { value: "Name", type: s },
            { value: "Handle", type: s },
            { value: "Location", type: s },
            { value: "Country", type: s },
            { value: "Link", type: s },
            { value: "Tags", type: s },
            { value: "Replies", type: s },
            { value: "Reposts", type: s },
            { value: "Likes", type: s },
            { value: "Views", type: s },
          ]);
          _.forEach(tweets.data, (post) => {
            xlsxContent.push([
              {
                value: `${moment(post.externalCreatedAt)
                  .utc()
                  .format("MMM D, YYYY")}`,
                type: s,
              },
              {
                value: `${post.content
                  ?.replace(/(\n)/g, " ")
                  ?.replace(/(\")/g, "'")}`,
                type: s,
              },
              { value: `${post.source || "-"}`, type: s },
              { value: `${post.tAccount.name}`, type: s },
              { value: `${post.tAccount.username}`, type: s },
              { value: `${post.tAccount.displayLocation || ""}`, type: s },
              { value: `${post.tAccount.country || ""}`, type: s },
              {
                value: `${window.location.origin}/post/${post.externalId}`,
                type: s,
              },
              { value: `${post.tagNames || ""}`, type: s },
              { value: `${post.numReplies}`, type: n },
              { value: `${post.numRetweets}`, type: n },
              { value: `${post.numLikes}`, type: n },
              { value: `${post.numViews}`, type: n },
            ]);
          });
          const config = {
            filename: `Posts_${moment().format("YYYYMMDDHHmmss")}`,
            sheet: {
              data: xlsxContent,
            },
          };

          zipcelx(config);
          return;
        }

        let csvContent = "";
        // header
        csvContent += `Date, Content, Source, Name, Handle, Location, Link, Tags, Replies, Reposts, Likes, Views\n`;
        _.forEach(tweets.data, (post) => {
          csvContent += `"${moment(post.externalCreatedAt)
            .utc()
            .format("MMM D, YYYY")}",`;
          csvContent += `"${post.content
            ?.replace(/(\n)/g, " ")
            ?.replace(/(\")/g, "'")}",`;
          csvContent += `"${post.source || "-"}",`;
          csvContent += `"${post.tAccount.name}",`;
          csvContent += `"${post.tAccount.username}",`;
          csvContent += `"${post.tAccount.displayLocation || ""}",`;
          csvContent += `"${window.location.origin}/post/${post.externalId}",`;
          csvContent += `"${post.tagNames || ""}",`;
          csvContent += `"${post.numReplies}",`;
          csvContent += `"${post.numRetweets}",`;
          csvContent += `"${post.numLikes}",`;
          csvContent += `"${post.numViews}",`;
          csvContent += `\n`;
        });
        const url = window.URL.createObjectURL(new Blob([csvContent]));
        const link = document.createElement("a");
        link.href = url;
        link.id = "mw-download-link";
        link.setAttribute(
          "download",
          `Posts_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
        document.body.appendChild(link);
        link.click();
      }

      // log event
      logEvent("Export Posts");
    }
  };

  const exportCSVForHCPs = () => {
    if (selectedMonitor.id && disease.data) {
      let csvContent = "";
      // header
      csvContent += `Name, Influence, Mentions, Audience, Location, Country\n`;
      _.forEach(hcps, (hcp) => {
        csvContent += `"${hcp.name}",`;
        csvContent += `${hcp.influence}, ${hcp.mentions}, ${hcp.audience},`;
        csvContent += `"${hcp.displayLocation || ""}",`;
        csvContent += `"${hcp.country || ""}"`;
        csvContent += `\n`;
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement("a");
      link.href = url;
      link.id = "mw-download-link";
      link.setAttribute(
        "download",
        `HCPs_${moment().format("YYYYMMDDHHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();

      // log event
      logEvent("Export");
    }
  };

  const [pptInProgress, setPptInProgress] = useState(false);
  const exportPptReport = async (params) => {
    const { maskAllHCPNames, maskNonUSHCPNames } = params;
    if (pptInProgress) return;
    setPptInProgress(true);
    getDiseasePowerPoint({
      id: selectedMonitor.id,
      addTagIds: selectedTags?.map((t) => t.id) || [],
      source: "X",
      userType: getUserType(),
      startDate: dates.start,
      endDate: dates.end,
      ...(regionName && regionName.length > 0 && { regionName }),
      ...(maskAllHCPNames && { maskAllHCPNames: true }),
      ...(maskNonUSHCPNames && { maskNonUSHCPNames: true }),
      sortBy: sortBy,
      pageNum: 0,
      maxResult: MAX_RESULTS,
      savedOnly: savedOnly,
    })
      .then((response) => {
        setPptInProgress(false);
        console.log(
          "response from report service ==> ",
          response.status
          // response.config,
          // response.headers,
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.id = "mw-download-link";
        link.setAttribute("download", `mw_${selectedMonitor.id}_.pptx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setPptInProgress(false);
        console.log(error);
      });
  };

  const sortByOnChange = (item) => {
    handleSortByChange(item);
    setPageDiseaseTweets(0);
    requestDiseaseTweets(0, item.id);
    requestDiseaseTweetsCount(0);

    // log event
    const event =
      item.id === "score" ? "Sort by most influential" : "Sort by newest";
    logEvent(event);
  };

  const getCounter = () => {
    let counter;
    if (activeTab === "tweets" && isTweetsCountReady) {
      counter = `${formatNumberWithCommas(tweetsCounter)} posts`;
    }
    if (activeTab === "influencers" && hcpsReady) {
      counter = `${formatNumberWithCommas(hcps.length)} physicians`;
    }
    return counter;
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedTags?.length > 0) {
      activeFilters = selectedTags.map((a) => a.label);
    }
    if (selectedConference?.[0]) {
      activeFilters.push(`Congress: ${selectedConference[0].primaryName}`);
    }
    if (regionName?.length > 0) {
      activeFilters = regions
        .filter((r) => regionName.includes(r.id))
        .map((a) => a.label);
    }
    if (activeTab === "influencers" && selectedInfluence !== "all") {
      activeFilters.push(
        `Influence: ${
          influencerTypes.find((a) => selectedInfluence === a.id)?.label
        }`
      );
    }
    if (savedOnly) {
      activeFilters.push("Saved Only");
    }
    return activeFilters;
  };

  const handleSavedOnly = () => {
    setSavedOnly(!savedOnly);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          savedOnly: {
            ...homeSettings.savedOnly,
            [selectedMonitor.id]: !savedOnly,
          },
        },
      })
    );
  };

  const handleAiRefresh = () => {
    dispatch(
      settingsActions.set({
        ai: {
          ...aiSettings,
          [selectedMonitor.id]: { response: "", keywords: [] },
        },
      })
    );

    // log event
    logEvent("Refresh");
  };

  const logEvent = (eventName, evProperties) => {
    // default event properties
    const eventProperties = {
      id: selectedMonitor.id,
      tagId: disease.data?.tagId,
      type: selectedMonitor.type,
      ...(selectedMonitor.source && { source: selectedMonitor.source }),
      name: disease.data?.primaryName,
      activeTab: activeTab,
      influence: selectedInfluence,
      startDate: dates.start,
      endDate: dates.end,
    };
    // any override
    if (!_.isEmpty(evProperties)) {
      evProperties.forEach((evProp) => {
        eventProperties[evProp.name] = evProp.value;
      });
    }

    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      eventName,
      eventProperties,
      auth?.id || null,
      auth?.displayName || null,
      auth?.email || null,
      auth?.createdAt || null,
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL",
      userPlans?.data?.[0]?.plan?.name || null
    );
  };

  return view({
    userId: auth.id,
    isRegistered,
    isAdmin: auth.mwRole === "ADMIN",
    userType: auth?.preferences?.find((k) => k.preferenceName === "UserType")
      ?.preferenceStringValue,
    handleAiRefresh,
    isFetchingAI: AIResponse.fetching,
    isAISuccess: AIResponse.success,
    aiResponse: aiSettings ? aiSettings[selectedMonitor.id]?.response : null,
    hideAI: AIResponse?.data?.requestTokens === 0 || false,
    isNewAI,
    AIKeywords: aiSettings ? aiSettings[selectedMonitor.id]?.keywords : [],
    refreshAI,

    handleSignUpClick,
    contentRef,
    downloadRef,
    handleScroll,
    monitors,
    selectedMonitor,
    loading,
    isReady,
    dates,
    handleDateChange: handleDateChangeInt,
    activeFilters: getActiveFilters(),
    diseaseName: disease.data?.primaryName,
    diseaseTweets: diseaseTweets.data,
    diseaseTweetsRef,
    diseaseNextPage: !diseaseTweets.isLastPage,

    exportCSVForTweets,

    counter: getCounter(),
    activeTab,
    tabOnChange,
    tags,
    searchTag,
    selectedTags: selectedTags || [],
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll,

    hcps,
    hcpsChartData,
    showExport,
    showFilter,
    filterVisibility,
    toggleFilterVisibility,
    exportCSVForHCPs,

    handleClearFilters,
    selectedHcp,
    handleSelectedHcp,
    sortTableBy,
    setSortTableBy,
    sortDirection,
    setSortDirection,
    handleInfluencersChartClick,
    influencerTypes,
    selectedInfluence,
    handleSelectedInfluence,
    loadingHcps,
    hcpsReady,
    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange: sortByOnChange,
    filterTip,
    closeFilterTip,
    showCalendar,
    setShowCalendar,

    conferences: getChildConferences() || [],
    searchConference,
    selectedConference,
    handleSearchConference,
    addSelectedConference,
    clearConference,
    conferencesInfinteScroll: {
      loading: conferences.fetching,
      hasNextPage: !conferences.isLastPage,
      onLoadMore: handleMoreConferences,
    },

    regions,
    selectedRegion,
    handleRegionChange,
    regionName,

    savedOnly,
    handleSavedOnly,

    visible,
    toggleVisibility,
    errorMessage,
    error,

    pptInProgress,
    exportPptReport,
    showToast,
    toggleToast,
  });
};
