import React from "react";

import {
  Row,
  Column,
  Content,
  H3,
  H4,
  Label,
  Spinner,
  AutoComplete,
  AutoCompleteWithResponses,
  Pill,
  DateRange,
  Card,
  Dropdown,
  NoContent,
  MainWrapper,
  Header,
  Menu,
  Filters,
  Grid,
  Summary,
  Toast,
  Icon,
  FilterModal,
  Metadata,
  Checkbox,
} from "../../components";
// import { Chart } from "./Chart";
import { TopTweets } from "./TopTweets";
import { TopHCPs } from "./TopHCPs";
import { XYScatterChart } from "../../components/XYScatterChart";
import { capitalize } from "../../utils";

export const view = ({
  isRegistered,
  userId,
  userType,
  handleSignUpClick,
  contentRef,
  downloadRef,
  handleScroll,
  dates,
  handleDateChange,
  selectedMonitor,
  activeFilters,
  loading,
  isReady,
  diseaseName,
  diseaseTweetsRef,
  diseaseNextPage,
  diseaseTweets,
  exportCSVForTweets,

  counter,
  activeTab,
  tabOnChange,
  tags,
  searchTag,
  selectedTags,
  handleSearchTag,
  addSelectedTag,
  removeSelectedTag,
  clearTag,
  tagsInfinteScroll,

  showExport,
  showFilter,
  filterVisibility,
  toggleFilterVisibility,
  exportCSVForHCPs,
  hcps,
  hcpsChartData,

  handleClearFilters,
  // selectedHcp,
  // handleSelectedHcp,
  sortTableBy,
  setSortTableBy,
  sortDirection,
  setSortDirection,
  handleInfluencersChartClick,
  influencerTypes,
  selectedInfluence,
  handleSelectedInfluence,
  loadingHcps,
  hcpsReady,
  allKeywords,
  sortBy,
  handleSortByChange,
  filterTip,
  closeFilterTip,
  showCalendar,
  setShowCalendar,
  handleAiRefresh,
  isFetchingAI,
  aiResponse,
  hideAI,
  isNewAI,
  AIKeywords,
  refreshAI,
  isAISuccess,

  conferences,
  searchConference,
  selectedConference,
  handleSearchConference,
  addSelectedConference,
  clearConference,
  conferencesInfinteScroll,

  regions,
  selectedRegion,
  handleRegionChange,

  savedOnly,
  handleSavedOnly,

  visible,
  toggleVisibility,
  errorMessage,
  error,

  pptInProgress,
  exportPptReport,
  showToast,
  toggleToast,
}) => {
  const monitorName = selectedMonitor.label || diseaseName;

  return (
    <MainWrapper drawer className="dashboard">
      <Header
        isHome
        search
        drawer
        login
        user
        signUpClick={handleSignUpClick}
        source="disease"
      />
      <Menu
        {...{
          selectedMonitor,
          activeTab,
          tabOnChange,
        }}
      />

      <Content ref={contentRef} onScroll={handleScroll}>
        <Metadata title={monitorName} />
        <Column>
          <Row marginBottom="5" marginRight style={{ minHeight: "20px" }}>
            <H3 bold>{monitorName}</H3>
          </Row>
          <Row marginTop="10" marginBottom>
            <Label color="gray" fade="7">
              Discover what physicians are saying on social media
            </Label>
          </Row>
          <Column marginBottom>
            <Filters
              counter={userType !== "OTHER" && userType !== "HCP" && counter}
              {...{ activeFilters }}
              onClearFilters={handleClearFilters}
              // counterTooltip={
              //   <Label>
              //     <p>The count and list below includes original posts,</p>
              //     <p>quotes and replies. Excludes reposts</p>
              //   </Label>
              // }
              datePicker={
                userType !== "OTHER" &&
                userType !== "HCP" &&
                dates.start && (
                  <Row gap="5" middle>
                    {counter && (
                      <Row marginLeft="5">
                        <Label>from</Label>
                      </Row>
                    )}
                    <DateRange
                      noBackground
                      id="date-range"
                      period
                      initialDate={dates.start}
                      endDate={dates.end}
                      onChange={handleDateChange}
                      noFutureDates
                      {...{ showCalendar, setShowCalendar }}
                    />
                  </Row>
                )
              }
              footer={
                selectedTags.length > 0 && (
                  <Row flexWrap marginTop="10" marginLeft>
                    {selectedTags.map((t, index) => (
                      <Row>
                        {index !== 0 && (
                          <Row marginRight="8" marginLeft="-2" marginTop="3">
                            <Label color="gray" fade="7">
                              or
                            </Label>
                          </Row>
                        )}
                        <Pill
                          key={t.id}
                          bkg="green"
                          fade="2"
                          label={t.label}
                          close
                          onClose={() => removeSelectedTag({ id: t.id })}
                        />
                      </Row>
                    ))}
                  </Row>
                )
              }
            >
              <Grid columns="3" bottom>
                <Column gap="10">
                  <Label bold>Keywords</Label>
                  <div
                    onClick={(e) => {
                      if (!showFilter) {
                        toggleFilterVisibility(true);
                        e.stopPropagation();
                      }
                    }}
                    className="pointer"
                  >
                    {showFilter ? (
                      <AutoCompleteWithResponses
                        showOnEmptyTerm
                        icon="search"
                        searchResults={tags}
                        placeholder="Biomarker or other"
                        searchTerm={searchTag}
                        setSearchTerm={handleSearchTag}
                        selectedItems={selectedTags}
                        addItem={addSelectedTag}
                        removeItem={removeSelectedTag}
                        clearSearchResults={clearTag}
                        searchLoading={false}
                        infiniteScroll={tagsInfinteScroll}
                        hidePills
                        hasClearButton
                        showSuggestion
                      />
                    ) : (
                      <div
                        onClick={(e) => {
                          if (!showFilter) {
                            toggleFilterVisibility(true);
                            e.stopPropagation();
                          }
                        }}
                        className="pointer"
                      >
                        <Dropdown
                          expand
                          placeholder="Biomarker or other"
                          value={[]}
                          items={null}
                          disabled
                          onChange={() => {}}
                        />
                      </div>
                    )}
                  </div>
                </Column>
                {userId && (
                  <Column gap="10">
                    <Label bold>Congress</Label>
                    {showFilter ? (
                      <AutoComplete
                        showOnEmptyTerm
                        icon="search"
                        displayTextFromSelected
                        searchResults={conferences}
                        setSearchTerm={handleSearchConference}
                        searchTerm={searchConference}
                        selectedItems={selectedConference}
                        addItem={addSelectedConference}
                        clearSearchResults={clearConference}
                        hasClearButton
                        infiniteScroll={conferencesInfinteScroll}
                      />
                    ) : (
                      <div
                        onClick={(e) => {
                          if (!showFilter) {
                            toggleFilterVisibility(true);
                            e.stopPropagation();
                          }
                        }}
                        className="pointer"
                      >
                        <Dropdown
                          expand
                          placeholder="Search"
                          value={[]}
                          items={null}
                          disabled
                          onChange={() => {}}
                        />
                      </div>
                    )}
                  </Column>
                )}
                {userId && (
                  <Column gap="10">
                    <Label bold>Region</Label>
                    <div
                      onClick={(e) => {
                        if (!showFilter) {
                          toggleFilterVisibility(true);
                          e.stopPropagation();
                        }
                      }}
                      className="pointer"
                    >
                      <Dropdown
                        expand
                        multiple
                        multipleLabel="Regions"
                        value={selectedRegion}
                        items={regions}
                        disabled={!showFilter}
                        onChange={(e) => handleRegionChange(e)}
                      />
                    </div>
                  </Column>
                )}

                {activeTab === "tweets" && (
                  <Column gap="10" marginLeft="10" marginBottom="-3">
                    <Checkbox
                      label="Only show saved posts"
                      checked={savedOnly}
                      onChange={handleSavedOnly}
                    />
                  </Column>
                )}

                {activeTab === "influencers" && (
                  <Column gap="10">
                    <Label bold>Influence</Label>
                    <div
                      onClick={(e) => {
                        if (!showFilter) {
                          toggleFilterVisibility(true);
                          e.stopPropagation();
                        }
                      }}
                      className="pointer"
                    >
                      <Dropdown
                        expand
                        select
                        items={influencerTypes}
                        value={selectedInfluence}
                        onChange={handleSelectedInfluence}
                        disabled={!showFilter}
                      />
                    </div>
                  </Column>
                )}
              </Grid>
            </Filters>
          </Column>

          <Column>
            {activeTab === "tweets" && (
              <Column>
                {isRegistered && diseaseTweets?.length >= 4 && !hideAI && (
                  <Summary
                    keywords={AIKeywords}
                    // monitors={[
                    //   monitorName,
                    //   monitorName?.toLowerCase(),
                    //   capitalize(monitorName),
                    // ]}
                    isFetching={isFetchingAI}
                    response={aiResponse}
                    isSuccess={isAISuccess}
                    {...{
                      addSelectedTag,
                      handleAiRefresh,
                      isNewAI,
                      refreshAI,
                    }}
                  />
                )}

                {diseaseTweets.length > 0 && (
                  <Row marginY>
                    <H4 bold>Posts</H4>
                  </Row>
                )}
                <TopTweets
                  source={selectedMonitor.type?.toLowerCase()}
                  tweets={diseaseTweets}
                  tweetsRef={diseaseTweetsRef}
                  hasNextPage={diseaseNextPage}
                  keywords={allKeywords}
                  onClearFilters={handleClearFilters}
                  onDateClick={() => setShowCalendar(true)}
                  showExport={
                    showExport && userType !== "OTHER" && userType !== "HCP"
                  }
                  {...{
                    isRegistered,
                    loading,
                    isReady,
                    sortBy,
                    handleSortByChange,
                    exportCSVForTweets,
                    exportPptReport,
                    pptInProgress,
                  }}
                />
              </Column>
            )}

            {activeTab === "influencers" && (
              <>
                {loadingHcps ? (
                  <Row expand paddingAll="80" center>
                    <Spinner />
                  </Row>
                ) : (
                  <>
                    {hcpsChartData.length === 0 && hcps.length === 0 ? (
                      <>
                        {hcpsReady && (
                          <NoContent
                            label="physicians"
                            onClearFilters={handleClearFilters}
                            onDateClick={() => setShowCalendar(true)}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {userType !== "OTHER" &&
                          userType !== "HCP" &&
                          hcpsChartData.length > 0 && (
                            <Card border marginBottom="40">
                              <Row marginBottom="10">
                                <H4 bold>Top influencers</H4>
                              </Row>
                              <XYScatterChart
                                id="hcps"
                                typeX="influence"
                                typeY="mentions"
                                data={hcpsChartData}
                                handleChartClick={handleInfluencersChartClick}
                              />
                              <Row marginBottom="-10" />
                            </Card>
                          )}
                        <Column ref={downloadRef}>
                          {hcps.length > 0 && (
                            <TopHCPs
                              source={selectedMonitor.type.toLowerCase()}
                              {...{
                                hcps,
                                showExport,
                                sortTableBy,
                                setSortTableBy,
                                sortDirection,
                                setSortDirection,
                                userType,
                                exportCSVForHCPs,
                              }}
                            />
                          )}
                        </Column>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Column>
          <Toast
            {...{ visible, toggleVisibility }}
            warning={error}
            expiration={4000}
          >
            <Row gap={error ? "10" : "5"} middle>
              <Icon name={error ? "warning" : "check"} />
              <Label>{errorMessage}</Label>
            </Row>
          </Toast>
          <FilterModal
            visible={filterVisibility}
            toggleVisibility={() => toggleFilterVisibility(!filterVisibility)}
          />
        </Column>
      </Content>
      <Toast
        visible={showToast}
        toggleVisibility={toggleToast}
        warning
        expiration={3000}
      >
        <Row gap="10" middle>
          <Icon color="white" name="warning" />
          <Label color="white" bold>
            You can only select 5 keywords
          </Label>
        </Row>
      </Toast>
    </MainWrapper>
  );
};
