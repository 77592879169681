import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Column,
  Row,
  Label,
  H3,
  H4,
  P,
  Icon,
  Footer,
  MainWrapper,
  Metadata,
  Spinner,
  RowToColumn,
  TweetCard,
  ListItem,
} from "../../components";
import { formatShortDates, idToText } from "../../utils";
import { HeaderAndDrawer, Share } from "./components";
import moment from "moment";

export const view = ({
  signUpClick,
  conferences,
  loadingConferences,
  isSuccess,
  tweet,
  isLoadingTweets,
}) => {
  return (
    <MainWrapper className="dashboard">
      <Metadata />
      <HeaderAndDrawer {...{ signUpClick }} />
      <Column marginTop="100">
        <Column paddingBottom="100">
          <Column center>
            <Column maxWidth="1200" paddingX>
              <Column gap="10">
                <H3 bold>Top congresses</H3>
                {/* <H3 bold>Top congresses - {moment().year()}</H3> */}
                <P color="gray" fade="7">
                  Click a congress to see top influencers
                </P>
                <Share title={`Top congresses`} />
                {/* <Share title={`Top congresses - ${moment().year()}`} /> */}
              </Column>
              <RowToColumn gap="40" columnGap="10">
                <Column fit maxWidth="600">
                  {loadingConferences && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}

                  {isSuccess && conferences?.length === 0 && (
                    <Row marginY="40" expand center>
                      <Label color="gray" fade="7">
                        No influencers found!
                      </Label>
                    </Row>
                  )}

                  {conferences?.length > 0 && (
                    <Column gap="10" marginTop="40">
                      {conferences?.map((conference, index) => (
                        <Column marginX="-10" key={conference.id}>
                          <Link
                            to={`/influencers/congress/${idToText(
                              conference.primaryName
                            )}/${conference.id}`}
                          >
                            <ListItem expand middle hover>
                              <Column>
                                <H4 bold>{conference.primaryName}</H4>
                                <Label color="gray" fade="7">
                                  {formatShortDates(
                                    conference.monitorStartTime,
                                    conference.monitorEndTime
                                  )}
                                </Label>
                              </Column>
                              <Row fit right>
                                <Label bold color="gray" fade="7">
                                  {conference.tag.count}{" "}
                                  {conference.tag.count === 1
                                    ? "post"
                                    : "posts"}
                                </Label>
                              </Row>
                            </ListItem>
                          </Link>
                        </Column>
                      ))}
                    </Column>
                  )}
                </Column>
                <Column marginTop="30" maxWidth="560">
                  {isLoadingTweets && (
                    <Row marginY="40" expand center>
                      <Spinner />
                    </Row>
                  )}
                  {tweet && (
                    <Link to={`/post/${tweet.externalId}`}>
                      <TweetCard
                        data={tweet}
                        title={`Top post from ${conferences?.[0]?.primaryName}`}
                        subtitle={
                          tweet?.tAccount?.lastName &&
                          `Congratulations Dr. ${tweet.tAccount.lastName}. Keep up the good work!`
                        }
                      />
                    </Link>
                  )}
                </Column>
              </RowToColumn>
            </Column>
          </Column>
        </Column>
        <Footer />
      </Column>
    </MainWrapper>
  );
};
