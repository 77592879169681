import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";
import { objectToUrlEncoded } from "../../utils/constants";
import { authHeader } from "../store";

export function getTagSummary(data) {
  const { id, maxResult, objectType } = data;
  const params = objectToUrlEncoded({ maxResult, objectType });
  return axios.get(
    `${getApiPath(false)}/account/${id}/tagSummary?${params}`,
    {}
  );
}

export function getAccountAttributes(data) {
  const { id } = data;
  return axios.get(
    `${getApiPath(false)}/account/${id}/attributes`,
    {}
  );
}

export function updateAccountAttribute(data) {
  return axios.put(
    `${getApiPath()}/account/attribute`,
    data,
    {
      headers: authHeader(),
    },
  );
}

export function useGetTagSummary(data) {
  return useQuery(["accountTagSummary"], () => getTagSummary(data), {
    cacheTime: 0,
  });
}

export function useGetAccountAttributes(data) {
  return useQuery(["accountAttributes"], () => getAccountAttributes(data), {
    cacheTime: 0,
  });
}
