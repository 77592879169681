// flag as input parameter to distinguish between auth or unauth requests
// by default, all requested should be authenticated
export const getApiPath = (auth = true) => {
  const path = auth ? "api/v1" : "notauth";
  if (window.location.hostname === "localhost") {
    return "https://beta.medical.watch/" + path;
  }
  return "https://my.medical.watch/" + path;
};

// export const axios = Axios.create({
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// ANALYTICS API KEY
export const getAnalyticsApiKey = () => {
  if (window.location.hostname === "localhost") {
    return "a82a770081a1d5b3f13b83acb0d22bee";
  } else if (window.location.hostname === "alpha.medical.watch") {
    return "e537b22a09acd23f100b95b64171860e";
  } else if (window.location.hostname === "beta.medical.watch") {
    return "78cd55883f3fb76433b2f1c1d6bdd015";
  }
  return "5075d4c7aaf7ebb4aa701af6efb0d12c";
};

export const appVersion = "1.20250329.0332";

export const MAX_RESULTS = 25;
export const FETCH_DELAY = 2000;

export const API_URL_TEMP =
  "https://60edaa605958940017140920.mockapi.io/api/v1";

export const colorList = {
  twitter: {
    default: "#1DA1F2",
  },
  white: {
    default: "#ffffff",
  },
  black: {
    default: "#000000",
  },
  gray: {
    default: "#AAABAF",
    0.5: "#F7F7F7",
    1: "#EEEEEF",
    2: "#DDDDDF",
    3: "#CCCDCF",
    4: "#BBBCBF",
    5: "#AAABAF",
    6: "#88898C",
    7: "#666769",
    8: "#444446",
    9: "#222223",
  },
  green: {
    default: "#2AC697",
    1: "#D4F4E9",
    2: "#AAE8D5",
    3: "#7FDDC0",
    4: "#60D2AC",
    5: "#2AC697",
    6: "#229E79",
    7: "#19775B",
    8: "#114F3C",
    9: "#08281E",
  },
  blue: {
    default: "#3641F1",
    1: "#D7D9F9",
    2: "#AFB4F4",
    3: "#868EF0",
    4: "#5D69EC",
    5: "#3641F1",
    6: "#2937BA",
    7: "#1F298B",
    8: "#151B5C",
    9: "#0B0D2E",
  },
  yellow: {
    default: "#FFC46B",
    1: "#FFF3E1",
    2: "#FFE7C4",
    3: "#FFE7C4",
    4: "#FFD089",
    5: "#FFC46B",
    6: "#CC9D56",
    7: "#997640",
    8: "#664E2B",
    9: "#332715",
  },
  red: {
    default: "#F45770",
    1: "#FDDDE2",
    2: "#FBBCC6",
    3: "#F89AA9",
    4: "#F6798D",
    5: "#F45770",
    6: "#C3465A",
    7: "#923443",
    8: "#62232D",
    9: "#311116",
  },
  purple: {
    default: "#7954B6",
    1: "#E4DDF0",
    2: "#C9BBE2",
    3: "#AF98D3",
    4: "#9476C5",
    5: "#7954B6",
    6: "#614392",
    7: "#49326D",
    8: "#302249",
    9: "#181124",
  },
  pink: {
    default: "#E56DE8",
    1: "#FAE2FA",
    2: "#F5C5F6",
    3: "#F0A7F1",
    4: "#EB8AEC",
    5: "#E56DE8",
    6: "#B857B9",
    7: "#8A418B",
    8: "#5C2C5D",
    9: "#2E162E",
  },
};

export const getRandomColor = () => {
  const list = [
    colorList.green.default,
    colorList.blue.default,
    colorList.yellow.default,
    colorList.red.default,
    colorList.purple.default,
    colorList.pink.default,
  ];
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
};

export const getTxtColorByBkg = (bkg, fade) => {
  const lightText = [
    "blue",
    "green",
    "red",
    "purple",
    "black",
    "gray-9",
    "gray-8",
    "gray-7",
    "gray-6",
  ];
  const selectedBkg = fade ? `${bkg}-${fade}` : bkg;
  return lightText.includes(selectedBkg) ? "white" : "black";
};

export const Color = (color, fade) => {
  let newColor = colorList[color] ? colorList[color].default : null;
  if (fade && colorList[color] && colorList[color][fade]) {
    newColor = colorList[color][fade];
  }
  return newColor;
};

export const breakpoint = {
  mobileS: "320px",
  mobileM: "425px",
  mobileL: "550px",
  mobileXL: "640px",
  tablet: "768px",
  tabletL: "900px",
  laptop: "1024px",
  laptopL: "1180px",
  desktop: "1400px",
  desktopL: "2560px",
};

export const device = {
  mobileS: `(min-width: ${breakpoint.mobileS})`,
  mobileM: `(min-width: ${breakpoint.mobileM})`,
  mobileL: `(min-width: ${breakpoint.mobileL})`,
  mobileXL: `(min-width: ${breakpoint.mobileXL})`,
  tablet: `(min-width: ${breakpoint.tablet})`,
  tabletL: `(min-width: ${breakpoint.tabletL})`,
  laptop: `(min-width: ${breakpoint.laptop})`,
  laptopL: `(min-width: ${breakpoint.laptopL})`,
  desktop: `(min-width: ${breakpoint.desktop})`,
  desktopL: `(min-width: ${breakpoint.desktopL})`,
};

export const deviceMax = {
  mobileS: `(max-width: ${breakpoint.mobileS})`,
  mobileM: `(max-width: ${breakpoint.mobileM})`,
  mobileL: `(max-width: ${breakpoint.mobileL})`,
  mobileXL: `(max-width: ${breakpoint.mobileXL})`,
  tablet: `(max-width: ${breakpoint.tablet})`,
  tabletL: `(max-width: ${breakpoint.tabletL})`,
  laptop: `(max-width: ${breakpoint.laptop})`,
  laptopL: `(max-width: ${breakpoint.laptopL})`,
  desktop: `(max-width: ${breakpoint.desktop})`,
  desktopL: `(max-width: ${breakpoint.desktopL})`,
};

export const MAX_SEARCH = 20;

export const currentYear = new Date().getFullYear();

// export const planSettings = (type) => {
//   switch (type.toUpperCase()) {
//     case "ENTERPRISE":
//       return {
//         allDiseases: true,
//         allProducts: true,
//         allConferences: true,
//         territory: true,
//         topInfluencers: true,
//         automatedInsights: true,
//         aiChat: true,
//       };
//     case "BASIC":
//       return {
//         diseases: 2,
//         products: 2,
//         conferences: 2,
//         territory: false,
//         topInfluencers: true,
//         automatedInsights: false,
//         aiChat: false,
//         message1:
//           "Basic plans include two active diseases, two products and two congresses monitors.",
//         message2:
//           "To change your active disease, product, or congress monitors first remove the current active one by selecting the its name on the Manage preferences page. Select 'Remove' and then you can add the desired monitor using 'Add to preferences'.",
//         message3:
//           "To increase the number of allowed diseases, products and congresses monitored please",
//         linkLabel: " upgrade your plan.",
//         linkTo: "/pricing",
//       };
//     case "PRO":
//       return {
//         diseases: 10,
//         products: 10,
//         conferences: 10,
//         territory: true,
//         topInfluencers: true,
//         automatedInsights: false,
//         aiChat: false,
//         message1:
//           "Pro plans offer the capability to actively monitor ten diseases, ten products, and ten congresses simultaneously.",
//         message2:
//           "To change your active disease, product or congress monitors first remove the current active one by selecting the its name on the Manage preferences page. Select 'Remove' and then you can add the desired monitor using 'Add to preferences'.",
//         message3:
//           "To increase the number of allowed diseases, products and congresses monitored please",
//         linkLabel: " contact sales ",
//         linkTo: "mailto:hello@medical.watch",
//         message4: "to upgrade to our Enterprise plan.",
//       };
//     case "DISEASE":
//       return {
//         diseases: 1,
//         products: 0,
//         conferences: 1,
//         topInfluencers: true,
//         automatedInsights: false,
//         aiChat: false,
//         message1:
//           "Our Medical.watch Free Disease Only plan lets you follow 1 disease and 1 congress.",
//         message2:
//           "To change your active disease or congress monitors first remove the current active one by selecting the its name on the Manage preferences page. Select 'Remove' and then you can add the desired monitor using 'Add to preferences'.",
//         message3:
//           "To follow products (or more diseases and congresses), please",
//         linkLabel: " upgrade your plan.",
//         linkTo: "/pricing",
//       };
//     case "HCP":
//     case "OTHER":
//       return {
//         allDiseases: true,
//         products: 0,
//         allConferences: true,
//         territory: true,
//         topInfluencers: true,
//         automatedInsights: true,
//         aiChat: true,
//         // message1:
//         //   "HCP plans include unlimited diseases and congresses monitors.",
//         // message2:
//         //   "To change your active disease monitors first remove the current active one by selecting the its name on the Manage preferences page. Select 'Remove' and then you can add the desired monitor using 'Add to preferences'.",
//         // message3:
//         //   "To increase the number of products monitored please",
//         // linkLabel: " upgrade your plan.",
//         // linkTo: "/pricing",
//       };

//     default:
//       return {
//         diseases: 1,
//         products: 1,
//         conferences: 1,
//         topInfluencers: true,
//         automatedInsights: false,
//         aiChat: false,
//         message1:
//           "Free plans include one active disease, one product and one congress monitor.",
//         message2:
//           "To change your active disease, product or congress monitors first remove the current active one by selecting the its name on the Manage preferences page. Select 'Remove' and then you can add the desired monitor using 'Add to preferences'.",
//         message3:
//           "To increase the number of allowed diseases, products and congresses monitored please",
//         linkLabel: " upgrade your plan.",
//         linkTo: "/pricing",
//       };
//   }
// };
