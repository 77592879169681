import { SETTINGS_ACTIONS } from "../actions";

const initialState = {
  account: {
    dates: [],
    chartType: 1,
    tags: [],
    sortTweetsBy: "score",
    sortTableBy: "name",
    sortTableDirection: "asc",
    ai: [],
    savedOnly: false,
  },
  disease: {
    status: 2,
  },
  product: {
    status: 2,
  },
  tag: {
    status: "ALL",
    meaning: "ALL",
  },
  tweets: {
    include: "1",
    period: 2,
  },
  home: {
    monitor: {},
    dates: [],
    chartType: 1,
    activeTabs: [],
    filterTip: false,
    tags: [],
    allRegions: [],
    regions: {},
    AI: false,
    savedOnly: {},
  },
  ai: [],
  adminPage: "conferences",
  search: {},
  abVersion: null,
  cookies: {
    seen: false,
    accepted: false,
  },
  influencers: {
    disease: {
      date: null,
    },
  },
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_ACTIONS.SET:
      return { ...state, ...action.payload.data };

    default:
      return state;
  }
}
