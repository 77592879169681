import React, { useEffect, useState, useCallback } from "react";

import styled from "styled-components";
import { Color } from "../utils/variables";
import { Icon } from "./Icon";
import { Row, Column } from "./Layout";
import { Label, H1 } from "./Typography";
import PropTypes from "prop-types";
import { Button } from "./Button";
// import { addLineJumps } from "../../../utils/formats";

export const Input = (props) => {
  let {
    icon,
    expand,
    reverse,
    testID,
    errorMessage,
    width,
    iconAction,
    iconSize,
    maxCharacters,
    // small,
    // large,
    rounded,
    label,
    bold,
    paddingLeft,
    maxWidth,
    transparent,
    min,
    max,
    step,
  } = props;

  const [hasFocus, setHasFocus] = useState(false);
  const [charsLeft, setCharsLeft] = useState(
    props.value ? maxCharacters - props.value.length : maxCharacters
  );
  const [inputValue, setInputValue] = useState(
    props.value || (props.number ? 0 : "")
  );

  const inputOnChange = useCallback(
    (e) => {
      let value = e && e.target ? e.target.value : e;
      setCharsLeft(maxCharacters - value.length);
      setInputValue(value);
      props.onChange && props.onChange(e);
    },
    [inputValue]
  );

  useEffect(() => {
    setInputValue(props.value);
    setCharsLeft(maxCharacters - props.value?.length || 0);
  }, [props.value]);

  return (
    <Column stretch={!width}>
      <InputContainer
        className={hasFocus ? "focus input-wrapper" : "input-wrapper"}
        {...{
          expand,
          label,
          reverse,
          maxCharacters,
          rounded,
          maxWidth,
          transparent,
        }}
      >
        {icon && (
          <IconContainer reverse={reverse} iconAction={iconAction}>
            {iconAction ? (
              <Row onClick={() => iconAction()} style={{ cursor: "pointer" }}>
                <Icon
                  name={props.icon}
                  color="gray"
                  fade="8"
                  size={iconSize || null}
                />
              </Row>
            ) : (
              <Row>
                <Icon
                  name={props.icon}
                  color="gray"
                  fade="8"
                  size={iconSize || null}
                />
              </Row>
            )}
          </IconContainer>
        )}

        <InputField
          autoComplete={props?.autoComplete || "off"}
          accessibilityLabel={testID}
          onFocus={() => !hasFocus && setHasFocus(true)}
          onBlur={() => hasFocus && setHasFocus(false)}
          {...props}
          ref={props.inputRef}
          value={inputValue || ""}
          maxLength={maxCharacters}
          onChange={(e) => inputOnChange(e)}
        />

        {maxCharacters && (
          <InputCounter>
            <Label color="gray" semibold fade="5">
              {charsLeft}
            </Label>
          </InputCounter>
        )}
      </InputContainer>
      {!!errorMessage && (
        <Row marginTop="10">
          <Label color={transparent ? "white" : "red"}>{errorMessage}</Label>
        </Row>
      )}
    </Column>
  );
};

export const Error = (props) => {
  let { children, color } = props;
  return (
    <Row marginTop="10">
      <Label color={color || "red"}>{children}</Label>
    </Row>
  );
};

export const TextArea = (props) => {
  let { expand, testID, maxCharacters, error, errorMessage, placeholder } =
    props;

  const [charsLeft, setCharsLeft] = useState(
    props.value ? maxCharacters - props.value.length : maxCharacters
  );
  const [inputValue, setInputValue] = useState(props.value || "");
  const [hasFocus, setFocus] = useState(false);
  const [rows, setRows] = useState(props.rows || 3);
  const minRows = props.minRows || 3;
  const maxRows = props.maxRows || 15;
  const inputRef = React.useRef(null);

  const inputOnChange = useCallback(
    (e) => {
      let value = e && e.target ? e.target.value : e;
      setCharsLeft(maxCharacters - value.length);
      setInputValue(value);
      setInputSize(inputRef.current);
      props.onChange && props.onChange(e);
    },
    [inputValue]
  );

  useEffect(() => {
    if (hasFocus) {
      setInputSize(inputRef.current);
      inputRef.current.scrollTo(0, 0);
      inputRef.current.focus();
    }
  }, [hasFocus]);

  const inputOnFocus = (e) => {
    setFocus(true);
    props.onFocus && props.onFocus(e);
  };

  const inputOnBlur = (e) => {
    setFocus(false);
    props.onBlur && props.onBlur(e);
  };

  const setInputSize = (e) => {
    const textareaLineHeight = 21;
    const previousRows = e.rows;
    e.rows = minRows;
    const currentRows = ~~(e.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      e.rows = maxRows;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  // const formatContent = (text) => ({
  //   __html: addLineJumps(text),
  // });

  return (
    <Column stretch>
      <TextAreaContainer
        className={hasFocus ? "focus" : ""}
        expand={expand}
        error={error}
      >
        <TextAreaField
          className={hasFocus ? "scroll" : "hide"}
          ref={inputRef}
          multiline
          spellCheck
          accessibilityLabel={testID}
          {...props}
          placeholder={placeholder || ""}
          rows={rows}
          value={inputValue}
          maxLength={maxCharacters}
          onChange={inputOnChange}
          onFocus={inputOnFocus}
          onBlur={inputOnBlur}
        />

        {maxCharacters && (
          <InputCounter>
            <Label color="gray" semibold fade="5">
              {charsLeft}
            </Label>
          </InputCounter>
        )}
      </TextAreaContainer>
      {!!errorMessage && (
        <Row marginTop="10">
          <Label color="red">{errorMessage}</Label>
        </Row>
      )}
    </Column>
  );
};

const InputCounter = styled.div`
  position: absolute;
  right: 0;
  bottom: -20px;
`;

const InputField = styled.input`
  background-color: transparent;
  color: black;
  height: ${(props) => (props.small ? "44px" : props.large ? "63px" : "50px")};
  border: none;
  outline: none;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: ${(props) => props.bold && "Oxigen-Bold"};

  ${(props) =>
    props.errorMessage && props.errorText && `color: ${Color("red")};`}
  font-size: ${(props) =>
    props.small ? "16px" : props.large ? "24px" : "20px"};
  line-height: 21px;
  text-align: left;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  padding-left: ${(props) => props.paddingLeft && `${props.paddingLeft}px`};

  &::placeholder {
    opacity: 1;
    color: ${Color("gray", 8)};
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px ${Color("gray", 2)} inset !important;
  }
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 50px ${Color("gray", 3)} inset !important;
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  ${(props) => props.reverse && `flex-direction: row-reverse;`}
  align-items: center;
  background-color: ${Color("gray", "2")};
  border-radius: ${(props) => (props.rounded ? "35px" : "15px")};
  padding: ${(props) => (props.label ? "0 10px" : "0 20px")};
  max-width: ${(props) =>
    props.expand ? "100%" : props.maxWidth ? props.maxWidth : "400px"};
  width: 100%;

  &.focus,
  &:hover {
    background-color: ${Color("gray", "3")};
  }

  ${(props) =>
    props.transparent &&
    `
      background-color: rgba(255, 255, 255, 0.3);

      input {
        color: white;
        &::placeholder {
          color: white;
        }
      }

      &.focus,
      &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      }
  `}
`;

const TextAreaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color("gray", 2)};
  border-radius: 15px;
  width: ${(props) =>
    props.expand ? "100%" : props.width ? props.width + "px" : "200px"};
  box-shadow: ${(props) =>
    props.error ? `0 0 1px 1px ${Color("red")}` : "unsert"};
  min-height: 117px;

  &.focus,
  &:hover {
    background-color: ${Color("gray", "3")};
  }
`;

const IconContainer = styled.div`
  ${(props) =>
    props.reverse
      ? "padding-left: 10px; margin-right: -5px; margin-left: 0; padding-left: 5px;"
      : "padding-right: 10px"};
  display: flex;
`;

const TextAreaField = styled.textarea`
  background-color: transparent;
  padding: 20px 20px 1px;
  overflow: auto;
  height: auto;
  font-size: 18px;
  line-height: 24px;
  width: ${(props) =>
    props.expand ? "100%" : props.width ? props.width + "px" : "200px"};
  border-radius: 15px;
  outline: none;
  border: none;
  resize: none;

  &::placeholder {
    opacity: 1;
    color: ${Color("gray", 8)};
  }
`;

Input.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  rounded: PropTypes.bool,
  testID: PropTypes.string,
  error: PropTypes.bool,
  reverse: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.string,
  iconAction: PropTypes.func,
  maxCharacters: PropTypes.number,
  onChange: PropTypes.func,
};

TextAreaContainer.propTypes = {
  placeholder: PropTypes.string,
};

TextArea.propTypes = {
  expand: PropTypes.bool,
  testID: PropTypes.string,
  maxCharacters: PropTypes.number,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
};
