import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import moment from "moment";

import {
  tagActions,
  tweetActions,
  scrollActions,
  settingsActions,
  accountActions,
  monitorActions,
  planActions,
} from "../../store/actions";
import {
  cancelAccountRequests,
  cancelHcpRequests,
  cancelTweetRequests,
  cancelTagRequests,
} from "../../store/sagas";
import { isReady } from "../../utils";

import { view } from "./AccountView";
import useDebounce from "../../utils/useDebounce";
import { MAX_RESULTS } from "../../utils/variables";
import {
  getISOFormattedDateTimeString,
  getUrlParams,
  logAnalyticEvent,
  formatNumberWithCommas,
} from "../../utils";
import {
  getAllRelationsTo,
  getAllRelationsFrom,
  getRelationsToByCountry,
  getRelationsFromByCountry,
} from "../../store/sagas/accountSagas";
import { getTweets } from "../../store/sagas/tweetSagas";
import {
  getNPITaxonomies,
  getTopArticles,
} from "../../store/sagas/accountSagas";
import { setWebEvent } from "../../store/queries/events";
import {
  useGetTagSummary,
  useGetAccountAttributes,
} from "../../store/queries/account";

export const Account = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const currentDate = moment().endOf("day");
  const lastMonth = moment().add(-1, "months").add(1, "days").startOf("day");
  const initialDates = {
    start: getISOFormattedDateTimeString(lastMonth),
    end: getISOFormattedDateTimeString(currentDate),
  };

  const { data: tagSummary } = useGetTagSummary({
    id,
    maxResult: 1,
    objectType: "DISEASE",
  });

  const { data: accountAttributes } = useGetAccountAttributes({
    id,
  });

  // --> mapStateToProp
  const userPlans = useSelector((state) => state.plan.userPlans);
  const AIResponse = useSelector((state) => state.account.gpt);
  const aiSettings = useSelector((state) => state.settings?.account?.ai);
  const accountSettings = useSelector((state) => state.settings?.account);
  const auth = useSelector((state) => state.auth.data);
  const hcp = useSelector((state) => state.account.one);
  const accountRelationsTo = useSelector((state) => state.account.relationsTo);
  const accountRelationsFrom = useSelector(
    (state) => state.account.relationsFrom
  );
  const tweets = useSelector((state) => state.tweet.all);
  const loading = useSelector((state) => state.tweet.all.fetching);
  const tweetsReady = useSelector((state) => state.tweet.all.success);
  const tweetsCount = useSelector((state) => state.tweet.tweetsCount.data);
  const scrollPosition = useSelector((state) => state.scroll.account);
  const nav = useSelector((state) => state.nav);
  const initialTags = useSelector((state) => state.tag.all);
  const whitelist = useSelector((state) => state.tag.whitelist.data);
  const sortBy = useSelector(
    (state) => state.settings?.account?.sortTweetsBy || "score"
  );
  const sortTableBy = useSelector(
    (state) => state.settings?.account?.sortTableBy || "name"
  );
  const sortTableDirection = useSelector(
    (state) => state.settings?.account?.sortTableDirection || "asc"
  );
  const products = useSelector((state) => state.account.products);
  // <-- mapStateToProp

  const contentRef = useRef();
  const noFetching = useRef(false);
  const isFirstRun = useRef(true);
  if (
    (nav.current?.includes("/post/") || nav.prev?.includes("/post/")) &&
    scrollPosition &&
    isFirstRun.current
  ) {
    noFetching.current = true;
  }

  // --> STATE
  const [isNewAI, setIsNewAI] = useState(false);
  const [refreshAI, setRefreshAI] = useState(false);
  const [queryParams, setQueryParams] = useState(getUrlParams(location.search));
  const [followed, setFollowed] = useState([]);
  const [follows, setFollows] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [followedPage, setFollowedPage] = useState(0);
  const [followsPage, setFollowsPage] = useState(0);
  const [expotVisibility, toggleExportVisibility] = useState(false);
  const [filterVisibility, toggleFilterVisibility] = useState(false);
  const [npiTaxonomies, setNpiTaxonomies] = useState([]);
  const [topArticles, setTopArticles] = useState([]);
  const [loadingTopArticles, setLoadingTopArticles] = useState([]);
  const [showToast, toggleToast] = useState(false);

  const [dates, setDates] = useState({
    start: null,
    end: null,
    selectedPeriod: "0",
  });

  const [sortByOptions] = useState([
    { id: "score", label: "Most Influential" },
    { id: "date", label: "Newest" },
  ]);

  const [tweetTypes] = useState([
    { id: "1", label: "Original Posts" },
    { id: "2", label: "Include Replies & Quotes" },
    { id: "3", label: "Include All" },
  ]);

  const [sentimentTypes] = useState([
    { id: "2", label: "Include All" },
    // { id: "3", label: "Only With Sentiments" },
    { id: "1", label: "Positive" },
    { id: "0", label: "Neutral" },
    { id: "-1", label: "Negative" },
  ]);

  const [pageTweets, setPageTweets] = useState(0);
  const [tabs, setTabs] = useState([
    { id: "summary", label: "Summary" },
    { id: "charts", label: "Posts" },
    { id: "followed", label: "Followed by" },
    { id: "follows", label: "Follows" },
  ]);
  const [activeTab, setActiveTab] = useState("summary");
  const [visitedTabs, setVisitedTabs] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchTag, setSearchTag] = useState(accountSettings?.tag?.[0]?.label);
  const [selectedTags, setSelectedTags] = useState([]);
  const debounceTag = useDebounce(searchTag, 700);
  const [pageTags, setPageTags] = useState(0);
  const [mainKeywords, setMainKeywords] = useState([]);
  const [allKeywords, setAllKeywords] = useState([]);
  const [selectedTweetType, setSelectedTweetType] = useState("3");
  const [selectedSentiment, setSelectedSentiment] = useState(
    accountSettings?.sentiment?.[id] || "2"
  );
  const [followedByCountry, setFollowedByCountry] = useState([]);
  const [followsByCountry, setFollowsByCountry] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [savedOnly, setSavedOnly] = useState(
    accountSettings?.savedOnly || false
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [visible, toggleVisibility] = useState(false);
  const [error, setError] = useState(false);
  // <-- STATE

  const cookies = useSelector((state) => state.settings.cookies);

  const requestTweets = (authorId, pageNum) => {
    // get tweets
    dispatch(
      tweetActions.request({
        externalId: authorId,
        addTagIds: selectedTags?.map((t) => t.id) || [],
        ...(selectedSentiment !== "2" && {
          withSentiment: true,
          sentiment: selectedSentiment,
        }),
        ...(auth?.id && { mwUserId: auth.id }),
        ...(selectedTweetType === "1" && { originalOnly: true }), // original
        ...(selectedTweetType === "2" && {
          originalOnly: false,
          includeReplies: true,
        }), // original and replies
        ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
        startDate: dates.start,
        endDate: dates.end,
        pageNum,
        maxResult: savedOnly ? -1 : MAX_RESULTS, // fetch all posts when 'savedOnly' is true
        sortBy: sortBy,
        savedOnly: savedOnly,
      })
    );

    if (pageNum === 0) {
      dispatch(
        tweetActions.tweetsCountRequest({
          addTagIds: selectedTags?.map((t) => t.id) || [],
          externalId: authorId,
          ...(selectedSentiment !== "2" && {
            withSentiment: true,
            sentiment: selectedSentiment,
          }),
          ...(auth?.id && { mwUserId: auth.id }),
          ...(selectedTweetType === "1" && { originalOnly: true }), // original
          ...(selectedTweetType === "2" && {
            originalOnly: false,
            includeReplies: true,
          }), // original and replies
          ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
          startDate: dates.start,
          endDate: dates.end,
          savedOnly: savedOnly,
        })
      );
    }
  };

  const handleMoreTweets = () => {
    if (!loading) {
      const newPage = pageTweets + 1;
      requestTweets(hcp.data?.externalId, newPage);
      setPageTweets(newPage);
    }
  };

  const [tweetsRef] = useInfiniteScroll({
    loading,
    hasNextPage: savedOnly ? false : !tweets.isLastPage,
    onLoadMore: handleMoreTweets,
  });

  const requestProducts = (authorId) => {
    // get products
    dispatch(
      accountActions.productsRequest({
        id: authorId,
        startDate: dates.start,
        endDate: dates.end,
      })
    );
  };

  const requestAccountRelationsTo = (pageNum) => {
    if (hcp.data.id) {
      dispatch(
        accountActions.relationsToRequest({
          id: hcp.data.id,
          relationship: "FOLLOWING",
          type: "PERSON",
          sortBy: sortTableBy,
          sortOrder: sortTableDirection,
          pageNum: pageNum || 0,
          maxResult: MAX_RESULTS,
        })
      );
    }
  };

  const handleMoreFollowed = () => {
    if (!accountRelationsTo.fetching) {
      const newPage = followedPage + 1;
      requestAccountRelationsTo(newPage);
      setFollowedPage(newPage);
    }
  };

  const [followedRef] = useInfiniteScroll({
    loading: accountRelationsTo.fetching,
    hasNextPage: !accountRelationsTo.isLastPage,
    onLoadMore: handleMoreFollowed,
  });

  const requestAccountRelationsFrom = (pageNum) => {
    if (hcp.data.id) {
      dispatch(
        accountActions.relationsFromRequest({
          id: hcp.data.id,
          relationship: "FOLLOWING",
          type: "PERSON",
          sortBy: sortTableBy,
          sortOrder: sortTableDirection,
          pageNum: pageNum || 0,
          maxResult: MAX_RESULTS,
        })
      );
    }
  };

  const handleMoreFollows = () => {
    if (!accountRelationsFrom.fetching) {
      const newPage = followsPage + 1;
      requestAccountRelationsFrom(newPage);
      setFollowsPage(newPage);
    }
  };

  const [followsRef] = useInfiniteScroll({
    loading: accountRelationsFrom.fetching,
    hasNextPage: !accountRelationsFrom.isLastPage,
    onLoadMore: handleMoreFollows,
  });

  const requestRelationsToByCountry = async () => {
    if (hcp.data.id) {
      const data = await getRelationsToByCountry({
        id: hcp.data.id,
        type: "PERSON",
      });
      if (data.data.length > 0) {
        const temp = data.data.map((t) => {
          return { id: t.countryCode, value: t.count };
        });
        setFollowedByCountry(temp);
      }
    }
  };

  const requestRelationsFromByCountry = async () => {
    if (hcp.data.id) {
      const data = await getRelationsFromByCountry({
        id: hcp.data.id,
        type: "PERSON",
      });
      if (data.data.length > 0) {
        const temp = data.data.map((t) => {
          return { id: t.countryCode, value: t.count };
        });
        setFollowsByCountry(temp);
      }
    }
  };

  // keywords search
  const requestTags = (pageNum) => {
    dispatch(
      tagActions.request({
        meaning: null,
        ignore: false,
        // preferred: false,
        includeDiseases: true,
        includeProducts: true,
        maxResult: MAX_RESULTS,
        ...(searchTag !== "" && { term: searchTag }),
        pageNum,
        nocache: "false",
      })
    );
  };

  useEffect(() => {
    if (!noFetching.current) {
      setPageTags(0);
      requestTags(0);
    }
  }, [debounceTag]);

  useEffect(() => {
    const newTags = initialTags.data.map((tag) => {
      return {
        ...tag,
        name: tag.preferredTagName || tag.name,
        id: tag.preferredTagId || tag.id,
        suggestion: !!tag.preferredTagId,
      };
    });

    setTags(newTags);
  }, [initialTags.data]);

  useEffect(() => {
    if (auth?.active && !userPlans?.data?.[0]) {
      dispatch(planActions.userPlansRequest({ userId: auth.id, active: true }));
    }

    if (auth?.active) {
      setWebEvent({ userId: auth.id, eventType: "ACCOUNT", objectId: id });
    }
  }, [auth]);

  const getUserType = () => {
    return (
      auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
        ?.preferenceStringValue || "MEDICAL"
    );
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    const selectedPeriod = e.selectedPeriod;
    setDates({
      start,
      end,
      selectedPeriod,
    });
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          dates: {
            ...accountSettings?.dates,
            [id]: {
              startDate: start,
              endDate: end,
              selectedPeriod,
            },
          },
        },
      })
    );
  };

  const handleSortByChange = (item) => {
    dispatch(
      settingsActions.set({
        account: { ...accountSettings, sortTweetsBy: item.id },
      })
    );
  };

  const handleSortTableBy = (id) => {
    dispatch(
      settingsActions.set({
        account: { ...accountSettings, sortTableBy: id },
      })
    );
  };

  const handleSortTableDirection = (id) => {
    dispatch(
      settingsActions.set({
        account: { ...accountSettings, sortTableDirection: id },
      })
    );
  };

  const addSelectedTag = (item, replace) => {
    if (selectedTags.length === 5) {
      toggleToast(true);
      return;
    }
    if (item) {
      let newTags = [];
      if (replace) {
        newTags = item;
      } else {
        newTags = [
          ...selectedTags,
          {
            id: item.id,
            label: item.label,
          },
        ];
      }

      setSelectedTags(newTags);
      dispatch(
        settingsActions.set({
          account: {
            ...accountSettings,
            tags: {
              ...accountSettings?.tags,
              [id]: newTags,
            },
          },
        })
      );
    }
  };

  const removeSelectedTag = (item) => {
    const newTags = selectedTags.filter((i) => i.id !== item.id);

    setSelectedTags(newTags);
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          tags: { ...accountSettings?.tags, [id]: newTags },
        },
      })
    );
  };

  const handleSearchTag = (e) => {
    setSearchTag(e);
  };

  const clearTag = () => {
    setSearchTag("");
    addSelectedTag(null);
  };

  const handleMoreTags = () => {
    if (!initialTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  const getAllTags = () => {
    let tempAll = [];
    if (tags.length > 0) {
      tags.forEach((a) => {
        tempAll.push({
          id: a.id,
          label: a.name,
          suggestion: a.suggestion,
        });
      });
    }
    return tempAll;
  };

  const handleClearFilters = () => {
    setSelectedTags([]);
    setSavedOnly(false);
    setSelectedSentiment("2");
    setSelectedTweetType("3");
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          tags: {
            ...accountSettings?.tags,
            [id]: [],
          },
          savedOnly: false,
        },
      })
    );
  };

  // --> EFFECT

  useEffect(() => {
    if (selectedTags?.length > 0) {
      dispatch(
        tagActions.whitelistRequest({ ids: selectedTags.map((t) => t.id) })
      );
    }
  }, [selectedTags]);

  useEffect(() => {
    if (hcp?.data?.id) {
      dispatch(
        settingsActions.set({
          account: {
            ...accountSettings,
            recentlyViewed: {
              ...accountSettings?.recentlyViewed,
              [hcp.data.id]: {
                id: hcp.data.id,
                name: hcp.data.name,
                imageurl: hcp.data.imageURL,
                country: hcp.data.country,
                institname: hcp.data.institution?.name || "",
                timestamp: new Date().getTime(),
              },
            },
          },
        })
      );
    }
  }, [hcp?.data]);

  useEffect(() => {
    let list1 = [];
    if (selectedTags?.length > 0) {
      list1 = whitelist?.map((t) => t.name) || [];
    }
    const list2 = selectedTags?.map((t) => t.label) || [];
    setMainKeywords(list2);
    setAllKeywords([...list1, ...list2]);
  }, [whitelist, selectedTags]);

  const handleSelectedSentiment = (e) => {
    setSelectedSentiment(e.id);
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          sentiment: {
            ...accountSettings.sentiment,
            [id]: e.id,
          },
        },
      })
    );
  };

  const handleTweetTypeChange = (e) => {
    setSelectedTweetType(e.id);
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          tweetType: {
            ...accountSettings.tweetType,
            [id]: e.id,
          },
        },
      })
    );
  };

  const handleSelectedTab = async (id) => {
    setActiveTab(id);
    // check if the tab is visited
    if (!visitedTabs.includes(id)) {
      // check if followed to be called
      if (id === "followed") {
        // call for first time
        setFollowedPage(0);
        requestAccountRelationsTo(0);
        await requestRelationsToByCountry();
      }
      // check if follows to be called
      if (id === "follows") {
        // call for first time
        setFollowsPage(0);
        requestAccountRelationsFrom(0);
        await requestRelationsFromByCountry();
      }
      // set as visited
      setVisitedTabs([...visitedTabs, id]);
    }
    // replace tab param
    let url = window.location.origin + window.location.pathname;
    if (queryParams.length > 0) {
      queryParams.tab = id;
      setQueryParams(queryParams);
      const searchParams = queryParams.toString();
      url = `${url}?${searchParams}`;
    } else {
      url = `${url}?tab=${id}`;
    }
    window.history.replaceState(null, "Medical.watch", url);
  };

  const handleSignUpClick = () => {
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Sign Up",
      {
        page: "Account Details",
        ...(queryParams?.source && { source: queryParams.source }),
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null
    );
  };

  useEffect(() => {
    dispatch(accountActions.oneRequest(id));

    if (queryParams?.tab) {
      handleSelectedTab(queryParams.tab || "summary");
    }

    setSelectedTags(accountSettings?.tags?.[id] || []);

    if (accountSettings?.dates?.[id]) {
      const selectedPeriod = accountSettings.dates[id].selectedPeriod;
      let start = accountSettings.dates[id].startDate;
      let end = accountSettings.dates[id].endDate;
      switch (selectedPeriod) {
        case "1": // last 7 days
          start = moment().subtract(6, "days")._d;
          end = moment().endOf("day")._d;
          break;
        case "2": // last 30 days
          start = moment().subtract(1, "month")._d;
          end = moment().endOf("day")._d;
          break;
      }

      setDates({
        start: getISOFormattedDateTimeString(start),
        end: getISOFormattedDateTimeString(end),
        selectedPeriod,
      });
    } else {
      setDates(initialDates);
    }

    if (auth.active) {
      dispatch(monitorActions.monitorsRequest(auth.id));
    }

    return history.listen(() => {
      if (contentRef?.current && history.action === "PUSH") {
        dispatch(
          scrollActions.set({
            account: contentRef.current.scrollTop,
          })
        );
      }
    });
  }, [id]);

  useEffect(() => {
    if (auth?.active) {
      // (async () => {
      //   const temp = await getNPITaxonomies();
      //   if (temp.data.length > 0) {
      //     const allNpiTaxonomies = [];
      //     temp.data.forEach((row) => {
      //       allNpiTaxonomies.push({
      //         id: row.taxonomyCode,
      //         name: row.taxonomyName,
      //         label: row.taxonomyName,
      //       });
      //     });
      //     setNpiTaxonomies(allNpiTaxonomies);
      //   }
      // })();
    }

    return () => {
      setVisitedTabs([]); // clear state
      setActiveTab("summary"); // by default first tab
      dispatch(tagActions.clearTags({}));
      // dispatch(tweetActions.clearTweets());
      // dispatch(accountActions.clear());
      // dispatch(accountActions.gptClear());
      cancelHcpRequests.abort();
      cancelTweetRequests.abort();
      cancelTagRequests.abort();
      cancelAccountRequests.abort();
    };
  }, []);

  useEffect(() => {
    if (id && hcp.data?.externalId) {
      if (queryParams.tab === "followed") {
        setVisitedTabs([...visitedTabs, "followed"]); // set visited tab
        setFollowedPage(0);
        requestAccountRelationsTo(0); // fetch for first time
        (async () => {
          await requestRelationsToByCountry();
        })();
      }
      if (queryParams.tab === "follows") {
        setVisitedTabs([...visitedTabs, "follows"]); // set visited tab
        setFollowsPage(0);
        requestAccountRelationsFrom(0); // fetch for first time
        (async () => {
          await requestRelationsFromByCountry();
        })();
      }
    }
  }, [hcp.data]);

  useEffect(() => {
    if (accountRelationsTo?.data?.length > 0) {
      const tempFollowers = accountRelationsTo?.data
        .filter(
          (a) =>
            a.tAccountIdTo === hcp.data?.id && a.relationship === "FOLLOWING"
        )
        .map((f) => f.tAccountFrom);
      setFollowed(tempFollowers);
      if (tempFollowers.length > 0) {
        setTabs((oldTabs) => {
          const newTabs = oldTabs.map((tab) => {
            if (tab.id === "followed") {
              return {
                ...tab,
                visible: true,
              };
            }
            return tab;
          });
          return newTabs;
        });
      }
    }
  }, [accountRelationsTo?.data]);

  useEffect(() => {
    // redo call in case sorting changes pressing on the table header
    if (activeTab === "followed") {
      setFollowedPage(0);
      requestAccountRelationsTo(0);
    }
    if (activeTab === "follows") {
      setFollowsPage(0);
      requestAccountRelationsFrom(0);
    }
  }, [sortTableBy, sortTableDirection]);

  useEffect(() => {
    if (accountRelationsFrom?.data?.length > 0) {
      const tempFollows = accountRelationsFrom?.data
        .filter(
          (a) =>
            a.tAccountIdFrom === hcp.data?.id && a.relationship === "FOLLOWING"
        )
        .map((f) => f.tAccountTo);

      setFollows(tempFollows);

      if (tempFollows.length > 0) {
        setTabs((oldTabs) => {
          const newTabs = oldTabs.map((tab) => {
            if (tab.id === "follows") {
              return {
                ...tab,
                visible: true,
              };
            }
            return tab;
          });
          return newTabs;
        });
      }
    }
  }, [accountRelationsFrom?.data]);

  useEffect(() => {
    if (noFetching.current) {
      return;
    }
    if (isReady(hcp)) {
      const userType = getUserType();
      if (hcp.data?.externalId) {
        // for authenticated users and user type not OTHER or HCP, call products
        if (auth?.id && userType !== "OTHER" && userType !== "HCP") {
          // get product tags, along with diseases
          requestProducts(hcp.data.externalId);
        }
        // get tweets
        requestTweets(hcp.data.externalId, 0);
        // check for cookies first before publishing event
        if (!auth?.active && cookies?.seen && !cookies?.accepted) {
          return;
        }
        // log event
        logAnalyticEvent(
          "Account Details",
          {
            id: hcp.data?.id,
            externalId: hcp.data?.externalId,
            name: hcp.data?.name,
            username: "@" + hcp.data?.username,
            ...(queryParams?.source && { source: queryParams.source }),
          },
          auth?.id || queryParams?.u || null,
          auth?.displayName || queryParams?.n || null,
          auth?.email || queryParams?.e || null,
          auth?.createdAt || null,
          getUserType() || queryParams?.t,
          userPlans?.data?.[0]?.plan?.name || null
        );

        // get top articles for unauthenticated users or user type 'OTHER' or 'HCP'
        if (!auth?.id || userType === "OTHER" || userType === "HCP") {
          (async () => {
            setLoadingTopArticles(true);
            const temp = await getTopArticles({
              id: hcp.data.externalId,
              startDate: dates.start,
              endDate: dates.end,
            });
            if (temp.data.length > 0) {
              setTopArticles(temp.data);
            } else {
              setTopArticles([]);
            }
            setLoadingTopArticles(false);
          })();
        }
      }
    }
  }, [hcp, dates, selectedTags, savedOnly]);

  useEffect(() => {
    if (noFetching.current) {
      return;
    }

    if (isReady(hcp)) {
      if (hcp.data?.externalId) {
        // get tweets
        requestTweets(hcp.data?.externalId, 0);
      }
    }
  }, [sortBy, selectedSentiment, selectedTweetType]);

  // this should be the last effect: move scroll to previous position
  useEffect(() => {
    if (scrollPosition > 0 && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollTo(0, scrollPosition);
        noFetching.current = false;
      }, 200);
    }
  }, [scrollPosition, contentRef?.current]);
  // <-- EFFECT

  const exportCSVFollowed = async () => {
    const allFollowed = await getRelationsTo();
    let csvContent = "";
    // header
    csvContent += `HCP, Location, Country, Institution, Audience\n`;
    _.forEach(allFollowed.data, (user) => {
      csvContent += `"${user.tAccountFrom.name}",`;
      csvContent += `"${user.tAccountFrom.displayLocation || ""}",`;
      csvContent += `"${user.tAccountFrom?.country || ""}",`;
      csvContent += `"${user.tAccountFrom?.institutionName || ""}",`;
      csvContent += `"${user.tAccountFrom.numFollowers}",`;
      csvContent += `\n`;
    });
    const url = window.URL.createObjectURL(new Blob([csvContent]));
    const link = document.createElement("a");
    link.href = url;
    link.id = "mw-download-link";
    link.setAttribute(
      "download",
      `Followed_${moment().format("YYYYMMDDHHmmss")}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  const exportCSVFollows = async () => {
    const allFollows = await getRelationsFrom();
    let csvContent = "";
    // header
    csvContent += `HCP, Location, Country, Institution, Audience\n`;
    _.forEach(allFollows.data, (user) => {
      csvContent += `"${user.tAccountTo.name}",`;
      csvContent += `"${user.tAccountTo.displayLocation || ""}",`;
      csvContent += `"${user.tAccountTo?.country || ""}",`;
      csvContent += `"${user.tAccountTo?.institutionName || ""}",`;
      csvContent += `"${user.tAccountTo.numFollowers}",`;
      csvContent += `\n`;
    });
    const url = window.URL.createObjectURL(new Blob([csvContent]));
    const link = document.createElement("a");
    link.href = url;
    link.id = "mw-download-link";
    link.setAttribute(
      "download",
      `Follows_${moment().format("YYYYMMDDHHmmss")}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  const exportCSVForTweets = async () => {
    // check for time period, should not be more than 30 days
    const start = moment(dates.start);
    const end = moment(dates.end);
    const diff = end.diff(start, "days");
    if (diff > 31) {
      setErrorMessage(
        "Only 30 days of data can be exported at a time. Please adjust your date range or contact support@medical.watch for assistance."
      );
      toggleVisibility(true);
      setError(true);
      return;
    }

    const tweetsForExport = await getTweets({
      externalId: hcp.data.externalId,
      addTagIds: selectedTags?.map((t) => t.id) || [],
      ...(selectedSentiment !== "2" && {
        withSentiment: true,
        sentiment: selectedSentiment,
      }),
      ...(auth?.id && { mwUserId: auth.id }),
      ...(selectedTweetType === "1" && { originalOnly: true }), // original
      ...(selectedTweetType === "2" && {
        originalOnly: false,
        includeReplies: true,
      }), // original and replies
      ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
      startDate: dates.start,
      endDate: dates.end,
      pageNum: 0,
      maxResult: -1,
      sortBy: sortBy,
      savedOnly: savedOnly,
    });

    if (tweetsForExport.data?.length > 0) {
      let csvContent = "";
      // header
      csvContent += `Date, Content, Source, Sentiment, Name, Handle, Location, Country, Link, Tags, Replies, Reposts, Likes, Views\n`;
      _.forEach(tweetsForExport.data, (tweet) => {
        csvContent += `"${moment(tweet.externalCreatedAt)
          .utc()
          .format("MMM D, YYYY")}",`;
        csvContent += `"${tweet.content
          ?.replace(/(\n)/g, " ")
          ?.replace(/(\")/g, "'")}",`;
        csvContent += `"${tweet.source || "-"}",`;
        csvContent += `"${tweet.sentiment || "-"}",`;
        csvContent += `"${tweet.tAccount.name}",`;
        csvContent += `"${tweet.tAccount.username}",`;
        csvContent += `"${tweet.tAccount.location || ""}",`;
        csvContent += `"${tweet.tAccount.country || ""}",`;
        csvContent += `"${window.location.origin}/post/${tweet.externalId}",`;
        csvContent += `"${tweet.tagNames || ""}",`;
        csvContent += `"${tweet.numReplies}",`;
        csvContent += `"${tweet.numRetweets}",`;
        csvContent += `"${tweet.numLikes}",`;
        csvContent += `"${tweet.numViews}",`;
        csvContent += `\n`;
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement("a");
      link.href = url;
      link.id = "mw-download-link";
      link.setAttribute(
        "download",
        `Posts_${moment().format("YYYYMMDDHHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();

      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // log veent
      logAnalyticEvent(
        "Export Posts",
        {
          id: hcp.data?.id,
          externalId: hcp.data?.externalId,
          name: hcp.data?.name,
          username: "@" + hcp.data?.username,
          ...(queryParams?.source && { source: queryParams.source }),
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue ||
          queryParams?.t ||
          "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }
  };

  const getRelationsTo = async () => {
    return await getAllRelationsTo({
      id: hcp.data.id,
      relationship: "FOLLOWING",
      type: "PERSON",
      sortBy: sortTableBy,
      sortOrder: sortTableDirection,
    });
  };

  const getRelationsFrom = async () => {
    return await getAllRelationsFrom({
      id: hcp.data.id,
      relationship: "FOLLOWING",
      type: "PERSON",
      sortBy: sortTableBy,
      sortOrder: sortTableDirection,
    });
  };

  const getCounter = () => {
    let counter;
    if (tweetsReady) {
      counter = `${formatNumberWithCommas(tweetsCount)} posts`;
    }
    return counter;
  };

  const getActiveFilters = () => {
    let activeFilters = [];
    if (selectedTags.length > 0) {
      activeFilters = selectedTags.map((a) => a.label);
    }
    if (selectedSentiment !== "2") {
      activeFilters.push(
        `Sentiment: ${
          sentimentTypes.find((a) => selectedSentiment === a.id)?.label
        }`
      );
    }
    if (savedOnly) {
      activeFilters.push("Saved Only");
    }
    return activeFilters;
  };

  const handleSavedOnly = () => {
    setSavedOnly(!savedOnly);
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          savedOnly: !savedOnly,
        },
      })
    );
  };

  const handleAiRefresh = () => {
    console.log("refresh :>> ");
    dispatch(
      settingsActions.set({
        account: {
          ...accountSettings,
          ai: {
            ...aiSettings,
            [id]: { response: "", keywords: [] },
          },
        },
      })
    );

    // do we need to log event here ??
  };

  const requestGptQuery = () => {
    dispatch(
      accountActions.gtpRequest({
        id,
        startDate: dates.start,
        endDate: dates.end,
        sortBy: sortBy,
        addTagIds: selectedTags.map((a) => a.id),
        ...(selectedSentiment !== "2" && {
          withSentiment: true,
          sentiment: selectedSentiment,
        }),
        ...(selectedTweetType === "1" && { originalOnly: true }), // original
        ...(selectedTweetType === "2" && {
          originalOnly: false,
          includeReplies: true,
        }), // original and replies
        ...(selectedTweetType === "3" && { originalOnly: false }), // all tweets
        maxResult: 20,
        source: "X",
        userType: getUserType(),
        savedOnly: savedOnly,
      })
    );
  };

  useEffect(() => {
    if (!userPlans?.data?.[0]) {
      return;
    }
    if (userPlans?.data?.[0].plan.name.includes("Free")) {
      setUserPlan("FREE");
    }
    if (userPlans?.data?.[0].plan.name.includes("Basic")) {
      setUserPlan("BASIC");
    }
    if (userPlans?.data?.[0].plan.name.includes("Pro")) {
      setUserPlan("PRO");
    }
    if (userPlans?.data?.[0].plan.name.includes("HCP")) {
      setUserPlan("HCP");
    }
    if (userPlans?.data?.[0].plan.name.includes("Other")) {
      setUserPlan("OTHER");
    }
  }, [userPlans]);

  useEffect(() => {
    if (AIResponse.data?.response) {
      let responseTags = [];

      for (const key in AIResponse.data.tweetTagMap) {
        const kws = AIResponse.data.tweetTagMap[key]?.map((t) => ({
          id: t.id,
          name: t.name,
        }));
        responseTags.push(kws);
      }
      setIsNewAI(true);

      dispatch(
        settingsActions.set({
          account: {
            ...accountSettings,
            ai: {
              ...aiSettings,
              [id]: {
                response: AIResponse.data.response,
                keywords: responseTags.flat(),
                timestamp: getISOFormattedDateTimeString(moment()),
                startDate: dates.start,
                endDate: dates.end,
                tags: selectedTags.map((t) => t.id),
                sortBy,
                sentiment: selectedSentiment,
                tweetType: selectedTweetType,
              },
            },
          },
        })
      );
    }
  }, [AIResponse.data]);

  const hasAISettingsChanged = () => {
    let hasChanged = false;
    const aiMonitor = aiSettings?.[id];
    if (aiMonitor && dates.start) {
      const hasTagsChanged =
        aiMonitor.tags?.sort()?.join(",") !==
        selectedTags
          .map((t) => t.id)
          ?.sort()
          ?.join(",");

      const hasDatesChanged =
        moment(aiMonitor.startDate).format("YYYY-MM-DD") !==
          moment(dates.start).format("YYYY-MM-DD") ||
        moment(aiMonitor.endDate).format("YYYY-MM-DD") !==
          moment(dates.end).format("YYYY-MM-DD");
      const hasSortByChanged = aiMonitor.sortBy !== sortBy;
      const hasSentimentChanged = aiMonitor.sentiment !== selectedSentiment;
      const hasTweetTypeChanged = aiMonitor.tweetType !== selectedTweetType;
      hasChanged =
        savedOnly ||
        savedOnly === false ||
        hasDatesChanged ||
        hasTagsChanged ||
        hasSortByChanged ||
        hasSentimentChanged ||
        hasTweetTypeChanged;
    }
    return hasChanged;
  };

  useEffect(() => {
    setRefreshAI(hasAISettingsChanged());
  }, [
    dates,
    selectedTags,
    sortBy,
    selectedSentiment,
    selectedTweetType,
    savedOnly,
  ]);

  useEffect(() => {
    if (noFetching.current) {
      return;
    }
    if (auth.active && dates.start && dates.end) {
      let hasChanged = true;
      const aiMonitor = aiSettings?.[id];
      let hasExpired = true;
      if (aiMonitor) {
        const hasResponse = aiMonitor.response;
        if (aiMonitor.timestamp) {
          hasExpired =
            getISOFormattedDateTimeString(moment()) >
            getISOFormattedDateTimeString(
              moment(aiMonitor.timestamp).add(12, "hours")
            );
        }
        hasChanged = !hasResponse || hasExpired;
      }
      if (hasChanged) {
        setRefreshAI(false);
        requestGptQuery();
      }
    }
  }, [aiSettings, dates]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  }, [isFirstRun]);

  return view({
    isAdmin: auth?.mwRole === "ADMIN",
    isRegistered: auth.active,
    userType: getUserType(),
    history,
    hcp: hcp.data || [],
    isFetchingHCP: hcp.fetching || false,
    contentRef,
    tabs,
    activeTab,
    handleSelectedTab,
    counter: getCounter(),
    activeFilters: getActiveFilters(),

    followed, // account followed by
    followedRef,
    followedNextPage: !accountRelationsTo.isLastPage,
    followedLoading: accountRelationsTo.fetching,
    followedByCountry,

    follows, // account follows
    followsRef,
    followsNextPage: !accountRelationsFrom.isLastPage,
    followsLoading: accountRelationsFrom.fetching,
    followsByCountry,

    showExport:
      auth?.active &&
      userPlan !== "FREE" &&
      userPlan !== "BASIC" &&
      userPlan !== "HCP" &&
      userPlan !== "OTHER",
    showFilter: auth?.active && userPlan !== "FREE" && userPlan !== "BASIC",
    expotVisibility,
    toggleExportVisibility,
    filterVisibility,
    toggleFilterVisibility,
    exportCSVFollowed,
    exportCSVFollows,
    exportCSVForTweets,

    loading,
    tweetsReady,
    tweetsRef,
    tweetsNextPage: !tweets.isLastPage,
    tweets: tweets.data || [],
    tweetsCount,

    dates,
    handleDateChange,

    sortByOptions,
    sortBy,
    handleSortByChange,
    handleSortTableBy,
    handleSortTableDirection,

    tags: getAllTags(),
    searchTag,
    handleSearchTag,
    clearTag,
    selectedTags,
    addSelectedTag,
    removeSelectedTag,
    tagsInfinteScroll: {
      loading: initialTags.fetching,
      hasNextPage: !initialTags.isLastPage,
      onLoadMore: handleMoreTags,
    },
    handleClearFilters,
    allKeywords,
    mainKeywords,
    products: products.data || [],
    loadingProducts: products.fetching,
    sortTableBy,
    sortTableDirection,

    npiTaxonomies,

    showArticles:
      !auth?.id || getUserType() === "OTHER" || getUserType() === "HCP",
    loadingTopArticles,
    topArticles,

    sentimentTypes,
    selectedSentiment,
    handleSelectedSentiment,

    tweetTypes,
    selectedTweetType,
    handleTweetTypeChange,

    showCalendar,
    setShowCalendar,

    handleSignUpClick,

    AIKeywords: aiSettings ? aiSettings[id]?.keywords : [],
    isFetchingAI: AIResponse.fetching,
    isAISuccess: AIResponse.success,
    aiResponse: aiSettings ? aiSettings[id]?.response : null,
    hideAI: AIResponse?.data?.requestTokens === 0 || false,
    handleAiRefresh,
    isNewAI,
    setIsNewAI,
    refreshAI,

    savedOnly,
    handleSavedOnly,

    visible,
    toggleVisibility,
    errorMessage,
    error,

    showToast,
    toggleToast,
    tagSummary: tagSummary?.data?.[0]?.name,
    accountAttributes: accountAttributes?.data || [],
  });
};
