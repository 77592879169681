import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import moment from "moment";
import { useLocation } from "react-router-dom";

import {
  productActions,
  diseaseActions,
  monitorActions,
  tagActions,
  scrollActions,
  settingsActions,
  navActions,
  planActions,
  userActions,
} from "../../store/actions";
import {
  cancelProductRequests,
  cancelDiseaseRequests,
  cancelConferenceRequests,
  cancelUserRequests,
  cancelTagRequests,
  cancelHighlightRequests,
} from "../../store/sagas";
import { useGetRegions } from "../../store/queries/regions";
import useDebounce from "../../utils/useDebounce";
import { MAX_RESULTS } from "../../utils/variables";
import { view } from "./DashboardView";

import {
  getISOFormattedDateTimeString,
  getUrlParams,
  logAnalyticEvent,
  sortedMonitors,
} from "../../utils";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const contentRef = useRef();
  const downloadRef = useRef();
  const history = useHistory();
  const currentDate = moment().endOf("day");
  const lastMonth = moment().add(-30, "days").startOf("day");
  const isFirstRun = useRef(true);
  const { monitorType, monitorId } = useParams();

  const userPreferences = useSelector((state) => state.user.preferences);
  const scrollPosition = useSelector((state) => state.scroll.home);
  const homeSettings = useSelector((state) => state.settings?.home);
  const auth = useSelector((state) => state.auth.data);
  const whitelist = useSelector((state) => state.tag.whitelist.data);
  const sortBy = useSelector(
    (state) => state.settings?.home?.sortTweetsBy || "score"
  );
  const initialMonitors = useSelector(
    (state) => state.monitor.auth?.data || []
  );
  const abVersion = useSelector((state) => state.settings.abVersion);
  const userPlans = useSelector((state) => state.plan.userPlans);
  const monitorsDate = useSelector((state) => state.monitor.date);
  const highlights = useSelector((state) => state.highlight.all.data);
  const highlightsLoading = useSelector(
    (state) => state.highlight.all.fetching
  );

  const initialTags = useSelector((state) => state.tag.all);
  const nav = useSelector((state) => state.nav);

  const { data: initialRegions } = useGetRegions(auth);

  const showAI = homeSettings.AI || false;
  const noFetching = useRef(false);

  // we are not fetching if we are coming from the following page
  if (
    (nav.current?.includes("/post/") ||
      nav.prev?.includes("/post/") ||
      nav.current?.includes("/account/") ||
      nav.prev?.includes("/account/")) &&
    scrollPosition &&
    isFirstRun.current
  ) {
    noFetching.current = true;
  }

  const initialDates = {
    start: getISOFormattedDateTimeString(lastMonth),
    end: getISOFormattedDateTimeString(currentDate),
  };

  const [queryParams, setQueryParams] = useState(getUrlParams(location.search));
  const [shareUrl, setShareUrl] = useState("");
  const [monitors, setMonitors] = useState([]);
  const [selectedMonitor, setSelectedMonitor] = useState({});
  const [dates, setDates] = useState({
    start: null,
    end: null,
    selectedPeriod: "0",
  });
  const [tags, setTags] = useState([]);
  const [activeTab, setActiveTab] = useState(undefined);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [regionName, setRegionName] = useState(null);

  const [regions, setRegions] = useState(homeSettings.allRegions || []);
  const [searchTag, setSearchTag] = useState(
    homeSettings.tag?.[0]?.label || ""
  );
  const debounceTag = useDebounce(searchTag, 700);
  const [selectedTags, setSelectedTags] = useState(null);
  const [pageTags, setPageTags] = useState(0);
  const [allKeywords, setAllKeywords] = useState([]);
  const [mainKeywords, setMainKeywords] = useState([]);
  const [filterTip, setFilterTip] = useState(homeSettings?.filterTip);
  const [showCalendar, setShowCalendar] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [filterVisibility, toggleFilterVisibility] = useState(false);
  const [showToast, toggleToast] = useState(false);
  const [showWelcomeModal, toggleWelcomeModal] = useState(false);
  const [welcomeStep, setWelcomeStep] = useState(1);
  const [showExplosion, setShowExplosion] = useState(false);

  const cookies = useSelector((state) => state.settings.cookies);

  useEffect(() => {
    return history.listen(() => {
      if (contentRef?.current && history.action === "PUSH") {
        dispatch(
          scrollActions.set({
            home: contentRef.current.scrollTop,
          })
        );
      }

      cancelRequests();
      dispatch(planActions.clear());
    });
  }, []);

  useEffect(() => {
    if (noFetching.current) {
      return;
    }

    if (auth.active) {
      dispatch(
        userActions.requestPreferences({
          id: auth.id,
          name: "Welcome",
        })
      );
      const shouldRequestMonitors =
        initialMonitors.length === 0 ||
        moment().format("YYYY-MM-DD") !== monitorsDate;

      if (shouldRequestMonitors) {
        dispatch(monitorActions.monitorsRequest(auth.id));
        dispatch(monitorActions.date(moment().format("YYYY-MM-DD")));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (initialRegions?.data?.length > 0) {
      const rgns = [
        {
          id: "ALL",
          name: "All Regions",
          label: "All Regions",
        },
      ];
      initialRegions.data.forEach((row) => {
        rgns.push({
          id: row.id,
          name: row.name,
          label: row.name,
        });
      });
      setRegions(rgns);
      dispatch(
        settingsActions.set({
          home: { ...homeSettings, allRegions: rgns },
        })
      );
    }
  }, [initialRegions?.data]);

  useEffect(() => {
    if (auth?.active) {
      // if (auth?.active && !userPlans?.data?.[0]) {
      dispatch(planActions.userPlansRequest({ userId: auth.id, active: true }));
    }
  }, [auth]);

  useEffect(() => {
    if (!userPlans?.data?.[0]) {
      return;
    }
    if (userPlans?.data?.[0].plan.name.includes("Free")) {
      setUserPlan("FREE");
    }
    if (userPlans?.data?.[0].plan.name.includes("Basic")) {
      setUserPlan("BASIC");
    }
    if (userPlans?.data?.[0].plan.name.includes("Pro")) {
      setUserPlan("PRO");
    }
    if (userPlans?.data?.[0].plan.name.includes("HCP")) {
      setUserPlan("HCP");
    }
    if (userPlans?.data?.[0].plan.name.includes("Other")) {
      setUserPlan("OTHER");
    }
  }, [userPlans]);

  useEffect(() => {
    if ((initialMonitors?.length > 0 && isFirstRun.current) || !auth.active) {
      isFirstRun.current = false;
      const newMonitors = sortedMonitors(
        initialMonitors.filter((m) => m.visible)
      );

      // New url format
      if (
        monitorId &&
        (monitorType === "disease" || monitorType === "product")
      ) {
        const _monitor = {
          name: monitorId?.replace(/_/g, " ")?.toLowerCase(),
          external: true,
          type: monitorType.toUpperCase(),
          ...(queryParams?.source && { source: queryParams.source }),
        };
        setSelectedMonitor(_monitor);
      } else {
        // Old url format
        if (
          queryParams?.productId ||
          queryParams?.diseaseId ||
          queryParams?.conferenceId
        ) {
          if (queryParams?.diseaseId) {
            const _monitor = {
              id: queryParams.diseaseId,
              external: true,
              type: "DISEASE",
              ...(queryParams?.source && { source: queryParams.source }),
            };
            setSelectedMonitor(_monitor);
            postUpdateMonitor(_monitor);
            // newMonitors.push(_monitor);
          }
          if (queryParams?.productId) {
            const _monitor = {
              id: queryParams?.productId,
              external: true,
              type: "PRODUCT",
              ...(queryParams?.source && { source: queryParams.source }),
            };
            setSelectedMonitor(_monitor);
            postUpdateMonitor(_monitor);
            // newMonitors.push(_monitor);
          }
          if (queryParams?.conferenceId) {
            const _monitor = {
              id: queryParams.conferenceId,
              external: true,
              type: "CONFERENCE",
              ...(queryParams?.source && { source: queryParams.source }),
            };
            setSelectedMonitor(_monitor);
            postUpdateMonitor(_monitor);
            // newMonitors.push(_monitor);
          }
        } else {
          const storedMonitor = newMonitors.find(
            (m) => m.id === homeSettings?.monitor?.id
          );
          // #293 - ONLY render stored monitor if found
          // in user selection. Or else, render first one
          // console.log(storedMonitor, newMonitors);
          if (storedMonitor) {
            const found = newMonitors.filter((m) => m.id === storedMonitor.id);
            if (found.length > 0) {
              setSelectedMonitor(storedMonitor);
              postUpdateMonitor(storedMonitor);
            } else {
              setSelectedMonitor(newMonitors[0]);
              postUpdateMonitor(newMonitors[0]);
            }
          } else {
            setSelectedMonitor(newMonitors[0]);
            postUpdateMonitor(newMonitors[0]);
          }
        }
      }

      if (queryParams?.tab) {
        setActiveTab(queryParams.tab);
      }

      setMonitors(newMonitors);
    }
  }, [initialMonitors]);

  useEffect(() => {
    if (
      selectedMonitor?.id &&
      (monitorType === "disease" || monitorType === "product")
    ) {
      postUpdateMonitor(selectedMonitor.id);
    }
  }, [selectedMonitor?.id]);

  const postUpdateMonitor = (monitor) => {
    // update settings object
    dispatch(
      settingsActions.set({
        home: { ...homeSettings, monitor: monitor },
      })
    );
    // update selected tags
    setSelectedTags(!auth.id ? [] : homeSettings.tags?.[monitor?.id] || []);
    if (monitor?.id && homeSettings.dates?.[monitor.id]) {
      const selectedPeriod = homeSettings.dates[monitor.id].selectedPeriod;
      let start = homeSettings.dates[monitor.id].start;
      let end = homeSettings.dates[monitor.id].end;

      switch (selectedPeriod) {
        case "1": // last 7 days
          start = moment().subtract(6, "days").startOf("day")._d;
          end = moment().endOf("day")._d;
          break;
        case "2": // last 30 days
          start = moment().subtract(1, "month").startOf("day")._d;
          end = moment().endOf("day")._d;
          break;
      }
      setDates({
        start: getISOFormattedDateTimeString(start),
        end: getISOFormattedDateTimeString(end),
        selectedPeriod,
      });
    } else if (queryParams?.startDate && queryParams.endDate) {
      setDates({
        start: getISOFormattedDateTimeString(queryParams.startDate),
        end: getISOFormattedDateTimeString(queryParams.endDate),
        selectedPeriod: "0",
      });
    } else {
      setDates(initialDates);
    }
  };

  useEffect(() => {
    if (regions?.length > 0) {
      if (selectedRegion?.length === regions.length) {
        setRegionName([]);
      } else {
        setRegionName(selectedRegion?.map((item) => item.id) || []);
      }
    }

    if (!auth.active) {
      setRegionName([]);
    }
  }, [selectedRegion, regions]);

  // Update url
  useEffect(() => {
    let type = null;
    const domain = window.location.origin;
    switch (selectedMonitor?.type) {
      case "DISEASE":
        type = "diseaseId";
        break;
      case "PRODUCT":
        type = "productId";
        break;
    }

    if (type) {
      let url = "";
      if (monitorType) {
        url = `/dashboard/${monitorType}/${monitorId}?startDate=${
          dates.start || initialDates.start
        }&endDate=${dates.end || initialDates.end}`;
      } else {
        url = `/?${type}=${selectedMonitor.id}&startDate=${
          dates.start || initialDates.start
        }&endDate=${dates.end || initialDates.end}`;
      }

      if (queryParams?.section) {
        url += `&section=${queryParams.section}`;
      }
      if (activeTab) {
        url += `&tab=${activeTab}`;
      }
      if (queryParams?.source) {
        url += `&source=${queryParams.source}`;
      }
      if (queryParams?.u) {
        url += `&u=${queryParams.u}`;
      }
      if (queryParams?.n) {
        url += `&n=${queryParams.n}`;
      }
      if (queryParams?.t) {
        url += `&t=${queryParams.t}`;
      }
      if (queryParams?.e) {
        url += `&e=${queryParams.e}`;
      }
      window.history.replaceState(null, "Medical.watch", url);
      setShareUrl(domain + url);
      dispatch(
        navActions.set({
          last: domain + url,
        })
      );

      const storedRegion = homeSettings?.regions?.[selectedMonitor.id];
      if (typeof storedRegion === "object") {
        setSelectedRegion(homeSettings.regions[selectedMonitor.id] || []);
      } else {
        setSelectedRegion([]);
      }
    }

    noFetching.current = false;
  }, [selectedMonitor, dates, activeTab]);

  const cancelRequests = () => {
    cancelProductRequests.abort();
    cancelDiseaseRequests.abort();
    cancelConferenceRequests.abort();
    cancelUserRequests.abort();
    cancelTagRequests.abort();
    cancelHighlightRequests.abort();
  };

  const handleDateChange = (e) => {
    const start = getISOFormattedDateTimeString(e.startDate);
    const end = getISOFormattedDateTimeString(e.endDate);
    const selectedPeriod = e.selectedPeriod;
    setDates({
      start,
      end,
      selectedPeriod,
    });
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          dates: {
            ...homeSettings.dates,
            [selectedMonitor.id]: {
              start,
              end,
              selectedPeriod,
            },
          },
        },
      })
    );

    // clear tag summary for next monitor
    dispatch(diseaseActions.clearTags({}));
    dispatch(productActions.clearTags({}));
  };

  const getSortedHighlights = () => {
    let hghts = highlights?.filter(
      (item) => item.objectId === selectedMonitor?.id
    );
    hghts = _.orderBy(hghts, ["startDate", "endDate"], ["desc", "desc"]);
    return hghts;
  };

  // keywords:
  const requestTags = (pageNum) => {
    if (selectedMonitor?.id) {
      dispatch(
        tagActions.request({
          diseaseId:
            selectedMonitor.type === "DISEASE" ? selectedMonitor.id : null,
          productId:
            selectedMonitor.type === "PRODUCT" ? selectedMonitor.id : null,
          startDate: dates.start,
          endDate: dates.end,
          ...(_.trim(searchTag) !== "" && { preferred: false }), // we need merged as well when search term is NOT empty
          threshold: 0,
          maxResult: MAX_RESULTS,
          ...(_.trim(searchTag) !== "" && { term: _.trim(searchTag) }),
          pageNum,
          nocache: "true", // NOTE - by pass cache, till we solve backend #489
        })
      );
    }
  };

  useEffect(() => {
    if (!noFetching.current) {
      setPageTags(0);
      requestTags(0);
    }
  }, [debounceTag, selectedMonitor]);

  useEffect(() => {
    let newTags = initialTags.data.map((tag) => {
      return {
        ...tag,
        name: tag.preferredTagName || tag.name,
        id: tag.preferredTagId || tag.id,
        suggestion: !!tag.preferredTagId,
      };
    });
    // TODO - do we need to filter out whitelisted tags
    // merged in conference tags ??
    newTags = newTags.filter((t) => !t.conferenceId);

    setTags(newTags);
  }, [initialTags.data]);

  const getAllTags = () => {
    let tempAll = [];
    if (tags.length > 0) {
      tags.forEach((a) => {
        tempAll.push({
          id: a.id,
          label: a.name,
          suggestion: a.suggestion,
        });
      });
    }
    return tempAll;
  };

  const addSelectedTag = (item) => {
    if (selectedTags.length === 5) {
      toggleToast(true);
      return;
    }
    if (item && selectedMonitor.id) {
      let newTags = [
        ...selectedTags,
        {
          id: item.id,
          label: item.label,
        },
      ];

      setSelectedTags(newTags);
      dispatch(
        settingsActions.set({
          home: {
            ...homeSettings,
            tags: {
              ...homeSettings.tags,
              [selectedMonitor.id]: newTags,
            },
          },
        })
      );

      // check for cookies first before publishing event
      if (!auth?.active && cookies?.seen && !cookies?.accepted) {
        return;
      }
      // call common helper function
      logAnalyticEvent(
        "Search Keyword",
        {
          keyword: item.label,
          id: selectedMonitor.id, // which monitor search initiated
          type: selectedMonitor.type, // which monitor type search initiated
        },
        auth?.id || queryParams?.u || null,
        auth?.displayName || queryParams?.n || null,
        auth?.email || queryParams?.e || null,
        auth?.createdAt || null,
        auth?.preferences?.filter((p) => p.preferenceName === "UserType")?.[0]
          ?.preferenceStringValue ||
          queryParams?.t ||
          "MEDICAL",
        userPlans?.data?.[0]?.plan?.name || null
      );
    }
  };

  const removeSelectedTag = (item) => {
    const newTags = selectedTags.filter((i) => i.id !== item.id);

    setSelectedTags(newTags);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          tags: { ...homeSettings.tags, [selectedMonitor.id]: newTags },
        },
      })
    );
  };

  const handleSearchTag = (e) => {
    setSearchTag(e);
  };

  const handleRegionChange = (items) => {
    setSelectedRegion(items);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          regions: {
            ...homeSettings.regions,
            [selectedMonitor.id]: items,
          },
        },
      })
    );
  };

  const clearTag = () => {
    setSearchTag("");
    addSelectedTag(null);
  };

  const handleMoreTags = () => {
    if (!initialTags.fetching) {
      const tagsNewPage = pageTags + 1;
      setPageTags(tagsNewPage);
      requestTags(tagsNewPage);
    }
  };

  const clearFilters = () => {
    setSelectedTags([]);
    setSelectedRegion([]);
    setRegionName([]);
    dispatch(
      settingsActions.set({
        home: {
          ...homeSettings,
          tags: {
            ...homeSettings.tags,
            [selectedMonitor.id]: [],
          },
          regions: {
            ...homeSettings.regions,
            [selectedMonitor.id]: [],
          },
        },
      })
    );
  };

  const handleInfluencersChartClick = (e) => {
    if (e.id) {
      dispatch(
        scrollActions.set({
          home: 0,
        })
      );
      history.push(`/account/${e.id}`);
    }
  };

  const handleSortByChange = (item) => {
    dispatch(
      settingsActions.set({
        home: { ...homeSettings, sortTweetsBy: item.id },
      })
    );
  };

  const closeFilterTip = () => {
    setFilterTip(true);
    dispatch(
      settingsActions.set({
        home: { ...homeSettings, filterTip: true },
      })
    );
  };

  const handleScroll = () => {
    if (queryParams?.section) {
      clearSection();
    }
  };

  const clearSection = () => {
    delete queryParams.section;
    setQueryParams(queryParams);
    const url = window.location.href.split("&");
    const newUrl = url.filter((a) => !a.startsWith("section")).join("&");
    window.history.replaceState(null, "Medical.watch", newUrl);
  };

  const handleActiveTab = (id) => {
    setActiveTab(id);
    if (queryParams?.tab) {
      const url = window.location.href.split("&");
      const newUrl =
        url.filter((a) => !a.startsWith("tab")).join("&") + `&tab=${id}`;
      window.history.replaceState(null, "Medical.watch", newUrl);
    }
  };

  const handleSignUpClick = () => {
    var dashboardPage = selectedMonitor?.type;
    dashboardPage = dashboardPage
      ? "Dashboard - " + dashboardPage
      : "Dashboard";
    // check for cookies first before publishing event
    if (!auth?.active && cookies?.seen && !cookies?.accepted) {
      return;
    }
    // call common helper function
    logAnalyticEvent(
      "Sign Up",
      {
        page: dashboardPage,
        ...(queryParams?.source && { source: queryParams.source }),
      },
      queryParams?.u || null,
      queryParams?.n || null,
      queryParams?.e || null,
      null,
      queryParams?.t || null
    );
  };

  const handleWelcomeClose = () => {
    dispatch(
      userActions.updatePreferences({
        userId: auth.id,
        preferenceName: "Welcome",
        preferenceIntValue: 1,
        primary: true,
      })
    );
    toggleWelcomeModal(false);
  };

  useEffect(() => {
    // userPreferences already has data from a previous screen
    // If we are loading only "Welcome" userPreferences?.data.length should be undefined
    if (userPreferences?.data?.length > 0) {
      return;
    }
    if (
      userPreferences?.success &&
      !userPreferences?.data?.preferenceIntValue
    ) {
      const featureDate = new Date("2024-06-16");
      if (new Date(auth.createdAt) > featureDate) {
        toggleWelcomeModal(true);
      }
    }
  }, [userPreferences]);

  useEffect(() => {
    if (selectedTags?.length > 0) {
      dispatch(
        tagActions.whitelistRequest({ ids: selectedTags.map((t) => t.id) })
      );
    }
  }, [selectedTags]);

  useEffect(() => {
    let list1 = [];
    if (selectedTags?.length > 0) {
      list1 = whitelist?.map((t) => t.name) || [];
    }
    const list2 = selectedTags?.map((t) => t.label) || [];
    setMainKeywords(list2);
    setAllKeywords([...list1, ...list2]);
  }, [whitelist, selectedTags]);

  useEffect(() => {
    // this should be the last effect: move scroll to previous position
    if (scrollPosition > 0 && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollTo(0, scrollPosition);
        noFetching.current = false;
      }, 200);
    }
  }, [scrollPosition, contentRef?.current]);

  return view({
    homeSettings,
    userPlan,
    filterVisibility,
    toggleFilterVisibility,
    showExport:
      auth?.active &&
      userPlan !== "FREE" &&
      userPlan !== "BASIC" &&
      userPlan !== "HCP" &&
      userPlan !== "OTHER",
    showFilter: auth?.active && userPlan !== "FREE" && userPlan !== "BASIC",
    auth: auth?.id
      ? auth
      : {
          id: queryParams?.u || undefined,
          displayName: queryParams?.n || undefined,
          email: queryParams?.e || undefined,
        },
    isRegistered: auth.active,
    userData: auth,
    noFetching,
    contentRef,
    downloadRef,
    handleScroll,
    monitors,
    selectedMonitor,
    setSelectedMonitor,
    dates,
    setDates,
    queryParams,
    handleDateChange,
    setShareUrl,
    urlTab: queryParams?.tab,
    activeTab,
    handleActiveTab,

    highlights: getSortedHighlights(),
    highlightsLoading,

    tags: getAllTags(),
    searchTag,
    selectedTags,
    handleSearchTag,
    addSelectedTag,
    removeSelectedTag,
    clearTag,
    tagsInfinteScroll: {
      loading: initialTags.fetching,
      hasNextPage: !initialTags.isLastPage,
      onLoadMore: handleMoreTags,
    },

    regions,
    selectedRegion,
    setSelectedRegion,
    handleRegionChange,
    regionName,

    clearFilters,
    handleInfluencersChartClick,

    allKeywords,
    mainKeywords,
    sortBy,
    handleSortByChange,
    filterTip,
    closeFilterTip,
    clearSection,
    showCalendar,
    setShowCalendar,

    handleSignUpClick,
    showAI,
    whitelist,
    showDashboard:
      auth?.active ||
      queryParams.diseaseId ||
      queryParams.productId ||
      queryParams.conferenceId ||
      monitorType === "disease" ||
      monitorType === "product",
    abVersion,

    showToast,
    toggleToast,
    showWelcomeModal,
    toggleWelcomeModal,
    welcomeStep,
    setWelcomeStep,
    handleWelcomeClose,
    showExplosion,
    setShowExplosion,
  });
};
