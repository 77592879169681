import React, { useState } from "react";
import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  H2,
  H3,
  Label,
  HR,
  Dropdown,
  Input,
  Button,
  Anchor,
  Modal,
  ModalFooter,
  Switch,
  P,
  Icon,
  Collapsible,
  AutoComplete,
  Toast,
  Grid,
} from "../../../components";
import { Menu } from "../Menu";
import { Watchlist } from "../../Watchlist/WatchList";

export const Group = ({ children, label }) => (
  <Column marginTop="30">
    <Row marginBottom="10">
      <Label bold>{label}</Label>
    </Row>
    <Row marginTop="5">{children}</Row>
  </Column>
);

const getUserType = (user) => {
  if (user.preferences) {
    const preference = user.preferences?.filter(
      (p) => p.preferenceName === "UserType"
    );
    if (preference.length > 0) {
      return preference[0].preferenceStringValue;
    } else {
      return "MEDICAL";
    }
  } else return "MEDICAL";
};

const getEmailSuggestions = (user) => {
  if (user.preferences) {
    const preference = user.preferences?.filter(
      (p) => p.preferenceName === "EmailSuggestions"
    );
    if (preference.length > 0) {
      return preference[0].preferenceIntValue === 1 ? true : false;
    } else {
      return true;
    }
  } else return true;
};

export const view = ({
  id,
  auth,
  errors,
  handleSave,
  handleChange,
  data,
  setData,
  isSavePressed,
  isNew,

  deleteModal,
  toggleDeleteModal,
  handleDeleteUser,

  resetPasswordModal,
  toggleResetPasswordModal,
  handleResetPassword,
  isResetSuccess,

  handleSendNotification,
  notificationSent,

  handleUpdate,
  userRoles,
  handleUpdateRole,
  userTypes,
  handleUpdateUserType,
  handleUserStatusChange,
  handleUserAiChatChange,
  aiValue,
  handleEmailSuggestionsChange,

  showPasswordModal,
  togglePasswordModal,
  newPassword,
  setNewPassword,
  repeatPassword,
  setRepeatPassword,
  handleSubmitPassword,
  passwordErrors,
  handleBlur,
  hasFocus,
  setHasFocus,
  isChangeSuccess,
  freePlan,

  handleProductChange,
  products,
}) => {
  const [monitors, setMonitors] = useState([]);

  return (
    <MainWrapper drawer>
      <Header
        drawer
        close={isNew}
        back={!isNew}
        bkg="white"
        rightContent={
          <Row>
            {isNew && <Button label="Create" onClick={() => handleSave()} />}
            {!isNew && (
              <Row marginX>
                <Anchor
                  label="Delete"
                  color="red"
                  onClick={() => toggleDeleteModal(true)}
                />
              </Row>
            )}
          </Row>
        }
      ></Header>
      <Menu active="users" />

      <Content>
        <Column center>
          <Column maxWidth="700" paddingBottom="40">
            <Row expand paddingY="20">
              <H2 bold>{isNew ? "Add a new User" : "Edit User"}</H2>
            </Row>
            <Column>
              <Group label="First Name *">
                <Input
                  small
                  placeholder="First Name"
                  value={data.firstName}
                  errorMessage={isSavePressed && errors.firstName}
                  onChange={(e) =>
                    handleChange({ value: e.target?.value, field: "firstName" })
                  }
                  onBlur={handleUpdate}
                />
              </Group>
              <Group label="Last Name *">
                <Input
                  small
                  placeholder="Last Name"
                  value={data.lastName}
                  errorMessage={isSavePressed && errors.lastName}
                  onChange={(e) =>
                    handleChange({ value: e.target?.value, field: "lastName" })
                  }
                  onBlur={handleUpdate}
                />
              </Group>
              <Group label="Email *">
                <Input
                  small
                  placeholder="Email"
                  value={data.email}
                  errorMessage={isSavePressed && errors.email}
                  onChange={(e) =>
                    handleChange({ value: e.target?.value, field: "email" })
                  }
                  onBlur={handleUpdate}
                />
              </Group>
              <Group label="Role">
                <Dropdown
                  expand
                  value={data.mwRole}
                  onChange={(e) =>
                    handleUpdateRole({ value: e?.id, field: "mwRole" })
                  }
                  items={userRoles}
                />
              </Group>
              <Group label="Type">
                <Dropdown
                  expand
                  value={getUserType(data)}
                  onChange={(e) => handleUpdateUserType(e?.id)}
                  items={userTypes}
                />
              </Group>
              <Column marginTop="30">
                <Collapsible label="Location" noBorder>
                  <Column paddingBottom="30">
                    <Group label="City">
                      <Input
                        small
                        placeholder="City"
                        value={data.city}
                        onChange={(e) =>
                          handleChange({
                            value: e.target?.value,
                            field: "city",
                          })
                        }
                        maxCharacters={64}
                        onBlur={handleUpdate}
                      />
                    </Group>
                    <Group label="State/province">
                      <Input
                        small
                        placeholder="State/province"
                        value={data.stateProvince}
                        onChange={(e) =>
                          handleChange({
                            value: e.target?.value,
                            field: "stateProvince",
                          })
                        }
                        maxCharacters={64}
                        onBlur={handleUpdate}
                      />
                    </Group>
                    <Group label="Country code">
                      <Input
                        small
                        placeholder="Country code"
                        value={data.country}
                        onChange={(e) =>
                          handleChange({
                            value: e.target?.value,
                            field: "country",
                          })
                        }
                        maxCharacters={2}
                        onBlur={handleUpdate}
                      />
                    </Group>
                    <Group label="Postal code">
                      <Input
                        small
                        placeholder="Postal code"
                        value={data.postalCode}
                        onChange={(e) =>
                          handleChange({
                            value: e.target?.value,
                            field: "postalCode",
                          })
                        }
                        maxCharacters={12}
                        onBlur={handleUpdate}
                      />
                    </Group>
                  </Column>
                </Collapsible>
              </Column>
              {!isNew && (
                <Column>
                  <Row gap="120">
                    <Group label="Active">
                      <Switch
                        checked={data.active}
                        onChange={handleUserStatusChange}
                      />
                    </Group>
                    <Group label="Email suggestions">
                      <Switch
                        checked={getEmailSuggestions(data)}
                        onChange={handleEmailSuggestionsChange}
                      />
                    </Group>
                  </Row>
                  <Group label="AI Chat">
                    <Dropdown
                      expand
                      value={aiValue}
                      onChange={(e) => handleUserAiChatChange(e?.id)}
                      items={[
                        { id: "default", label: "Default" },
                        { id: 1, label: "Enabled" },
                        { id: 0, label: "Disabled" },
                      ]}
                    />
                  </Group>
                  {monitors.length > 0 && (
                    <Column marginTop="30">
                      <HR />
                      <Row marginTop="10" marginBottom="10">
                        <Label bold>Preferred products for DOL Mapping</Label>
                      </Row>
                      {monitors.map((k) => (
                        <Row
                          marginTop="5"
                          key={k.id}
                          marginBottom="10"
                          gap="10"
                          middle
                        >
                          <Grid gap="10" columns={2} middle>
                            <Row>
                              <Label semibold>{k.label}</Label>
                            </Row>
                            <Row right>
                              <Dropdown
                                expand
                                select
                                filter
                                placeholder="Preferred Product"
                                value={k.object2Id}
                                items={products}
                                onChange={(e) =>
                                  handleProductChange({
                                    id: k.monitorComponent.id,
                                    object2Id: e.id,
                                    object2Type: "PRODUCT",
                                  })
                                }
                                onClear={() =>
                                  handleProductChange({
                                    id: k.monitorComponent.id,
                                    object2Id: null,
                                    object2Type: null,
                                    remove: true,
                                  })
                                }
                              />
                            </Row>
                          </Grid>
                        </Row>
                      ))}
                      <HR />
                    </Column>
                  )}
                  <Group label="Password">
                    <Row gap="30">
                      {auth.id === data.id && (
                        <Anchor
                          bold
                          label="Change password"
                          onClick={() => togglePasswordModal(true)}
                        />
                      )}

                      <Anchor
                        bold
                        label="Reset password"
                        onClick={() => toggleResetPasswordModal(true)}
                      />
                    </Row>
                  </Group>
                  <Row marginBottom="40" />
                </Column>
              )}
            </Column>

            {!isNew && (
              <Column left stretch>
                <HR />
                <Row marginTop="30" marginBottom expand middle>
                  <H3 bold>Notifications</H3>
                  <Row fit />
                  <Row right>
                    <Anchor
                      line
                      label="Send Last Notifications"
                      title="Only Daily and Weekly notifications will be sent"
                      onClick={() => handleSendNotification()}
                      disabled={notificationSent}
                    />
                  </Row>
                </Row>
                <Row expand marginBottom>
                  <P color="gray" fade="6" small>
                    NOTE:
                    <br />
                    1. Time period (<i>for notifications to be sent</i>) will be
                    calculated automatically.
                    <br />
                    2. For frequency as 'Daily', the time period will be
                    previous day (00:00 to 23:59).
                    <br />
                    3. For frequency as 'Weekly', the time period will be week
                    ending previous day (ending at 23:59).
                    <br />
                    4. No notifications will be sent for monitors with frequency
                    'Instant'.
                    <br />
                    5. For a selected monitor, if notifications are not to be
                    sent, those monitors can be deactivated.
                  </P>
                </Row>

                <HR />
                {data.id && (
                  <Column marginTop="30" gap="30" stretch>
                    <Watchlist
                      user={data}
                      setParentMonitors={setMonitors}
                      adminPage
                      handleUpdateUser={setData}
                    />
                  </Column>
                )}
              </Column>
            )}
          </Column>

          {/* delete user modal */}
          <Modal
            visible={deleteModal}
            toggleVisibility={toggleDeleteModal}
            title="Delete User"
            close
          >
            <Column marginBottom gap="20" center>
              <Label>
                <Label large bold>
                  {data.firstName} {data.lastName}
                </Label>
                <Label large> will be deleted</Label>
              </Label>
              <Label large>
                Are you sure you want to continue? This action cannot be undone.
              </Label>
            </Column>
            <ModalFooter right>
              <Button
                secondary
                label="Delete"
                onClick={() => handleDeleteUser()}
              />
            </ModalFooter>
          </Modal>

          {/* reset password  modal */}
          <Modal
            visible={resetPasswordModal}
            toggleVisibility={toggleResetPasswordModal}
            title="Reset password"
            close
          >
            {!isResetSuccess ? (
              <Column marginBottom gap="20" center>
                <Label>
                  <Label large>You will reset the password for </Label>
                  <Label large bold>
                    {data.firstName} {data.lastName}
                  </Label>
                </Label>
                <Label large>
                  Are you sure you want to continue? This action cannot be
                  undone.
                </Label>
              </Column>
            ) : (
              <Row gap="10" center middle marginBottom>
                <Icon name="check" size="40" color="green" />
                <Label bold>Password changed successfully!</Label>
              </Row>
            )}
            {!isResetSuccess && (
              <ModalFooter right>
                <Button
                  secondary
                  label="Reset"
                  onClick={() => handleResetPassword()}
                />
              </ModalFooter>
            )}
          </Modal>

          <Modal
            visible={showPasswordModal}
            toggleVisibility={togglePasswordModal}
            close
            onClose={() => togglePasswordModal(false)}
            title="Change password"
            maxWidth={600}
          >
            {!isChangeSuccess ? (
              <form onSubmit={handleSubmitPassword}>
                <Column gap="30" stretch>
                  <Column gap="10">
                    <Label bold>New password</Label>
                    <Input
                      small
                      autoFocus
                      expand
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      onBlur={handleBlur}
                      onFocus={() => setHasFocus(true)}
                      errorMessage={
                        hasFocus
                          ? ""
                          : passwordErrors.uppercase
                          ? "Password must contain at least one uppercase letter"
                          : passwordErrors.lowercase
                          ? "Password must contain at least one lowercase letter"
                          : passwordErrors.number
                          ? "Password must contain at least one number"
                          : passwordErrors.length
                          ? "Password must be at least 8 characters long"
                          : ""
                      }
                    />
                  </Column>
                  <Column gap="10">
                    <Label bold>Repeat password</Label>
                    <Input
                      small
                      expand
                      type="password"
                      value={repeatPassword}
                      onChange={(e) => {
                        setRepeatPassword(e.target.value);
                      }}
                      errorMessage={
                        repeatPassword === "" || repeatPassword === newPassword
                          ? ""
                          : "Passwords do not match"
                      }
                    />
                  </Column>
                  <Row>
                    <Button type="primary" label="Submit" />
                  </Row>
                </Column>
              </form>
            ) : (
              <Row gap="10" center middle marginBottom>
                <Icon name="check" size="40" color="green" />
                <Label bold>Password changed successfully!</Label>
              </Row>
            )}
          </Modal>
        </Column>
      </Content>
    </MainWrapper>
  );
};
