import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, debounce } from "lodash";

import useKeyPress from "../../../utils/useKeyPress";
import useDebounce from "../../../utils/useDebounce";
import { useChangePassword } from "../../../store/queries/user";
import {
  userActions,
  commonActions,
  notificationActions,
  monitorActions,
  authActions,
} from "../../../store/actions";
import { isValidEmail } from "../../../utils";
import { getEnumUserType } from "../../../store/sagas/commonSagas";
import { view } from "./EditUserView";
import { updateMonitorComponent } from "../../../store/sagas/monitorSagas";
import { getProducts } from "../../../store/sagas/productSagas";

const initialData = {
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
  city: "",
  country: "",
  stateProvince: "",
  postalCode: "",
  mwRole: "USER",
  active: true,
  preferences: [
    {
      preferenceName: "UserType",
      preferenceStringValue: "MEDICAL",
      primary: true,
    },
  ],
};

const userRoles = [
  {
    id: "USER",
    label: "User",
  },
  {
    id: "ADMIN",
    label: "Admin",
  },
  {
    id: "HIGHLIGHTSADMIN",
    label: "Highlights Admin",
  },
];

const errorsMessage = {
  firstName: "Required field",
  lastName: "Required field",
  email: "Invalid email",
};

const initialPasswordErrors = {
  length: true,
  uppercase: true,
  lowercase: true,
  number: true,
  // special: true,
};

export const EditUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const enterPress = useKeyPress("Enter");

  // --> mapStateToProp
  const auth = useSelector((state) => state.auth.data);
  const user = useSelector((state) => state.user.one.data);
  const userPlans = useSelector((state) => state.plan.userPlans);

  // <-- mapStateToProp

  // --> STATE
  const [products, setProducts] = useState([]);
  const [userTypes, setUserTypes] = useState([
    { id: "HCP", label: "Physician" },
    { id: "MARKETING", label: "Marketing" },
    { id: "MEDICAL", label: "Medical Affairs" },
    { id: "SALES", label: "Sales" },
    { id: "OTHER", label: "Other" },
  ]);
  const [isSavePressed, setIsSavePressed] = useState(false);

  const [errors, setErrors] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [resetPasswordModal, toggleResetPasswordModal] = useState(false);
  const [data, setData] = useState(initialData);
  const [notificationSent, setNotificationSent] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const [showPasswordModal, togglePasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState(initialPasswordErrors);
  const [hasFocus, setHasFocus] = useState(false);
  const [lonLat, setLonLat] = useState(false);
  const [aiValue, setAiValue] = useState("default");
  // const [isPasswordFormSuccess, setIsPasswordFormSuccess] = useState(false);
  // <-- STATE

  const {
    mutateAsync: changePassword,
    isSuccess,
    error: backendError,
  } = useChangePassword();

  // const {
  //   mutateAsync: changePassword,
  //   isSuccess,
  //   error: backendError,
  // } = useChangePassword();

  const handleResetPassword = async () => {
    await changePassword({ id: user.id, password: "Test1234" });
  };

  // TODO: return backend error, i.e: message: "User with email 'test@gmail.com' already exists"
  useEffect(() => {
    // checking `isSavePressed` to prevent reseting fields after creating a new user
    if (!isSavePressed) {
      if (id && user) {
        setData({
          id: user.id,
          displayName: user.displayName,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          mwRole: user.mwRole,
          active: user.active,
          preferences: user.preferences,
          city: user.city,
          country: user.country,
          stateProvince: user.stateProvince,
          postalCode: user.postalCode,
          ...(lonLat && { lon: null, lat: null }),
        });

        const initialAiValue = user.preferences?.find(
          (item) => item.preferenceName === "AIChat"
        )?.preferenceIntValue;
        setAiValue(
          initialAiValue ? initialAiValue : initialAiValue === 0 ? 0 : "default"
        );
      } else {
        setData(initialData);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      setIsFormSuccess(true),
        setTimeout(() => {
          toggleResetPasswordModal(false);
          togglePasswordModal(false);
          setIsFormSuccess(false);
          setNewPassword("");
          setRepeatPassword("");
        }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(commonActions.requestUserRoles());

    (async () => {
      // get enum values for 'UserType'
      const _userTypeOptions = await getEnumUserType();
      const temp =
        _userTypeOptions?.data.map((o) => {
          return {
            id: o.enumStringValue,
            label: o.enumValueDisplayName,
          };
        }) || [];
      setUserTypes([...temp]);
    })();

    if (id) {
      dispatch(userActions.oneRequest(id));
    }

    requestProducts();

    return () => {
      dispatch(userActions.clear());
      dispatch(monitorActions.monitorsRequest(auth.id));

      if (id === auth.id) {
        // If we are updating our own user, we need to update the auth data
        dispatch(
          authActions.userRequest({
            data: { userId: auth.id, lastLocation: "current" },
          })
        );
      }
    };
  }, [dispatch]);

  const checkErrors = () => {
    let newErrors = {};
    for (const item in errorsMessage) {
      if (item === "email") {
        if (isValidEmail(data.email)) {
          delete newErrors.email;
        } else {
          newErrors.email = errorsMessage.email;
        }
      } else {
        if (data[item] === "") {
          newErrors[item] = errorsMessage[item];
        } else {
          delete newErrors[item];
        }
      }
    }
    setErrors(newErrors);
  };

  const handleChange = ({ value, field }) => {
    setData((oldData) => ({
      ...oldData,
      [field]: value,
    }));

    if (field === "postalCode") {
      setLonLat(true);
    }
  };

  const handleDeleteUser = () => {
    dispatch(userActions.delete(data.id));
  };

  const debounceSave = debounce(() => {
    if (!id) {
      const newDisplayName = data.firstName + " " + data.lastName;
      const newData = {
        ...data,
        displayName: newDisplayName.trim(),
        password: "Test1234",
      };
      dispatch(userActions.create(newData));
    }
  }, 1000);

  const handleSave = () => {
    setIsSavePressed(true);
    if (isEmpty(errors)) {
      debounceSave();
    }
  };

  const handleSendNotification = () => {
    setNotificationSent(true);
    dispatch(
      notificationActions.send({
        userId: id,
        objectTypes: ["DISEASE", "PRODUCT", "CONFERENCE"],
        intervals: ["DAILY", "WEEKLY"],
      })
    );
    setTimeout(() => {
      setNotificationSent(false);
    }, 4000);
  };

  useEffect(() => {
    checkErrors();
  }, [data]);

  useEffect(() => {
    if (enterPress) handleSave();
  }, [enterPress]);

  const handleUpdate = () => {
    if (id && isEmpty(errors)) {
      dispatch(userActions.update(data));
    }
  };

  const handleUpdateRole = ({ value, field }) => {
    const newData = {
      ...data,
      [field]: value,
    };
    setData(newData);
    if (id && isEmpty(errors)) {
      dispatch(userActions.update(newData));
    }
  };

  const handleUpdateUserType = (value) => {
    const newData = { ...data };
    const index = data.preferences?.findIndex(
      (p) => p.preferenceName === "UserType"
    );
    if (index > -1) {
      newData.preferences.splice(index, 1);
    }
    newData.preferences.push({
      preferenceName: "UserType",
      preferenceStringValue: value,
      primary: true,
    });
    setData(newData);
    if (id && isEmpty(errors)) {
      dispatch(userActions.update(newData));
    }
  };

  const handleEmailSuggestionsChange = (value) => {
    const newData = { ...data };
    const index = data.preferences?.findIndex(
      (p) => p.preferenceName === "EmailSuggestions"
    );
    if (index > -1) {
      newData.preferences.splice(index, 1);
    }
    newData.preferences.push({
      preferenceName: "EmailSuggestions",
      preferenceIntValue: value ? 1 : 0,
      primary: true,
    });

    setData(newData);
    if (id && isEmpty(errors)) {
      dispatch(userActions.update(newData));
    }
  };

  const handleUserStatusChange = (value) => {
    const newData = {
      ...data,
      active: value,
    };
    setData(newData);

    if (id && isEmpty(errors)) {
      // activate / deactivate user
      dispatch(
        userActions.updateStatus({
          id,
          active: value,
        })
      );
    }
  };

  const handleUserAiChatChange = (value) => {
    const newData = { ...data };
    const index = data.preferences?.findIndex(
      (p) => p.preferenceName === "AIChat"
    );
    if (index > -1) {
      newData.preferences.splice(index, 1);
    }

    if (value === "default") {
      dispatch(
        userActions.deletePreferences({
          userId: id,
          name: "AIChat",
        })
      );
    } else {
      newData.preferences.push({
        preferenceName: "AIChat",
        preferenceIntValue: value,
        primary: true,
      });
      dispatch(
        userActions.updatePreferences({
          userId: id,
          preferenceName: "AIChat",
          preferenceIntValue: value,
          primary: true,
        })
      );
    }
  };

  const handleProductChange = async (value) => {
    if (value?.object2Id || value.remove) {
      await updateMonitorComponent({
        id: value.id,
        object2Id: _.isEmpty(_.trim(value.object2Id))
          ? null
          : _.trim(value.object2Id),
        object2Type: _.isEmpty(_.trim(value.object2Id))
          ? null
          : _.trim(value.object2Type),
      });
    }
  };

  const requestProducts = async () => {
    const temp = await getProducts([], false);
    const newItems = temp.data.map((item) => ({
      id: item.id,
      label: item.primaryName,
    }));
    setProducts(newItems);
  };

  const handlePasswordErrors = () => {
    const uppercase = !/[A-Z]/.test(newPassword);
    const lowercase = !/[a-z]/.test(newPassword);
    const number = !/[0-9]/.test(newPassword);
    // const special = !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    const length = newPassword.length < 8;

    setPasswordErrors({
      length,
      uppercase,
      lowercase,
      number,
      // special,
    });
  };

  const handleChangePassword = async () => {
    await changePassword({ id: auth.id, password: newPassword });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (
      passwordErrors.length ||
      passwordErrors.uppercase ||
      passwordErrors.lowercase ||
      passwordErrors.number
      //|| errors.special
    ) {
      return;
    }
    if (newPassword !== repeatPassword) {
      return;
    }
    handleChangePassword();
  };

  const handleBlur = () => {
    setHasFocus(false);
    handlePasswordErrors();
  };

  return view({
    id,
    auth,
    errors,
    handleSave,
    user,
    data,
    setData,
    handleChange,
    isSavePressed,
    isNew: !id,

    deleteModal,
    toggleDeleteModal,
    handleDeleteUser,

    resetPasswordModal,
    toggleResetPasswordModal,
    handleResetPassword,
    isResetSuccess: isFormSuccess,

    showPasswordModal,
    togglePasswordModal,
    newPassword,
    setNewPassword,
    repeatPassword,
    setRepeatPassword,
    handleSubmitPassword,
    passwordErrors,
    handleBlur,
    hasFocus,
    setHasFocus,
    isChangeSuccess: isFormSuccess,

    notificationSent,
    handleSendNotification,

    handleUpdate,
    userRoles,
    handleUpdateRole,
    userTypes,
    handleUpdateUserType,
    handleUserStatusChange,
    handleUserAiChatChange,

    aiValue,
    handleEmailSuggestionsChange,
    freePlan: userPlans?.data?.[0]?.plan?.free,

    handleProductChange,
    products,
  });
};
