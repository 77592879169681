import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Column,
  H4,
  MainWrapper,
  Header,
  Content,
  Label,
  ResponsiveButton,
  Filters,
  Input,
  List,
  ListItem,
  Dropdown,
  AutoComplete,
  Anchor,
  DateRange,
  Grid,
  Spinner,
  Icon,
} from "../../../components";

import { Menu } from "../Menu";

export const view = ({
  products,
  activeFilters,
  statusTypes,
  productsCount,
  selectedStatus,
  handleStatusChange,
  // institutions,
  // searchInstitution,
  // selectedInstitution,
  // handleSearchInstitution,
  // addSelectedInstitution,
  // clearInstitution,
  infiniteScrollRef,
  hasNextPage,
  loading,
  searchTerm,
  setSearchTerm,

  exportCSV,
  dates,
  handleDateChange,
}) => {

  return (
    <MainWrapper drawer>
      <Header
        drawer
        rightContent={
          <>
            <Row fit />
            <ResponsiveButton label="Add Product" to="/newProduct" />
          </>
        }
      />
      <Menu active="products" />

      <Content>
        <Column>
          <Filters
            counter={`${productsCount} Products `}
            {...{ activeFilters }}
            datePicker={
              <Row gap="10" middle>
                <Label>From </Label>
                <DateRange
                  noBackground
                  period
                  initialDate={dates.start}
                  endDate={dates.end}
                  onChange={handleDateChange}
                  noFutureDates
                  allTime
                />
              </Row>
            }
          >
            <Grid>
              <Column gap="10">
                <Label bold>Name</Label>
                <Input
                  small
                  placeholder="Search"
                  icon="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Column>
              {/* <Column gap="10">
                <Label bold>Owner</Label>
                <AutoComplete
                  showOnEmptyTerm
                  displayTextFromSelected
                  searchResults={institutions}
                  setSearchTerm={handleSearchInstitution}
                  searchTerm={searchInstitution}
                  selectedItems={selectedInstitution}
                  addItem={addSelectedInstitution}
                  clearSearchResults={clearInstitution}
                  hasClearButton
                />
              </Column> */}
              <Column gap="10">
                <Label bold>Status</Label>
                <Dropdown
                  expand
                  right
                  select
                  value={selectedStatus}
                  items={statusTypes}
                  onChange={(e) => handleStatusChange(e.id)}
                />
              </Column>
            </Grid>
          </Filters>

          <Column marginTop="10">
            <Row paddingBottom gap="70" right>
            <Anchor
                bold
                label="Export"
                icon="download"
                onClick={() =>
                  exportCSV()
                }
              />
            </Row>
          </Column>

          <Column marginTop="10">
            <List>
              <ListItem>
                <Row fit>
                  <Label color="gray" fade="3" bold>
                    Name
                  </Label>
                </Row>
                {/* <Row paddingLeft center style={{ width: "70px" }}>
                    <Label color="gray" fade="3" bold>
                      Owner
                    </Label>
                  </Row> */}
                <Row paddingLeft center style={{ width: "200px" }}>
                  <Label color="gray" fade="3" bold>
                    Created At
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "200px" }}>
                  <Label color="gray" fade="3" bold>
                    Tag Created At
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "70px" }}>
                  <Label color="gray" fade="3" bold>
                    Match
                  </Label>
                </Row>
                <Row paddingLeft center style={{ width: "70px" }}>
                  <Label color="gray" fade="3" bold>
                    Posts
                  </Label>
                </Row>
              </ListItem>
              {products.map((item) => (
                <Link key={item.id} to={`/editProduct/${item.id}`}>
                  <ListItem key={item.id}>
                    <Row fit style={{ width: "300px" }}>
                      <Column paddingY="5">
                        <Row>
                          <Label
                            color={item.tag?.alwaysIgnore ? "gray" : "black"}
                          >
                            {item.primaryName}
                          </Label>
                        </Row>
                        <Row>
                          <Label small color="gray" fade="6">({item.tag.meaning})</Label>
                        </Row>
                        {!_.isEmpty(item.substitutedTagNames) && (
                          <Row>
                            <Label small color="green" fade="6">
                              {item.substitutedTagNames}
                            </Label>
                          </Row>
                        )}
                      </Column>
                    </Row>
                    {/* {item.ownerName && (
                        <Row paddingLeft center style={{ width: "200px" }}>
                          <Label
                            color={item.tag?.alwaysIgnore ? "gray" : "black"}
                          >
                            {item.ownerName}
                          </Label>
                        </Row>
                      )} */}
                    <Row paddingLeft center style={{ width: "200px" }}>
                      <Label color="gray" fade="3">
                        {new Date(item.createdAt).toLocaleString(
                          "en-US",
                          { timeZone: "UTC" } // remove this if we want in local timezone
                        )}
                      </Label>
                    </Row>
                    <Row paddingLeft center style={{ width: "200px" }}>
                      <Label color="gray" fade="3">
                        {new Date(item.tag.createdAt).toLocaleString(
                          "en-US",
                          { timeZone: "UTC" } // remove this if we want in local timezone
                        )}
                      </Label>
                    </Row>
                    <Row paddingLeft center style={{ width: "70px" }}>
                      {item.tag.alwaysMatch ? (
                        <Icon size="30" name="check" color="green" />
                      ) : (
                        <Icon size="20" name="close" color="red" />
                      )}
                    </Row>
                    <Row paddingLeft center style={{ width: "70px" }}>
                      {item.tag.count && item.tag.count !== -1 ? (
                        <Label
                          color={item.tag?.alwaysIgnore ? "gray" : "black"}
                        >
                          {item.tag.count.toLocaleString()}
                        </Label>
                      ) : (
                        <Label
                          color={item.tag?.alwaysIgnore ? "gray" : "black"}
                        >
                          {" "}
                          -{" "}
                        </Label>
                      )}
                    </Row>
                  </ListItem>
                </Link>
              ))}
            </List>

            {loading || hasNextPage ? (
              <Row expand paddingAll="40" center ref={infiniteScrollRef}>
                <Spinner />
              </Row>
            ) : (
              <>
                {products?.length === 0 && (
                  <List center>
                    <ListItem>No Products found</ListItem>
                  </List>
                )}
              </>
            )}
          </Column>
        </Column>
      </Content>
    </MainWrapper>
  );
};
