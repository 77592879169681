import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "axios";

import { getApiPath, MAX_RESULTS } from "../../utils/variables";
import { DISEASE_ACTIONS, diseaseActions } from "../actions";
import { authHeader } from "../store";
import { objectToUrlEncoded } from "../../utils/constants";

export let cancelDiseaseRequests = new AbortController();

function* handleRequest(action) {
  const { term, ignore, pageNum, maxResult, details, startDate, endDate } =
    action.payload;
  const params = objectToUrlEncoded({
    term,
    ignore,
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    details,
    startDate,
    endDate,
  });
  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/diseases?${params}`,
      {
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.success({ data, pageNum }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(diseaseActions.failure({ error: { ...e } }));
  }
}

function* handleTagsRequest(action) {
  try {
    cancelDiseaseRequests = new AbortController();
    const { ignore, term, maxResult, pageNum } = action.payload;
    const params = objectToUrlEncoded({
      ignore,
      term,
      pageNum,
      maxResult: maxResult || -1,
    });
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/tags/diseases?${params}`,
      {
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.tagsSuccess({ data, pageNum }));
  } catch (e) {
    yield put(diseaseActions.tagsFailure({ error: { ...e } }));
  } finally {
  }
}

function* handleRequestOne(action) {
  try {
    cancelDiseaseRequests = new AbortController();
    const { id, nocache } = action.payload;
    const { data } = yield call(
      axios.get,
      `${getApiPath(false)}/disease/${id}?${Date.now()}${
        nocache ? "&nocache=true" : ""
      }`,
      {
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.oneSuccess({ data }));
  } catch (e) {
    if (e?.response?.status === 401) {
      yield put((window.location.href = "/logout"));
    }
    yield put(diseaseActions.oneFailure({ error: { ...e } }));
  }
}

function* handleCreate(action) {
  const body = action.payload.data;
  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(axios.post, `${getApiPath()}/disease`, body, {
      headers: authHeader(),
      signal: cancelDiseaseRequests.signal,
    });
    yield put(diseaseActions.oneSuccess({ data }));

    yield put((window.location.href = "/admin/diseases"));
  } catch (e) {
    yield put(diseaseActions.oneFailure({ error: { ...e } }));
  }
}

function* handleUpdate(action) {
  const body = action.payload.data;
  body.tagId = action.payload.data.tag.id;

  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(axios.put, `${getApiPath()}/disease`, body, {
      headers: authHeader(),
      signal: cancelDiseaseRequests.signal,
    });
    yield put(diseaseActions.oneSuccess({ data }));
  } catch (e) {
    yield put(diseaseActions.oneFailure({ error: { ...e } }));
  }
}

/*
function* handleDelete(action) {
  const { id } = action.payload;
  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(
      axios.delete,
      `${getApiPath()}/disease?id=${id}`,
      {
        headers: authHeader(),
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.oneSuccess({ data }));
    yield put((window.location.href = "/admin/diseases"));
  } catch (e) {
    yield put(diseaseActions.oneFailure({ error: { ...e } }));
  }
}
*/

function* handleTweetsRequest(action) {
  const {
    id,
    addTagIds,
    authorId,
    userId,
    mwUserId,
    startDate,
    endDate,
    withSentiment,
    sentiment,
    sortBy = "score", // 'score' or 'date'
    pageNum,
    maxResult,
    regionName,
    savedOnly,
  } = action.payload;
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId,
    userId,
    mwUserId,
    startDate,
    endDate,
    sortBy,
    withSentiment,
    sentiment,
    // source: 'X',
    pageNum,
    maxResult: maxResult || MAX_RESULTS,
    savedOnly,
  });

  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/disease/${id}/tweets?${tagIdList}${regionList}${params}`,
      {
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.tweetsSuccess({ data, pageNum }));
  } catch (e) {
    yield put(diseaseActions.tweetsFailure({ error: { ...e } }));
  }
}

function* handleTweetsCountRequest(action) {
  const {
    id,
    addTagIds,
    authorId,
    userId,
    mwUserId,
    startDate,
    endDate,
    withSentiment,
    sentiment,
    regionName,
    savedOnly,
  } = action.payload;
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId,
    userId,
    mwUserId,
    startDate,
    endDate,
    withSentiment,
    sentiment,
    // source: "X",
    savedOnly,
  });
  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/disease/${id}/tweets/count?${tagIdList}${regionList}${params}`,
      {
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.tweetsCountSuccess({ data }));
  } catch (e) {
    yield put(diseaseActions.tweetsCountFailure({ error: { ...e } }));
  }
}

function* handleDolRequest(action) {
  const {
    id,
    userId,
    startDate,
    endDate,
    accountType,
    accountSubType,
    regionName,
    addTagIds,
    originalOnly,
  } = action.payload;
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  const params = objectToUrlEncoded({
    userId,
    startDate,
    endDate,
    accountType: accountType || "PERSON",
    accountSubType: accountSubType,
    originalOnly,
  });

  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(
      axios.get,
      `${getApiPath(
        false
      )}/disease/${id}/dols?${tagIdList}${regionList}${params}`,
      {
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.dolSuccess({ data }));
  } catch (e) {
    yield put(diseaseActions.dolFailure({ error: { ...e } }));
  }
}

function* handleGptRequest(action) {
  const {
    id,
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    q,
    role,
    model,
    source,
    userType,
    savedOnly,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    timeout,
    addTagIds,
    regionName,
    nocache,
  } = action.payload.data;
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const body = objectToUrlEncoded({
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    q,
    role,
    model,
    source,
    userType,
    savedOnly,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    timeout,
  });

  try {
    cancelDiseaseRequests = new AbortController();
    const { data } = yield call(
      axios.post,
      `${getApiPath()}/disease/${id}/gptQuery${nocache ? "?nocache=true" : ""}`,
      `${tagIdList}${regionList}${body}`,
      {
        headers: authHeader({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        signal: cancelDiseaseRequests.signal,
      }
    );
    yield put(diseaseActions.gptSuccess({ data }));
  } catch (e) {
    yield put(diseaseActions.gptFailure({ error: { ...e } }));
  }
}

// function to fetch tweets associated with disease for export
export const getDiseaseTweets = ({
  id,
  addTagIds,
  authorId,
  userId,
  mwUserId,
  startDate,
  endDate,
  withSentiment,
  sentiment,
  sortBy = "score", // 'score' or 'date'
  pageNum,
  regionName,
  maxResult,
  savedOnly,
}) => {
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const params = objectToUrlEncoded({
    authorId,
    userId,
    mwUserId,
    startDate,
    endDate,
    sortBy,
    withSentiment,
    sentiment,
    pageNum,
    maxResult,
    savedOnly,
  });

  return axios.get(
    `${getApiPath(
      false
    )}/disease/${id}/tweets?${tagIdList}${regionList}${params}`
  );
};

export const getDiseasePowerPoint = ({
  id,
  addTagIds,
  source,
  userId,
  userType,
  startDate,
  endDate,
  regionName,
  sortBy,
  pageNum,
  maxResult,
  maskAllHCPNames,
  maskNonUSHCPNames,
  savedOnly,
}) => {
  let tagIdList = "";
  if (addTagIds?.length > 0) {
    addTagIds.forEach((tagId) => {
      tagIdList += `addTagId=${tagId}&`;
    });
  }
  let regionList = "";
  if (regionName?.length > 0) {
    regionName.forEach((region) => {
      regionList += `regionName=${region}&`;
    });
  }
  const params = objectToUrlEncoded({
    source,
    userId,
    userType,
    startDate,
    endDate,
    sortBy,
    pageNum,
    maxResult,
    maskAllHCPNames,
    maskNonUSHCPNames,
    savedOnly,
  });

  return axios.get(
    `${getApiPath()}/disease/${id}/downloadPpt?${tagIdList}${regionList}${params}`,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
};

export const getDiseases = ({
  ignore,
  term,
  pageNum,
  maxResult,
  details,
  startDate,
  endDate,
}) => {
  const params = objectToUrlEncoded({
    term,
    ignore,
    pageNum,
    maxResult: maxResult || -1,
    details,
    startDate,
    endDate,
  });

  return axios.get(`${getApiPath(false)}/diseases?${params}`);
};

export const getDiseaseMetrics = ({ id, startDate, endDate }) => {
  const params = objectToUrlEncoded({
    startDate,
    endDate,
  });

  return axios.get(`${getApiPath()}/disease/${id}/metrics?${params}`, {
    headers: authHeader(),
  });
};

function* watchDiseaseSagas() {
  yield all([
    takeLatest(DISEASE_ACTIONS.REQUEST, handleRequest),
    takeLatest(DISEASE_ACTIONS.TAGS_REQUEST, handleTagsRequest),
    takeLatest(DISEASE_ACTIONS.ONE_REQUEST, handleRequestOne),
    takeLatest(DISEASE_ACTIONS.CREATE, handleCreate),
    takeLatest(DISEASE_ACTIONS.UPDATE, handleUpdate),
    // takeLatest(DISEASE_ACTIONS.DELETE, handleDelete),
    takeLatest(DISEASE_ACTIONS.TWEETS_REQUEST, handleTweetsRequest),
    takeLatest(DISEASE_ACTIONS.TWEETS_COUNT_REQUEST, handleTweetsCountRequest),
    takeLatest(DISEASE_ACTIONS.DOL_REQUEST, handleDolRequest),
    takeLatest(DISEASE_ACTIONS.GPT_REQUEST, handleGptRequest),
  ]);
}

export default watchDiseaseSagas;
