import { useQuery } from "react-query";
import axios from "axios";
import { getApiPath } from "../../utils/variables";

export function getTweet(id, mwUserId) {
  const params = mwUserId ? "?mwUserId="+mwUserId : "";
  return axios.get(`${getApiPath(false)}/tweet/${id}${params}`);
}
export function useGetTweet(id, mwUserId) {
  return useQuery(["tweet"], () => getTweet(id, mwUserId), {
    cacheTime: 0,
  });
}
