import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { Icon } from "./Icon";
import { Color } from "../utils/variables";
import { Label } from "./Typography";
import { Row } from "./Layout";
import { Spinner } from "./Spinner";
import { getUrlParams } from "../utils";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  height: ${(props) => (props.small ? "32px" : props.large ? "50px" : "38px")};
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px;
  border: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${(props) =>
    props.danger ? Color("red", "6") : Color("green", "6")};
  padding: ${(props) =>
    props.small ? "0 14px 2px" : props.large ? "0 25px 2px" : "0 20px 2px"};
  border-radius: 30px;
  color: white;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  ${({ maxWidth }) =>
    maxWidth &&
    `
      width: 100%;
      max-width: ${maxWidth}px;
  `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: default;
      border-radius: 30px;
      pointer-events: none;
    `};

  &:focus {
    outline: 0;
    background-color: ${(props) =>
      props.secondary
        ? props.danger
          ? "white"
          : "white"
        : props.danger
        ? Color("red", "5")
        : Color("green", "5")};
    border-color: ${(props) =>
      props.secondary
        ? props.danger
          ? Color("red", "5")
          : Color("green", "5")
        : props.danger
        ? Color("red", "5")
        : Color("green", "5")};

    ${Label} {
      color: ${(props) =>
        props.secondary
          ? props.danger
            ? Color("red", "5")
            : Color("green", "5")
          : props.danger
          ? "white"
          : "white"};
    }
  }

  &:hover {
    background-color: ${(props) =>
      props.secondary
        ? props.danger
          ? Color("gray", "0.5")
          : Color("gray", "0.5")
        : props.danger
        ? Color("red", "7")
        : Color("green", "7")};
    border-color: ${(props) =>
      props.secondary
        ? props.danger
          ? Color("red", "7")
          : Color("green", "7")
        : props.danger
        ? "transparent"
        : "transparent"};
    ${Label} {
      color: ${(props) =>
        props.secondary
          ? props.danger
            ? Color("red", "7")
            : Color("green", "7")
          : props.danger
          ? "white"
          : "white"};
    }
    svg {
      fill: ${(props) =>
        props.secondary
          ? props.danger
            ? Color("red", "7")
            : Color("green", "7")
          : props.danger
          ? "white"
          : "white"};
    }
  }

  ${Label} {
    font-size: ${(props) => (props.large ? "20px" : "16px")};
  }

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => (props.noBkg ? "transparent" : "white")};
      border: 2px solid
        ${(props) => (props.danger ? Color("red", "6") : Color("green", "6"))};

      span {
        color: ${(props) =>
          props.danger ? Color("red", "6") : Color("green", "6")};
      }
    `};
`;

export const Button = ({
  id,
  icon,
  label,
  onClick,
  secondary,
  to,
  disabled,
  type,
  small,
  large,
  loading,
  blank,
  minWidth,
  maxWidth,
  reverse,
  danger,
  noShrink,
  noBkg,
}) => (
  <ConditionalWrapper
    condition={to && !disabled}
    wrapper={(children) =>
      blank ? (
        <a id={id} href={to} disabled={disabled} target="_blank">
          {children}
        </a>
      ) : (
        <Link {...{ id, to, disabled }}>{children}</Link>
      )
    }
  >
    <ButtonWrapper
      {...{
        id,
        onClick,
        secondary,
        disabled,
        type,
        small,
        large,
        minWidth,
        maxWidth,
        reverse,
        danger,
        noShrink,
        noBkg,
      }}
    >
      {icon && (
        <Row
          marginLeft={reverse ? "10" : "0"}
          marginRight={reverse ? "0" : "10"}
          marginTop="2"
        >
          <Icon
            name={icon}
            size={small ? "20" : null}
            color={secondary ? "green" : "white"}
            fade={secondary ? "6" : "default"}
          />
        </Row>
      )}
      {label && (
        <Label large bold noShrink>
          {label}
        </Label>
      )}

      {loading && (
        <Row paddingLeft="10">
          <Spinner color="white" small />
        </Row>
      )}
    </ButtonWrapper>
  </ConditionalWrapper>
);

const TabButtonWrapper = styled.div`
  ${({ active }) =>
    active
      ? css`
          background: ${Color("green", "3")};
          border: 1px solid ${Color("green", "3")};
        `
      : css`
          background: white;
          border: 1px solid ${Color("gray", "3")};
        `};
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  height: 50px;
  min-width: 0;
  transition: none; // to avoid flicking on first load
  margin-bottom: -1px;

  &:hover {
    ${({ active }) =>
      !active &&
      css`
        background-color: ${Color("gray", "0.5")};
        border: 1px solid ${Color("gray", "3")};
      `};
  }
`;

export const TabButton = ({ id, label, onClick, active }) => (
  <TabButtonWrapper {...{ id, onClick, active }}>
    {label && (
      <Label color="black" bold>
        {label}
      </Label>
    )}
  </TabButtonWrapper>
);

const IconButtonWrapper = styled(ButtonWrapper)`
  width: ${(props) => (props.small ? "34px" : props.medium ? "38px" : "43px")};
  height: ${(props) => (props.small ? "34px" : props.medium ? "38px" : "43px")};
  background-color: ${(props) =>
    props.bkg
      ? Color(props.bkg, props.fade || null)
      : props.small
      ? "transparent"
      : Color("green", "6")};
  padding: 0;
  border-radius: 50%;

  &:hover {
    background-color: ${(props) =>
      props.secondary
        ? Color("green", "1")
        : props.small
        ? Color("gray", "1")
        : Color("green", "7")};
    svg {
      ${(props) => props.small && `fill: ${Color("green", "7")}`};
    }
  }

  ${(props) =>
    props.secondary &&
    css`
      background: white;
      border: 2px solid ${Color("green", "6")};
    `};
`;

const IconToggleButtonWrapper = styled(ButtonWrapper)`
  width: 44px;
  height: 44px;
  padding: 0;
  border-radius: 10px;
  background-color: transparent;

  svg {
    fill: ${Color("gray", "8")};
  }

  &:hover {
    background-color: ${Color("gray", "1")};

    svg {
      fill: ${Color("green", "7")};
    }
  }

  &:focus {
    outline: 0;
    background-color: transparent;
  }

  &.active {
    svg {
      fill: ${Color("gray", "8")};
    }
    &:hover svg {
      fill: ${Color("green", "7")};
    }
  }
`;

export const IconButton = ({
  id,
  icon,
  back,
  onClick,
  to,
  disabled,
  small,
  medium,
  secondary,
  bkg,
  fade,
  color,
}) => (
  <ConditionalWrapper
    condition={to}
    wrapper={(children) => <Link {...{ id, to, disabled }}>{children}</Link>}
  >
    <IconButtonWrapper
      {...{ id, onClick, disabled, small, medium, secondary, bkg, fade }}
    >
      <Icon
        name={back ? "arrowLeft" : icon}
        color={
          color
            ? `${Color(color)}`
            : secondary
            ? "green"
            : small
            ? "gray"
            : "white"
        }
        fade={secondary ? "6" : small ? "8" : "default"}
        size={small ? "16" : null}
      />
    </IconButtonWrapper>
  </ConditionalWrapper>
);

export const IconToggleButton = ({
  id,
  onIcon,
  offIcon,
  onClick,
  to,
  disabled,
  active,
}) => (
  <ConditionalWrapper
    condition={to}
    wrapper={(children) => <Link {...{ id, to, disabled }}>{children}</Link>}
  >
    <IconToggleButtonWrapper
      {...{ id, onClick, disabled, onIcon, offIcon, active }}
    >
      <Icon name={active ? onIcon : offIcon} />
    </IconToggleButtonWrapper>
  </ConditionalWrapper>
);

const FabWrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 12;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    `};

  &:focus {
    outline: 0;
  }
`;

export const FabButton = ({ id, icon, onClick, to, disabled }) => (
  <FabWrapper {...{ id, disabled }}>
    <IconButton {...{ onClick, to }} icon={icon || "add"} />
  </FabWrapper>
);

export const ResponsiveButton = ({
  id,
  icon,
  label,
  onClick,
  to,
  secondary,
  disabled,
}) => (
  <>
    <Row show="tabletL">
      <Button {...{ id, to, onClick, label, icon, secondary, disabled }} />
    </Row>
    <Row hide="tabletL">
      <FabButton {...{ id, to, onClick, icon, disabled }} />
    </Row>
  </>
);

const AnchorWrapper = styled(Row)`
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px;
  border: none;
  background-color: transparent;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  word-break: break-word;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    `};

  ${({ reverse }) => reverse && "flex-direction: row-reverse"};

  ${Label} {
    font-size: ${(props) =>
      props.large ? "20px" : props.small ? "14px" : "16px"};
    // border-bottom: 1px solid;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    ${(props) =>
      !props.line
        ? "transparent"
        : props.color
        ? Color(props.color)
        : Color("green", "6")};
  }

  &:focus {
    outline: 0;
    ${Label} {
      color: ${(props) =>
        props.color ? Color(props.color, "5") : Color("green", "5")};
    }
  }

  &:hover,
  &.active {
    ${Label} {
      ${(props) => props.color === "black" && "text-decoration: underline;"};
      color: ${(props) =>
        props.color ? Color(props.color, "7") : Color("green", "7")};
    }
    svg {
      fill: ${(props) =>
        props.color ? Color(props.color, "7") : Color("green", "7")};
    }
  }
`;

export const Anchor = ({
  id,
  onClick,
  label,
  color,
  fade,
  to,
  inline,
  blank,
  icon,
  large,
  disabled,
  line,
  small,
  bold,
  noShrink,
  reverse,
  // reloadDocument,
  underline,
}) => (
  <ConditionalWrapper
    condition={to}
    wrapper={(children) =>
      blank ? (
        <a id={id} href={to} disabled={disabled} target="_blank">
          {children}
        </a>
      ) : (
        <Link
          {...{
            id,
            to,
            disabled,
            // reloadDocument
          }}
        >
          {children}
        </Link>
      )
    }
  >
    <AnchorWrapper
      {...{
        id,
        small,
        line,
        onClick,
        color,
        large,
        disabled,
        noShrink,
        reverse,
        underline,
      }}
      className={inline ? "inline" : ""}
      gap={small ? "5" : "10"}
    >
      {icon && (
        <Row middle>
          {icon === "spinner" ? (
            <Row marginRight="6" marginTop="-5">
              <Spinner small color="green" fade="7" />
            </Row>
          ) : (
            <Icon
              name={icon}
              size={small ? "20" : null}
              color={color || "green"}
              fade="6"
            />
          )}
        </Row>
      )}
      <Label
        {...{ noShrink, bold }}
        color={color || "green"}
        fade={fade || "6"}
      >
        {label}
      </Label>
    </AnchorWrapper>
  </ConditionalWrapper>
);

const ToggleWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px;
  border: none;
  background-color: transparent;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 10px 20px;
  border: 2px solid ${Color("gray", "0.4")};
  border-radius: 30px;

  &:focus {
    outline: 0;
  }

  &.active {
    background-color: ${Color("green", "6")};
    border-color: ${Color("green", "6")};
    color: white;
  }

  &:hover {
    background-color: ${Color("green", "6")};
    border-color: ${Color("green", "6")};
    color: white;
  }
`;
export const ToggleButton = ({ id, active, label, onChange }) => {
  const [isActive, setActive] = useState(active || false);

  return (
    <ToggleWrapper
      id={id}
      className={isActive ? "active" : ""}
      onClick={() => {
        setActive(!isActive);
        onChange?.(active);
      }}
    >
      <Label>{label}</Label>
    </ToggleWrapper>
  );
};

const PillContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
  font-weight: normal;
  ${(props) => props.expand && "width: 100%"};
  background-color: ${(props) =>
    props.bkg ? Color(props.bkg, props.fade || null) : Color("gray", "2")};
  border-radius: ${(props) => (props.small ? "8px" : "20px")};
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.small ? "2px 6px" : "8px 14px")};
  padding-right: ${(props) => props.close && "2px"};
  height: ${(props) => (props.large ? "36px" : "28px")};
  ${({ maxWidth }) =>
    maxWidth &&
    `
      width: 100%;
      margin: 0;
      max-width: ${maxWidth}px;
  `}
  ${({ noMargin }) => (noMargin ? `margin: 0;` : `margin: 0 10px 10px 0;`)}
   

  ${Label} {
    color: ${(props) => props.color || "black"};
    font-size: ${(props) => (props.large ? "20px" : "14px")};
  }
`;

const PillIconContainer = styled.button`
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  width: 40px;
  padding: 0;
  background-color: transparent;
  border: none;
  ${(props) => !props.disabled && `cursor: pointer;`};

  &:focus {
    outline: none;
  }
  &:hover {
    color: ${Color("green", "6")};
  }
`;

export const Pill = (props) => {
  const {
    id,
    small,
    label,
    testID,
    close,
    bkg,
    closeDisabled,
    secondLabel,
    large,
    maxWidth,
    noMargin,
    bold,
  } = props;

  return (
    <PillContainer accessibilityLabel={testID} {...props}>
      <Row gap="5" flexWrap>
        <Label bold={bold} noShrink={props.noShrink}>
          {label}
        </Label>
        {secondLabel && <Label bold>AND</Label>}
        {secondLabel && <Label noShrink={props.noShrink}>{secondLabel}</Label>}
      </Row>
      {close && (
        <PillIconContainer
          style={{ opacity: closeDisabled ? "0.3" : "1" }}
          onClick={() => !closeDisabled && props.onClose()}
        >
          <Icon
            id={`close-${id}`}
            name="close"
            size="16"
            color={props.color || "gray"}
            fade={props.color ? "default" : "8"}
          />
        </PillIconContainer>
      )}
    </PillContainer>
  );
};

const TipContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
  font-weight: normal;
  width: 100%;
  background-color: ${Color("green", "1")};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.close ? "4px 4px 4px 14px" : "4px 12px")};
  margin: 0 10px 10px 0;

  ${Label} {
    color: ${(props) => props.color || "black"};
  }
`;

export const Tip = (props) => {
  const { id, label, testID, close, bkg, closeDisabled } = props;

  return (
    <TipContainer accessibilityLabel={testID} {...props}>
      <Row gap="5" flexWrap fit marginLeft="6">
        <Label bold>Tip:</Label>
        <Label noShrink={props.noShrink}>{label}</Label>
      </Row>
      {close && (
        <PillIconContainer
          style={{ opacity: closeDisabled ? "0.3" : "1" }}
          onClick={() => !closeDisabled && props.onClose()}
        >
          <Icon
            id={`close-${id}`}
            name="close"
            size="20"
            color={props.color || "gray"}
            fade={props.color ? "default" : "8"}
          />
        </PillIconContainer>
      )}
    </TipContainer>
  );
};

export const ShareButton = ({
  activeTab,
  showIcon,
  handleShareEvent,
  tweet,
  tweets,
  highlights,
  keywords,
  influencers,
  bold,
}) => {
  const [queryParams] = useState(getUrlParams(location.search));
  let shareUrl = window.location.origin + window.location.pathname;
  queryParams.source = "share";
  const searchParams = new URLSearchParams(queryParams).toString();
  shareUrl = `${shareUrl}?${searchParams}`;

  if (activeTab) {
    shareUrl = shareUrl + `&tab=${activeTab}`;
  }

  let text = "";

  if (tweet) {
    text = `I think this tweet from ${tweet} is interesting. Check it out on Medical.watch.`;
  }
  if (tweets) {
    text = `I think these posts from ${tweets} are interesting. Check it out on Medical.watch.`;
  }
  if (highlights) {
    text = `I think these ${highlights} highlights are interesting. Check it out on Medical.watch.`;
  }
  if (keywords?.type === "disease") {
    text = `I think these Top Keywords in ${keywords.value} are interesting. Check it out on Medical.watch.`;
  }
  if (keywords?.type === "product") {
    text = `Check out these Top Keywords related to ${keywords.value} in Medical.watch.`;
  }
  if (keywords?.type === "conference") {
    text = `Check out these Top Keywords related to ${keywords.value} in Medical.watch.`;
  }
  if (influencers) {
    text = `Check out these Top Influencers tweeting about ${influencers} in Medical.watch.`;
  }

  const handleShare = () => {
    navigator
      .share({
        title: "New on Medical.watch",
        text: `Hey,
${text}`,
        url: shareUrl,
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch((err) => {
        console.log("Error while using Web share API:");
        console.log(err);
      });
  };

  return (
    <div style={{ marginBottom: "-6px" }} onClick={() => handleShareEvent?.()}>
      {navigator.share ? (
        <Anchor
          {...{ bold }}
          label="Share"
          icon={showIcon ? "share" : ""}
          onClick={() => handleShare()}
        />
      ) : (
        <Anchor
          {...{ bold }}
          blank
          label="Share"
          icon={showIcon ? "share" : ""}
          to={`mailto:?subject=New on Medical.watch&body=Hey, %0D%0D ${text} %0D%0D${shareUrl}`}
        />
      )}
    </div>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  alt: PropTypes.string,
  to: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  danger: PropTypes.bool,
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  back: PropTypes.bool,
  small: PropTypes.bool,
};

Anchor.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  to: PropTypes.string,
  blank: PropTypes.bool,
  noShrink: PropTypes.bool,
  icon: PropTypes.string,
};

ToggleButton.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  active: PropTypes.bool,
};

Pill.propTypes = {
  small: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  testID: PropTypes.string,
  noShrink: PropTypes.bool,
  bkg: PropTypes.string,
};

Tip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  testID: PropTypes.string,
  noShrink: PropTypes.bool,
  bkg: PropTypes.string,
};
