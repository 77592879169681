import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Table from "rc-table";
import { sortBy } from "lodash";

// import { tweetUrls } from "../../utils";

import {
  Column,
  Row,
  MainWrapper,
  Header,
  Content,
  Label,
  Pill,
  AutoCompleteWithResponses,
  Circle,
  Anchor,
  Tabs,
  H3,
  H4,
  Avatar,
  HR,
  Menu,
  RowToColumn,
  Grid,
  Keyword,
  AvatarGroup,
  Summary,
  Card,
  Blured,
  Metadata,
  TweetCard,
} from "../../components";
import { formatNumberWithCommas } from "../../utils";
import { Color } from "../../utils/variables";
import { WorldMapChart } from "../../components/WorldMapChart";
import { insightHighlight, formatTime } from "../../utils";

export const Group = ({ children, label }) => (
  <Column marginTop="40">
    <Label large color="gray" bold fade="8">
      {label}
    </Label>
    <Column marginTop="10">{children}</Column>
  </Column>
);

const TopSection = styled(Column)`
  background: ${Color("gray", "0.5")};
  padding: 20px;
  margin: -20px -20px 0;
  border-bottom: 1px solid ${Color("gray", "3")};
`;

const Form = styled.form`
  mark {
    cursor: pointer;
  }
`;

export const Box = ({ children, label }) => (
  <Column border="gray" radius="12" paddingAll="20" fit>
    <Label bold>{label}</Label>
    <Row marginTop="5">{children}</Row>
  </Column>
);

export const view = ({
  tweet,
  tweetReplies,
  isAdmin,
  isRegistered,
  activeTab,
  setActiveTab,
  tabs,

  ytData,

  tags,
  addSelectedTags,
  removeSelectedTags,
  searchTag,
  handleSearchTag,
  selectedTags,
  tagsInfinteScroll,

  diseases,
  addSelectedDiseases,
  removeSelectedDiseases,
  searchDisease,
  handleSearchDisease,
  selectedDiseases,

  products,
  addSelectedProducts,
  removeSelectedProducts,
  searchProduct,
  handleSearchProduct,
  selectedProducts,
  productsInfinteScroll,
  tweetFirstImage,

  accountsReplied,
  accountsRetweeted,
  accountsQuoted,
  allAccounts,
  sortTableBy,
  setSortTableBy,
  sortDirection,
  setSortDirection,

  // impressions,
  // impressionsPercentage,
  impressionByCountry,
  impressionByCity,
  impressionByInstitution,

  handleSignUpClick,
  sentimentTags,

  mentionedProducts,
  playAt,
  videoRef,
  podcastRef,
  showMoreProducts,
  setShowMoreProducts,
  maxProducts,
  transcriptProducts,
  contentRef,
  podcast,
  aiSummary,
  isLoadingSumary,
  isAiSumarySuccess,
  hasAiSummary,
  regenerateSummary,
  meidaTakeaways,
  isLoadingTakeaways,
  isTakeawaysySuccess,
}) => {
  const pluralize = ({ length, text }) => {
    if (length === 1) {
      return `${length} ${text}`;
    }
    return `${length} ${text}s`;
  };
  const hasQuote = tweet?.quoted?.length > 0;
  const productMentions =
    (ytData?.videoId || podcast) && mentionedProducts.length > 0
      ? mentionedProducts
      : tweet?.productTags?.concat(tweet?.diseaseTags);

  // We are using different values to adjust the time of the video/podcast depending if they are
  // old or new (new have "originalLanguage")
  const delay =
    ytData?.originalLanguage || podcast?.originalLanguage ? -3 : -50;

  return (
    <MainWrapper drawer>
      <Metadata
        title={tweet?.tAccount?.name}
        description={tweet?.content}
        image={
          tweetFirstImage?.url ||
          tweet?.tAccount?.imageURL?.replace("_bigger", "")
        }
      />
      <Header
        drawer
        back
        search
        login
        user
        signUpClick={handleSignUpClick}
        source="post"
      />
      <Menu />

      <Content ref={contentRef}>
        {tweet?.externalId && (
          <Column>
            <RowToColumn gap="20" device="laptopL" columnGap="10">
              <Column
                marginBottom
                maxWidth="700"
                paddingAll={hasQuote}
                style={
                  hasQuote
                    ? {
                        borderRadius: "10px",
                        border: `1px solid ${Color("gray", "4")}`,
                      }
                    : {}
                }
              >
                <Blured
                  enabled={isRegistered}
                  text={
                    <Label>
                      <Anchor bold label="Sign in" to="/login" />
                      <Row marginX="8">
                        <Label bold>or</Label>
                      </Row>
                      <Row marginRight="8">
                        <Anchor bold label="Sign up" to="/" />
                      </Row>
                      <Label bold>to see posts</Label>
                    </Label>
                  }
                >
                  <>
                    <TweetCard
                      key={tweet?.externalId}
                      data={tweet}
                      details
                      sentiment
                      hasQuote={hasQuote}
                      isTweetView
                      {...{ videoRef, podcastRef, podcast }}
                    />

                    {tweet?.repliedTo?.length > 0 && (
                      <Column marginTop="40" stretch>
                        <Row flexWrap marginBottom expand gap="30">
                          {tweet?.repliedTo.map((i) => (
                            <div
                              onClick={() =>
                                (window.location = `/post/${i.externalId}`)
                              }
                              key={i.externalId}
                              className="pointer"
                            >
                              <TweetCard
                                key={i.externalId}
                                data={i}
                                isTweetView
                              />
                            </div>
                          ))}
                        </Row>
                      </Column>
                    )}

                    {hasQuote && (
                      <Column marginTop="40" stretch>
                        <Row flexWrap expand gap="30">
                          {tweet?.quoted.map((i) => (
                            <div
                              onClick={() =>
                                (window.location = `/post/${i.externalId}`)
                              }
                              key={i.externalId}
                              className="pointer"
                            >
                              <TweetCard data={i} isTweetView />
                            </div>
                          ))}
                        </Row>
                      </Column>
                    )}

                    {tweet?.retweeted?.length > 0 && (
                      <Column marginTop="40" stretch>
                        <Row flexWrap marginBottom expand gap="30">
                          {tweet?.retweeted.map((i) => (
                            <div
                              onClick={() =>
                                (window.location = `/post/${i.externalId}`)
                              }
                              key={i.externalId}
                              className="pointer"
                            >
                              <TweetCard data={i} isTweetView />
                            </div>
                          ))}
                        </Row>
                      </Column>
                    )}
                  </>
                </Blured>
              </Column>

              <Column stretch maxWidth="300">
                <Blured enabled={isRegistered}>
                  {productMentions.length > 0 && (
                    <Column>
                      <Label bold color="gray" fade="8">
                        {(ytData?.videoId || podcast) &&
                        mentionedProducts.length > 0
                          ? "Product mentions"
                          : "See more posts about"}
                      </Label>
                      <Column gap="20" marginTop left>
                        {(ytData?.videoId || podcast) &&
                        mentionedProducts.length > 0 ? (
                          <Column gap="20" left>
                            {productMentions
                              .slice(
                                0,
                                showMoreProducts
                                  ? maxProducts
                                  : productMentions.length
                              )
                              .map((tag) => (
                                <Anchor
                                  key={tag.name}
                                  bold
                                  label={`${tag.name} (${formatTime(
                                    tag.start + delay
                                  )})`}
                                  onClick={() =>
                                    playAt(tag.start + delay, tag.name)
                                  }
                                />
                              ))}
                          </Column>
                        ) : (
                          <Column gap="20" left>
                            {productMentions
                              .slice(
                                0,
                                showMoreProducts
                                  ? maxProducts
                                  : productMentions.length
                              )
                              .map((tag) => (
                                <>
                                  {tag.productId && (
                                    <Anchor
                                      bold
                                      label={tag.name}
                                      to={`/?productId=${tag.productId}`}
                                    />
                                  )}
                                  {tag.diseaseId && (
                                    <Anchor
                                      key={tag.id}
                                      bold
                                      label={tag.name}
                                      to={`/?diseaseId=${tag.diseaseId}`}
                                    />
                                  )}
                                </>
                              ))}
                          </Column>
                        )}
                        {productMentions.length > maxProducts && (
                          <Anchor
                            reverse
                            icon={
                              showMoreProducts ? "chevronDown" : "chevronUp"
                            }
                            label={showMoreProducts ? "See more" : "See less"}
                            onClick={() =>
                              setShowMoreProducts(!showMoreProducts)
                            }
                          />
                        )}
                      </Column>
                      <Row expand marginY="30">
                        <HR />
                      </Row>
                    </Column>
                  )}
                  {tweet?.externalAuthorId && (
                    <Column gap="20" marginBottom="30">
                      <Label bold color="gray" fade="8">
                        Actions
                      </Label>
                      {tweet?.tAccount?.name && (
                        <Anchor
                          bold
                          label={`See more from ${tweet?.tAccount?.name}`}
                          to={`/account/${tweet?.tAccount?.id}?source=post`}
                          blank
                        />
                      )}
                      {tweet?.source === "X" && (
                        <Anchor
                          bold
                          label="View on X"
                          to={`https://twitter.com/${tweet?.externalAuthorId}/status/${tweet?.externalId}`}
                          blank
                        />
                      )}
                      {tweet?.source === "YT" && (
                        <Anchor
                          bold
                          label="View on YouTube"
                          to={`https://www.youtube.com/watch?v=${
                            tweet?.externalId?.split("YT_")?.[1]
                          }`}
                          blank
                        />
                      )}
                    </Column>
                  )}
                </Blured>
              </Column>
            </RowToColumn>
            {activeTab && (
              <Tabs
                active={activeTab}
                tabs={tabs}
                onChange={(e) => setActiveTab(e)}
              />
            )}
            <Column border="gray" fade="3" paddingAll>
              {activeTab === "analysis" && (
                <Column>
                  {tweet?.tAccount && (
                    <TopSection>
                      <Row gap="10">
                        <Row gap="10" middle>
                          <Link
                            id={`tweet-analysis-name-${tweet?.tAccount.id}`}
                            to={`/account/${tweet?.tAccount.id}?source=post`}
                          >
                            <AvatarGroup>
                              <Avatar
                                large
                                clickable
                                imageUrl={tweet?.tAccount.imageURL}
                                name={tweet?.tAccount.name}
                              />
                              <Column gap="5">
                                <Row gap="10">
                                  <Label className="text" large bold>
                                    {tweet?.tAccount.name}
                                  </Label>
                                  <Anchor
                                    bold
                                    label="See Profile"
                                    to={`/account/${tweet?.tAccount.id}?source=post`}
                                  />
                                </Row>
                                <Row gap="10">
                                  <Anchor
                                    color="gray"
                                    fade="7"
                                    label={`@${tweet?.tAccount.username}`}
                                    to={`https://twitter.com/${tweet?.tAccount.username}`}
                                    blank
                                  />
                                </Row>
                              </Column>
                            </AvatarGroup>
                          </Link>
                        </Row>
                      </Row>

                      {/* {tweet?.tAccount.description && (
                        <Row marginTop="20">
                          <Label>{tweet?.tAccount.description}</Label>
                        </Row>
                      )} */}
                      {tweet?.tAccount.institution?.name && (
                        <Column marginTop="20" gap="5">
                          <Label bold>Institution</Label>
                          <Label>{tweet?.tAccount.institution.name}</Label>
                        </Column>
                      )}
                      {tweet?.tAccount.institution?.displayLocation && (
                        <Column marginTop="20" gap="5">
                          <Label bold>Location</Label>
                          <Label>
                            {tweet?.tAccount.institution?.displayLocation}
                          </Label>
                        </Column>
                      )}
                    </TopSection>
                  )}

                  {(sentimentTags?.length > 0 || tweet?.numViews > 0) && (
                    <Column marginTop>
                      <Grid columns="3">
                        {sentimentTags?.map((st) => (
                          <>
                            <Box label={st.name}>
                              {st.sentimentAverage === 0 && (
                                <Row gap="8" middle>
                                  <Circle radius="10" bkg="gray" />
                                  <H3 bold>Neutral</H3>
                                </Row>
                              )}
                              {st.sentimentAverage > 0 && (
                                <Row gap="8" middle>
                                  <Circle radius="10" bkg="green" />
                                  <H3 bold>Positive</H3>
                                </Row>
                              )}
                              {st.sentimentAverage < 0 && (
                                <Row gap="8" middle>
                                  <Circle radius="10" bkg="red" />
                                  <H3 bold>Negative</H3>
                                </Row>
                              )}
                            </Box>
                          </>
                        ))}
                        {tweet?.numViews > 0 && (
                          <Box label="Views">
                            <Row gap="30" middle>
                              <H3 bold>
                                {formatNumberWithCommas(tweet?.numViews)}
                              </H3>
                              {tweet?.numViews >= 15000 && (
                                <Row gap="5" middle>
                                  <H3>🚀</H3>
                                  <Label bold>Excellent</Label>
                                </Row>
                              )}
                              {tweet?.numViews < 15000 &&
                                tweet?.numViews >= 5000 && (
                                  <Row gap="5" middle>
                                    <H3>😎</H3>
                                    <Label bold>Good</Label>
                                  </Row>
                                )}
                              {tweet?.numViews < 5000 &&
                                tweet?.numViews >= 1000 && (
                                  <Row gap="5" middle>
                                    <H3>😐</H3>
                                    <Label bold>Fair</Label>
                                  </Row>
                                )}
                              {tweet?.numViews < 1000 && (
                                <Row gap="5" middle>
                                  <H3>😔</H3>
                                  <Label bold>Poor</Label>
                                </Row>
                              )}
                            </Row>
                          </Box>
                        )}
                      </Grid>
                    </Column>
                  )}

                  {impressionByCountry.length > 0 && (
                    <Column>
                      <Row expand marginY>
                        <HR />
                      </Row>
                      <RowToColumn
                        gap="30"
                        device="laptopL"
                        columnGap="10"
                        middle
                      >
                        <Column marginBottom maxWidth="700">
                          <H4 bold>Views by country</H4>
                          <Column marginTop>
                            <WorldMapChart
                              id={`worldmapChart-1`}
                              data={impressionByCountry}
                              handleNameClick={() => {}}
                            />
                          </Column>
                        </Column>
                        {(impressionByCity.length > 0 ||
                          impressionByInstitution.length > 0) && (
                          <Column maxWidth="300">
                            <>
                              {impressionByCity.length > 0 && (
                                <>
                                  <Row>
                                    <Row fit>
                                      <Label bold>Top cities</Label>
                                    </Row>
                                    <Row right>
                                      <Label color="gray" fade="6">
                                        Views
                                      </Label>
                                    </Row>
                                  </Row>
                                  {impressionByCity.map((row) => (
                                    <Row marginTop key={row.id}>
                                      <Row fit>
                                        <Label>{row.id}</Label>
                                      </Row>
                                      <Row right>
                                        <Label>{row.value}</Label>
                                      </Row>
                                    </Row>
                                  ))}
                                </>
                              )}
                              {impressionByInstitution.length > 0 && (
                                <>
                                  <Row expand marginY>
                                    <HR />
                                  </Row>
                                  <Row marginTop="10">
                                    <Row fit>
                                      <Label bold>Top Institutions</Label>
                                    </Row>
                                    <Row right>
                                      <Label color="gray" fade="6">
                                        Views
                                      </Label>
                                    </Row>
                                  </Row>
                                  {impressionByInstitution.map((row) => (
                                    <Row marginTop key={row.id}>
                                      <Row fit>
                                        <Label>{row.id}</Label>
                                      </Row>
                                      <Row right>
                                        <Label>{row.value}</Label>
                                      </Row>
                                    </Row>
                                  ))}
                                </>
                              )}
                            </>
                          </Column>
                        )}
                      </RowToColumn>
                    </Column>
                  )}
                  {meidaTakeaways && (
                    <Column marginTop="10" gap="10" className="description">
                      <Summary
                        title="Key Takeaways"
                        isFetching={isLoadingTakeaways}
                        response={meidaTakeaways}
                        isSuccess={isTakeawaysySuccess}
                        hideMore
                      />
                    </Column>
                  )}

                  {(accountsReplied.length > 0 ||
                    accountsRetweeted.length > 0 ||
                    accountsQuoted.length > 0) && (
                    <Column>
                      <Row expand marginY>
                        <HR />
                      </Row>

                      <Row marginBottom="30">
                        <H4 bold>Views from physicians</H4>
                      </Row>
                      <div>
                        <Row
                          border="gray"
                          borderFade="4"
                          radius="10"
                          paddingY="10"
                          paddingX
                          marginBottom
                          gap="30"
                        >
                          {accountsReplied.length > 0 && (
                            <Label bold>
                              {accountsReplied.length}{" "}
                              {accountsReplied.length === 1
                                ? "reply"
                                : "replies"}
                            </Label>
                          )}
                          {accountsRetweeted.length > 0 && (
                            <Label bold>
                              {accountsRetweeted.length}{" "}
                              {accountsRetweeted.length === 1
                                ? "repost"
                                : "reposts"}
                            </Label>
                          )}
                          {accountsQuoted.length > 0 && (
                            <Label bold>
                              {accountsQuoted.length}{" "}
                              {accountsQuoted.length === 1 ? "quote" : "quotes"}
                            </Label>
                          )}
                        </Row>
                      </div>

                      {allAccounts.length > 0 && (
                        <Hcps
                          data={allAccounts}
                          {...{
                            sortTableBy,
                            setSortTableBy,
                            sortDirection,
                            setSortDirection,
                          }}
                        />
                      )}
                    </Column>
                  )}
                </Column>
              )}
              {activeTab === "transcript" && (
                <Column scroll style={{ maxHeight: "400px" }}>
                  {hasAiSummary && (
                    <Column
                      className="description"
                      marginBottom
                      marginTop="-10"
                    >
                      <Summary
                        title="Summary"
                        showRegenerate={isAdmin && !isLoadingSumary}
                        handleAiRefresh={regenerateSummary}
                        isFetching={isLoadingSumary}
                        response={aiSummary}
                        isSuccess={isAiSumarySuccess}
                        hideMore
                      />
                    </Column>
                  )}
                  {(ytData?.transcript || podcast?.transcript) && (
                    <Column className="description" maxWidth="700">
                      <Card border gap="20" paddingRight="30">
                        <H3 bold>Original transcript</H3>
                        <Keyword>
                          <Form
                            onClick={(e) => {
                              if (e.target.tagName === "MARK") {
                                playAt(parseInt(e.target.dataset.time) + delay);
                                contentRef?.current?.scrollTo(0, 0);
                              }
                            }}
                          >
                            {insightHighlight({
                              text: ytData.transcript || podcast?.transcript,
                              excludeBreaks: false,
                              // keywords: mentionedProducts.map((i) => i.name),
                              mentions: transcriptProducts,
                              breakLines: true,
                            })}
                          </Form>
                        </Keyword>
                      </Card>
                    </Column>
                  )}
                </Column>
              )}

              {activeTab === "replies" && (
                <Column>
                  {tweetReplies.map((tweet, index) => (
                    <Row marginTop={index !== 0} expand key={tweet?.externalId}>
                      <Row
                        onClick={() =>
                          (window.location = `/post/${tweet?.externalId}`)
                        }
                        key={tweet?.externalId}
                        className="pointer"
                        expand
                        maxWidth="680"
                      >
                        <TweetCard data={tweet} isTweetView />
                      </Row>
                    </Row>
                  ))}
                </Column>
              )}
              {activeTab === "tags" && (
                <>
                  {tweet?.content && (
                    <Column>
                      <Column marginTop="-40">
                        <Group
                          label={pluralize({
                            length: selectedDiseases.length,
                            text: "Disease",
                          })}
                        >
                          {isAdmin ? (
                            <Column>
                              <AutoCompleteWithResponses
                                showOnEmptyTerm
                                searchResults={diseases}
                                placeholder="Add Disease"
                                setSearchTerm={(e) => handleSearchDisease(e)}
                                searchTerm={searchDisease}
                                selectedItems={selectedDiseases}
                                addItem={addSelectedDiseases}
                                removeItem={removeSelectedDiseases}
                                clearSearchResults={() => {}}
                                searchLoading={false}
                                hidePills
                              />
                              {selectedDiseases?.length > 0 && (
                                <Row flexWrap marginTop marginBottom="-10">
                                  {selectedDiseases.map((tag) => (
                                    <Pill
                                      large
                                      label={tag.label}
                                      key={tag.id}
                                      close
                                      bkg="green"
                                      fade="2"
                                      onClose={() =>
                                        removeSelectedDiseases({ id: tag.id })
                                      }
                                    />
                                  ))}
                                </Row>
                              )}
                            </Column>
                          ) : (
                            <>
                              {tweet?.diseaseTags?.length > 0 && (
                                <Row flexWrap marginTop="10" marginBottom="-10">
                                  {tweet?.diseaseTags.map((tag) => (
                                    <Pill
                                      label={tag.name}
                                      key={tag.id}
                                      large
                                      bkg="green"
                                      fade="2"
                                    />
                                  ))}
                                </Row>
                              )}
                            </>
                          )}
                        </Group>

                        <Group
                          label={pluralize({
                            length: selectedProducts.length,
                            text: "Product",
                          })}
                        >
                          {isAdmin ? (
                            <Column>
                              <AutoCompleteWithResponses
                                showOnEmptyTerm
                                searchResults={products}
                                placeholder="Add Product"
                                setSearchTerm={(e) => handleSearchProduct(e)}
                                searchTerm={searchProduct}
                                selectedItems={selectedProducts}
                                addItem={addSelectedProducts}
                                removeItem={removeSelectedProducts}
                                clearSearchResults={() => {}}
                                searchLoading={false}
                                infiniteScroll={productsInfinteScroll}
                                hasClearButton
                                hidePills
                              />
                              {selectedProducts?.length > 0 && (
                                <Row flexWrap marginTop marginBottom="-10">
                                  {selectedProducts.map((tag) => (
                                    <Pill
                                      large
                                      label={tag.label}
                                      key={tag.id}
                                      close
                                      bkg="green"
                                      fade="2"
                                      onClose={() =>
                                        removeSelectedProducts({ id: tag.id })
                                      }
                                    />
                                  ))}
                                </Row>
                              )}
                            </Column>
                          ) : (
                            <>
                              {tweet?.productTags?.length > 0 && (
                                <Row flexWrap marginTop="10" marginBottom="-10">
                                  {tweet?.productTags.map((tag) => (
                                    <Pill
                                      label={tag?.name}
                                      key={tag?.id}
                                      large
                                      bkg="green"
                                      fade="2"
                                    />
                                  ))}
                                </Row>
                              )}
                            </>
                          )}
                        </Group>

                        <Group
                          label={pluralize({
                            length: selectedTags.length,
                            text: "Tag",
                          })}
                        >
                          {isAdmin ? (
                            <Column>
                              <AutoCompleteWithResponses
                                showOnEmptyTerm
                                searchResults={tags}
                                placeholder="Add Tag"
                                setSearchTerm={(e) => handleSearchTag(e)}
                                searchTerm={searchTag}
                                selectedItems={selectedTags}
                                addItem={addSelectedTags}
                                removeItem={removeSelectedTags}
                                clearSearchResults={() => {}}
                                searchLoading={false}
                                infiniteScroll={tagsInfinteScroll}
                                hasClearButton
                                hidePills
                              />
                              {selectedTags?.length > 0 && (
                                <Row flexWrap marginTop marginBottom="-10">
                                  {selectedTags.map((tag) => (
                                    <Pill
                                      large
                                      label={tag.label}
                                      key={tag.id}
                                      close
                                      bkg="green"
                                      fade="2"
                                      onClose={() =>
                                        removeSelectedTags({ id: tag.id })
                                      }
                                    />
                                  ))}
                                </Row>
                              )}
                            </Column>
                          ) : (
                            <>
                              {tweet?.tags?.length > 0 && (
                                <Row flexWrap marginTop="10" marginBottom="-10">
                                  {tweet?.tags.map((tag) => (
                                    <Pill
                                      label={tag?.name}
                                      key={tag?.id}
                                      large
                                      bkg="green"
                                      fade="2"
                                    />
                                  ))}
                                </Row>
                              )}
                            </>
                          )}
                        </Group>
                      </Column>
                    </Column>
                  )}
                </>
              )}
            </Column>
          </Column>
        )}
      </Content>
    </MainWrapper>
  );
};

const Hcps = ({
  data,
  sortTableBy,
  setSortTableBy,
  sortDirection,
  setSortDirection,
}) => {
  const getClassNames = (title) => {
    if (sortTableBy === title) {
      return sortDirection === "asc" ? "sort-asc" : "sort-desc";
    }
    return "";
  };

  const headerActions = (key) => ({
    onClick() {
      if (sortTableBy === key) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortTableBy(key);
        if (key === "score" || key === "mentions") {
          setSortDirection("asc");
        } else {
          setSortDirection("desc");
        }
      }
    },
  });

  const columns = [
    {
      title: "HCP",
      key: "name",
      render: (cell) => (
        <Link to={`/account/${cell.id}?source=post`}>
          <Row middle gap="10">
            <Avatar imageUrl={cell.imageURL} name={cell.name} />
            <Label bold>{cell.name}</Label>
          </Row>
        </Link>
      ),
      className: getClassNames("name"),
      onHeaderCell: (header) => headerActions(header.key),
    },
    {
      title: "Type",
      key: "type",
      render: (cell) => <Label>{cell.type}</Label>,
      className: getClassNames("type"),
      onHeaderCell: () => headerActions("type"),
    },
    {
      title: "Influence",
      key: "influence",
      render: (cell) => (
        <Label>
          {cell.twitterNetworkScore > 666
            ? "High"
            : cell.twitterNetworkScore > 333
            ? "Medium"
            : "Low"}
        </Label>
      ),
      className: getClassNames("influence"),
      onHeaderCell: () => headerActions("influence"),
    },
    {
      title: "Location",
      key: "location",
      render: (cell) => (
        <Label>
          {cell.institution?.displayLocation || cell.displayLocation}
        </Label>
      ),
      className: getClassNames("location"),
      onHeaderCell: () => headerActions("location"),
    },
    {
      title: "Country",
      key: "country",
      render: (cell) => (
        <Label>{cell.institution?.country || cell.country}</Label>
      ),
      className: getClassNames("country"),
      onHeaderCell: () => headerActions("country"),
    },
    {
      title: "Institution",
      key: "institutionName",
      render: (cell) => <Label>{cell.institution?.name || ""}</Label>,
      className: getClassNames("institutionName"),
      onHeaderCell: () => headerActions("institutionName"),
    },
    /*
    {
      title: "Mentions",
      key: "mentions",
      render: (cell) => <Label>{cell.mentions}</Label>,
    },
    */
    {
      title: "Audience",
      key: "audience",
      render: (cell) => (
        <Label>{formatNumberWithCommas(cell.numFollowers)}</Label>
      ),
      className: getClassNames("audience"),
      onHeaderCell: () => headerActions("audience"),
    },
  ];

  return (
    <Column>
      <Table
        rowKey={"id"}
        className="sortable"
        // data={sortBy(data, "name")}
        {...{ columns, data }}
        style={{ maxWidth: 850 }}
        scroll={{ x: 849 }}
      />
    </Column>
  );
};
