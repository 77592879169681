import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDebounce from "../../../utils/useDebounce";
import { MAX_RESULTS } from "../../../utils/variables";

import {
  tagActions,
  diseaseActions,
  productActions,
  conferenceActions,
  commonActions,
  settingsActions,
} from "../../../store/actions";
import {
  cancelTagRequests,
  cancelDiseaseRequests,
  cancelProductRequests,
  cancelConferenceRequests,
  cancelCommonRequests,
} from "../../../store/sagas";
import { useUpdateTags } from "../../../store/queries/tags";

import { view } from "./TagsView";

export const AdminTags = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { mutateAsync: updateTags } = useUpdateTags();

  // --> mapStateToProp
  const tags = useSelector((state) => state.tag.adminSummary);
  const products = useSelector((state) => state.product.all.data);
  const diseases = useSelector((state) => state.disease.all.data);
  const conferences = useSelector((state) => state.conference.all.data);
  const meaningState = useSelector((state) => state.common.meanings);
  const tagSettings = useSelector((state) => state.settings?.tag);
  // <-- mapStateToProp

  // --> STATE
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm, 700);

  const [meaningItems, setMeaningItems] = useState([
    {
      id: "ALL",
      label: "All",
    },
  ]);
  const [selectedMeaningId, setSelectedMeaningId] = useState(
    tagSettings?.meaning || "ALL"
  );

  const [statusItems] = useState([
    { id: "ALL", label: "All" },
    { id: "ACTIVE", label: "Active" },
    { id: "PREFERRED", label: "Preferred" },
    { id: "MERGED", label: "Merged" },
    { id: "BLACKLISTED", label: "Blacklisted" },
    { id: "REVIEWED", label: "Reviewed" },
    { id: "NEEDS_REVIEWED", label: "Needs Review" },
    { id: "EXACT_MATCH_ON", label: "Exact match on" },
    { id: "EXACT_MATCH_OFF", label: "Exact match off" },
  ]);
  const [selectedStatusId, setSelectedStatusId] = useState(
    tagSettings?.status || "ALL"
  );

  const [includeStats, setIncludeStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [matchTags, setMatchTags] = useState(false);
  const [blacklistTags, setBlacklistTags] = useState(false);
  const [selectedMatchTags, setSelectedMatchTags] = useState([]);
  const [selectedBlacklistTags, setSelectedBlacklistTags] = useState([]);
  // <-- STATE

  const requestTags = (pageNum) => {
    let ignore = null;
    let preferred = null;
    let reviewed = null;
    let match = null;
    switch (selectedStatusId) {
      case "ACTIVE": // active (non-blacklisted & non-whitelisted)
        ignore = false;
        break;
      case "PREFERRED": // active, but only substitutes
        ignore = false;
        preferred = true;
        break;
      case "MERGED": // whitelisted
        ignore = false;
        preferred = false;
        break;
      case "BLACKLISTED": // blacklisted
        ignore = true;
        break;
      case "REVIEWED": // reviewed
        reviewed = true;
        break;
      case "NEEDS_REVIEWED": // needs reviewed
        reviewed = false;
        break;
      case "EXACT_MATCH_ON": // exact match on
        match = true;
        break;
      case "EXACT_MATCH_OFF": // exact match off
        match = false;
        break;
    }

    dispatch(
      tagActions.adminSummaryRequest({
        ...(selectedMeaningId !== "ALL" && { meaning: selectedMeaningId }),
        ...(searchTerm !== "" && { term: searchTerm }),
        ignore,
        match,
        preferred,
        reviewed,
        withStats: includeStats,
        pageNum,
        maxResult: MAX_RESULTS,
      })
    );
  };

  const handleStatusChange = (item) => {
    setSelectedStatusId(item.id);
    dispatch(
      settingsActions.set({
        tag: { ...tagSettings, status: item.id },
      })
    );
  };

  const handleMeaningChange = (item) => {
    setSelectedMeaningId(item.id);
    dispatch(
      settingsActions.set({
        tag: { ...tagSettings, meaning: item.id },
      })
    );
  };

  // --> EFFECT
  useEffect(() => {
    return () => {
      dispatch(conferenceActions.clearAll());
      dispatch(tagActions.clearTags());

      cancelTagRequests.abort();
      cancelDiseaseRequests.abort();
      cancelProductRequests.abort();
      cancelConferenceRequests.abort();
      cancelCommonRequests.abort();
    };
  }, []);

  useEffect(() => {
    // get meanings only if not found
    if (Object.keys(meaningState)?.length == 0)
      dispatch(commonActions.requestMeanings());
    dispatch(diseaseActions.request({ maxResult: -1 }));
    dispatch(productActions.request({ maxResult: -1 }));
    dispatch(conferenceActions.request({ maxResult: -1 }));
  }, [dispatch]);

  useEffect(() => {
    if (meaningState?.length > 0) {
      const newMeanings = [];
      for (const key in meaningState) {
        // Excluding 'CONGRESS' meaning. We only support 'CONFERENCE'
        if (meaningState[key].enumStringValue !== "CONGRESS") {
          newMeanings.push({
            id: meaningState[key].enumStringValue,
            label: meaningState[key].enumValueDisplayName,
          });
        }
      }
      setMeaningItems([{ id: "ALL", label: "All" }, ...newMeanings]);
    }
  }, [meaningState]);

  useEffect(() => {
    requestTags(0);
    setPage(0);
  }, [debounceTerm, includeStats, selectedStatusId, selectedMeaningId]);

  useEffect(() => {
    setLoading(tags.fetching);
  }, [tags.fetching]);
  // <-- EFFECT

  const handleMoreItems = () => {
    if (!loading) {
      const newPage = page + 1;
      requestTags(newPage);
      setPage(newPage);
    }
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: !tags.isLastPage,
    onLoadMore: handleMoreItems,
  });

  const getActiveFilters = () => {
    let activeFilters = [];
    if (searchTerm) {
      activeFilters.push(`Search: ${searchTerm}`);
    }
    if (selectedStatusId !== "ALL") {
      activeFilters.push(
        `Status: ${statusItems.find((a) => selectedStatusId === a.id)?.label}`
      );
    }
    if (selectedMeaningId !== "ALL") {
      activeFilters.push(
        `Meaning: ${
          meaningItems.find((a) => selectedMeaningId === a.id)?.label
        }`
      );
    }

    return activeFilters;
  };

  const applyMatchTags = async () => {
    const response = await updateTags(selectedMatchTags);
    if (response.data) {
      setMatchTags(false);
      setSelectedMatchTags([]);
      requestTags(0);
      setPage(0);
    }
  };

  const cancelMatchTags = () => {
    setMatchTags(false);
    setSelectedMatchTags([]);
  };

  const updateSelectedMatchTags = (tagId) => {
    if (selectedMatchTags.includes(tagId)) {
      setSelectedMatchTags((prev) => prev.filter((id) => id !== tagId));
    } else {
      setSelectedMatchTags((prev) => [...prev, tagId]);
    }
  };

  const applyBlacklistTags = async () => {
    selectedBlacklistTags.forEach((tagId, index) => {
      const selectedTag = tags.data.find((t) => t.tag.id === tagId);
      if (selectedTag) {
        const newData = {
          ...selectedTag.tag,
          alwaysIgnore: true,
        };
        dispatch(tagActions.update(newData));
      }

      if (index === selectedBlacklistTags.length - 1) {
        setTimeout(() => {
          setBlacklistTags(false);
          setSelectedBlacklistTags([]);
          requestTags(0);
          setPage(0);
        }, 500);
      }
    });
  };

  const cancelBlacklistTags = () => {
    setBlacklistTags(false);
    setSelectedBlacklistTags([]);
  };

  const updateSelectedBlacklistTags = (tagId) => {
    if (selectedBlacklistTags.includes(tagId)) {
      setSelectedBlacklistTags((prev) => prev.filter((id) => id !== tagId));
    } else {
      setSelectedBlacklistTags((prev) => [...prev, tagId]);
    }
  };

  return view({
    history,
    loading,
    tags: tags.data || [],
    tagsCount: tags.data?.length /*tagsCount.data*/ || 0,
    activeFilters: getActiveFilters(),
    infiniteScrollRef,
    hasNextPage: !tags.isLastPage,

    searchTerm,
    setSearchTerm,

    meaningItems,
    selectedMeaningId,
    handleMeaningChange,

    selectedStatusId,
    handleStatusChange,
    statusItems,

    includeStats,
    setIncludeStats,

    diseases,
    products,
    conferences,

    matchTags,
    setMatchTags,
    applyMatchTags,
    cancelMatchTags,
    selectedMatchTags,
    updateSelectedMatchTags,

    blacklistTags,
    setBlacklistTags,
    applyBlacklistTags,
    selectedBlacklistTags,
    cancelBlacklistTags,
    updateSelectedBlacklistTags,
  });
};
