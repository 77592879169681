import React from "react";
import ConfettiExplosion from "react-confetti-explosion";
import styled from "styled-components";
import { Disease } from "./DiseaseContainer";
import { Product } from "./ProductContainer";
import { Conference } from "./ConferenceContainer";
import { NoMonitors } from "./NoMonitors";
import { Signup1 } from "./Signup1";
import { Signup4 } from "./Signup4";
import { Signup5 } from "./Signup5";
import {
  Spinner,
  Column,
  Cookies,
  Modal,
  ModalFooter,
  Label,
  Button,
  Row,
  Circle,
  Icon,
  H4,
  Anchor,
} from "../../components";

const ExplosionWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const view = (props) => {
  const {
    showDashboard,
    abVersion,
    // handleSignUpClick,
    userData,
    showWelcomeModal,
    toggleWelcomeModal,
    welcomeStep,
    setWelcomeStep,
    selectedMonitor,
    monitors,
    handleWelcomeClose,
    showExplosion,
    setShowExplosion,
  } = props;
  return (
    <>
      {!showDashboard ? (
        <>
          <Signup5 />
          {/* {abVersion ? (
            <>
              {abVersion === 1 ? (
                <Signup1 />
              ) : (
                <>{abVersion === 2 ? <Signup4 /> : <Signup5 />}</>
              )}
            </>
          ) : (
            <Column expand center middle>
              <Spinner />
            </Column>
          )} */}
          <Cookies />
        </>
      ) : (
        <>
          {userData?.active && (
            <Modal
              filter
              visible={showWelcomeModal}
              toggleVisibility={toggleWelcomeModal}
              title={`Welcome to Medical.watch, ${userData.firstName}!`}
              close
              maxWidth="600"
              maxHeight="600"
              onClose={handleWelcomeClose}
            >
              <Column paddingX="20" bkg="white" fit>
                {welcomeStep === 1 ? (
                  <>
                    <Row marginTop="-10" marginBottom="60">
                      <Label>Thanks for confirming your email</Label>
                    </Row>
                    <Column center paddingX="60">
                      <Circle radius="50" bkg="gray" fade="1" center>
                        <Icon
                          name="emailLarge"
                          size="64"
                          color="gray"
                          fade="6"
                        />
                      </Circle>
                      <Column gap="15" marginTop>
                        {monitors.length === 1 && (
                          <H4 bold center>
                            You're following {monitors[0].label}
                          </H4>
                        )}
                        {monitors.length > 1 && (
                          <H4 bold center>
                            You're following
                            {monitors.map((monitor, index) => (
                              <span key={index}>
                                {index === 0
                                  ? " "
                                  : index === monitors.length - 1
                                  ? " and "
                                  : ", "}
                                {monitor.label}
                              </span>
                            ))}
                          </H4>
                        )}
                        <Column gap="2" center>
                          <Label center>
                            Want to follow other diseases or products?
                          </Label>
                          <Label center>
                            Click{" "}
                            <Anchor
                              label="Manage Preferences"
                              underline
                              blank
                              to="/preferences"
                            />
                          </Label>
                        </Column>
                      </Column>
                    </Column>
                  </>
                ) : (
                  <>
                    <Row marginTop="-10" marginBottom="60">
                      <Label>We’re glad you’re here</Label>
                    </Row>
                    <Column center paddingX="60">
                      <Circle radius="50" bkg="gray" fade="1" center>
                        <Icon
                          name="analyticsLarge"
                          size="64"
                          color="gray"
                          fade="6"
                        />
                      </Circle>
                      <Column gap="15" marginTop>
                        <H4 bold center>
                          Get analytics and reports on the website
                        </H4>
                        <Label center>
                          View top influencers, add filters and more!
                        </Label>
                      </Column>
                    </Column>
                  </>
                )}
              </Column>
              <ModalFooter filter noSahdow right>
                <Row gap="20" middle>
                  <Label>Step {welcomeStep} of 2</Label>
                  {welcomeStep === 1 ? (
                    <Button
                      small
                      onClick={() => setWelcomeStep(2)}
                      label="Next"
                      minWidth="150"
                    />
                  ) : (
                    <Button
                      small
                      onClick={() => {
                        handleWelcomeClose();
                        setShowExplosion(true);
                      }}
                      label="Get started"
                      minWidth="150"
                    />
                  )}
                </Row>
              </ModalFooter>
            </Modal>
          )}
          {showExplosion && (
            <ExplosionWrapper>
              <ConfettiExplosion
                colors={[
                  "#EAF9F5",
                  "#D4F4E9",
                  "#AAE8D5",
                  "#7FDDCO",
                  "#60D2AC",
                  "#2AC697",
                  "#229E79",
                  "#19775B",
                  "#114F3C",
                  "#08281E",
                ]}
                force={1}
                duration={4000}
                particleCount={400}
                width={1600}
              />
            </ExplosionWrapper>
          )}
          {!selectedMonitor && <NoMonitors />}
          {selectedMonitor?.type === "DISEASE" && <Disease {...{ props }} />}
          {selectedMonitor?.type === "PRODUCT" && <Product {...{ props }} />}
          {selectedMonitor?.type === "CONFERENCE" && (
            <Conference {...{ props }} />
          )}
        </>
      )}
    </>
  );
};
