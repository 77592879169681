import React, { useRef } from "react";
import { Example } from "../../components/Example";
// import { Link } from "react-scroll";
import { NavHashLink } from "react-router-hash-link";

import {
  Column,
  Row,
  Circle,
  Header,
  H1,
  H2,
  H3,
  Label,
  P,
  Button,
  IconButton,
  Icon,
  MainWrapper,
  Card,
  Content,
  Anchor,
  Input,
  TextArea,
  Dropdown,
  Avatar,
  Tabs,
  Filters,
  Switch,
  List,
  ListItem,
  Drawer,
  Spinner,
  BlinkCursor,
  IconToggleButton,
} from "../../components";

import { States } from "../../mocks/states";

export const view = ({}) => {
  const content = useRef(null);
  const executeScroll = () =>
    content.current.scrollIntoView({ behavior: "smooth", block: "end" });

  const Menu = [
    { id: 1, label: "Typography", to: "typography" },
    { id: 2, label: "Colors", to: "colors" },
    { id: 3, label: "Layout", to: "layout" },
    { id: 4, label: "Icons", to: "icons" },
    { id: 5, label: "Spinner", to: "spinner" },
    { id: 6, label: "Avatar", to: "avatar" },
    { id: 7, label: "Buttons", to: "buttons" },
    { id: 8, label: "Inputs", to: "inputs" },
    { id: 9, label: "Toggles", to: "toggles" },
    { id: 10, label: "Dropdowns", to: "dropdowns" },
    { id: 11, label: "Tabs", to: "tabs" },
    { id: 12, label: "Filters", to: "filters" },
  ];

  return (
    <MainWrapper drawer ref={content}>
      <Header drawer></Header>
      <Drawer>
        <List>
          {Menu.map((item) => (
            <Row key={item.id}>
              <NavHashLink
                smooth
                to={`/styleguide#${item.to}`}
                activeClassName="active"
              >
                <ListItem>{item.label}</ListItem>
              </NavHashLink>
            </Row>
          ))}
        </List>
      </Drawer>
      <Content>
        <Column>
          <Card>
            <Row marginBottom="30" id="typography">
              <H1>Typography</H1>
            </Row>
            <Example
              prepend="import { H1 } from '../components';"
              code={<H1>Heading 1</H1>}
              text="<H1>Heading 1</H1>"
            />
            <Example
              prepend="import { H2 } from '../components';"
              code={<H2>Heading 2</H2>}
              text="<H2>Heading 2</H2>"
            />
            <Example
              prepend="import { H3 } from '../components';"
              code={<H3>Heading 3</H3>}
              text="<H3>Heading 3</H3>"
            />
            <Example
              prepend="import { Label } from '../components';"
              code={<Label>Default Label</Label>}
              text="<Label>Default Label</Label>"
            />
            <Example
              prepend="import { Label } from '../components';"
              code={
                <Label color="blue" bold large>
                  Custom Label
                </Label>
              }
              text='<Label color="blue" bold large>
                  Custom Label
                </Label>'
            />
            <Example
              name="Paragraph"
              last
              prepend="import { P } from '../components';"
              code={
                <Column>
                  <P>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </P>
                  <P>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </P>
                </Column>
              }
              text={`
<Column>
  <P>
    Lorem Ipsum is simply dummy text of the printing and
    typesetting industry.
  </P>
  <P>
    Lorem Ipsum is simply dummy text of the printing and
    typesetting industry.
  </P>
</Column>
              `}
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="colors">
              <H1>Colors</H1>
            </Row>
            <Example
              code={
                <Row gap="10">
                  <Circle bkg="gray" fade="0.5" radius="35">
                    gray 0.5
                  </Circle>
                  <Circle bkg="gray" fade="1" radius="35">
                    gray 1
                  </Circle>
                  <Circle bkg="gray" fade="2" radius="35">
                    gray 2
                  </Circle>
                  <Circle bkg="gray" fade="3" radius="35">
                    gray 3
                  </Circle>
                  <Circle bkg="gray" fade="4" radius="35">
                    gray 4
                  </Circle>
                  <Circle bkg="gray" fade="5" radius="35">
                    gray 5
                  </Circle>
                  <Circle bkg="gray" fade="6" radius="35">
                    gray 6
                  </Circle>
                  <Circle bkg="gray" fade="7" radius="35">
                    gray 7
                  </Circle>
                  <Circle bkg="gray" fade="8" radius="35">
                    gray 8
                  </Circle>
                  <Circle bkg="gray" fade="9" radius="35">
                    gray 9
                  </Circle>
                </Row>
              }
              text={`
<Row gap="10">
  <Circle bkg="gray" fade="0.5" radius="35">
    gray 0.5
  </Circle>
  <Circle bkg="gray" fade="1" radius="35">
    gray 1
  </Circle>
  <Circle bkg="gray" fade="2" radius="35">
    gray 2
  </Circle>
  <Circle bkg="gray" fade="3" radius="35">
    gray 3
  </Circle>
  <Circle bkg="gray" fade="4" radius="35">
    gray 4
  </Circle>
  <Circle bkg="gray" fade="5" radius="35">
    gray 5
  </Circle>
  <Circle bkg="gray" fade="6" radius="35">
    gray 6
  </Circle>
  <Circle bkg="gray" fade="7" radius="35">
    gray 7
  </Circle>
  <Circle bkg="gray" fade="8" radius="35">
    gray 8
  </Circle>
  <Circle bkg="gray" fade="9" radius="35">
    gray 9
  </Circle>
</Row>
              `}
            />

            <Example
              code={
                <Row gap="10">
                  <Circle bkg="green" fade="1" radius="35">
                    green 1
                  </Circle>
                  <Circle bkg="green" fade="2" radius="35">
                    green 2
                  </Circle>
                  <Circle bkg="green" fade="3" radius="35">
                    green 3
                  </Circle>
                  <Circle bkg="green" fade="4" radius="35">
                    green 4
                  </Circle>
                  <Circle bkg="green" fade="5" radius="35">
                    green 5
                  </Circle>
                  <Circle bkg="green" fade="6" radius="35">
                    green 6
                  </Circle>
                  <Circle bkg="green" fade="7" radius="35">
                    green 7
                  </Circle>
                  <Circle bkg="green" fade="8" radius="35">
                    green 8
                  </Circle>
                  <Circle bkg="green" fade="9" radius="35">
                    green 9
                  </Circle>
                </Row>
              }
              text={`
<Row gap="10">
  <Circle bkg="green" fade="1" radius="35">
    green 1
  </Circle>
  <Circle bkg="green" fade="2" radius="35">
    green 2
  </Circle>
  <Circle bkg="green" fade="3" radius="35">
    green 3
  </Circle>
  <Circle bkg="green" fade="4" radius="35">
    green 4
  </Circle>
  <Circle bkg="green" fade="5" radius="35">
    green 5
  </Circle>
  <Circle bkg="green" fade="6" radius="35">
    green 6
  </Circle>
  <Circle bkg="green" fade="7" radius="35">
    green 7
  </Circle>
  <Circle bkg="green" fade="8" radius="35">
    green 8
  </Circle>
  <Circle bkg="green" fade="9" radius="35">
    green 9
  </Circle>
</Row>
              `}
            />
            <Example
              code={
                <Row gap="10">
                  <Circle bkg="blue" fade="1" radius="35">
                    blue 1
                  </Circle>
                  <Circle bkg="blue" fade="2" radius="35">
                    blue 2
                  </Circle>
                  <Circle bkg="blue" fade="3" radius="35">
                    blue 3
                  </Circle>
                  <Circle bkg="blue" fade="4" radius="35">
                    blue 4
                  </Circle>
                  <Circle bkg="blue" fade="5" radius="35">
                    blue 5
                  </Circle>
                  <Circle bkg="blue" fade="6" radius="35">
                    blue 6
                  </Circle>
                  <Circle bkg="blue" fade="7" radius="35">
                    blue 7
                  </Circle>
                  <Circle bkg="blue" fade="8" radius="35">
                    blue 8
                  </Circle>
                  <Circle bkg="blue" fade="9" radius="35">
                    blue 9
                  </Circle>
                </Row>
              }
              text={`
<Row gap="10">
  <Circle bkg="blue" fade="1" radius="35">
    blue 1
  </Circle>
  <Circle bkg="blue" fade="2" radius="35">
    blue 2
  </Circle>
  <Circle bkg="blue" fade="3" radius="35">
    blue 3
  </Circle>
  <Circle bkg="blue" fade="4" radius="35">
    blue 4
  </Circle>
  <Circle bkg="blue" fade="5" radius="35">
    blue 5
  </Circle>
  <Circle bkg="blue" fade="6" radius="35">
    blue 6
  </Circle>
  <Circle bkg="blue" fade="7" radius="35">
    blue 7
  </Circle>
  <Circle bkg="blue" fade="8" radius="35">
    blue 8
  </Circle>
  <Circle bkg="blue" fade="9" radius="35">
    blue 9
  </Circle>
</Row>
              `}
            />
            <Example
              code={
                <Row gap="10">
                  <Circle bkg="yellow" fade="1" radius="35">
                    yellow 1
                  </Circle>
                  <Circle bkg="yellow" fade="2" radius="35">
                    yellow 2
                  </Circle>
                  <Circle bkg="yellow" fade="3" radius="35">
                    yellow 3
                  </Circle>
                  <Circle bkg="yellow" fade="4" radius="35">
                    yellow 4
                  </Circle>
                  <Circle bkg="yellow" fade="5" radius="35">
                    yellow 5
                  </Circle>
                  <Circle bkg="yellow" fade="6" radius="35">
                    yellow 6
                  </Circle>
                  <Circle bkg="yellow" fade="7" radius="35">
                    yellow 7
                  </Circle>
                  <Circle bkg="yellow" fade="8" radius="35">
                    yellow 8
                  </Circle>
                  <Circle bkg="yellow" fade="9" radius="35">
                    yellow 9
                  </Circle>
                </Row>
              }
              text={`
<Row gap="10">
  <Circle bkg="yellow" fade="1" radius="35">
    yellow 1
  </Circle>
  <Circle bkg="yellow" fade="2" radius="35">
    yellow 2
  </Circle>
  <Circle bkg="yellow" fade="3" radius="35">
    yellow 3
  </Circle>
  <Circle bkg="yellow" fade="4" radius="35">
    yellow 4
  </Circle>
  <Circle bkg="yellow" fade="5" radius="35">
    yellow 5
  </Circle>
  <Circle bkg="yellow" fade="6" radius="35">
    yellow 6
  </Circle>
  <Circle bkg="yellow" fade="7" radius="35">
    yellow 7
  </Circle>
  <Circle bkg="yellow" fade="8" radius="35">
    yellow 8
  </Circle>
  <Circle bkg="yellow" fade="9" radius="35">
    yellow 9
  </Circle>
</Row>
              `}
            />
            <Example
              text={`
<Row gap="10">
  <Circle bkg="red" fade="1" radius="35">
    red 1
  </Circle>
  <Circle bkg="red" fade="2" radius="35">
    red 2
  </Circle>
  <Circle bkg="red" fade="3" radius="35">
    red 3
  </Circle>
  <Circle bkg="red" fade="4" radius="35">
    red 4
  </Circle>
  <Circle bkg="red" fade="5" radius="35">
    red 5
  </Circle>
  <Circle bkg="red" fade="6" radius="35">
    red 6
  </Circle>
  <Circle bkg="red" fade="7" radius="35">
    red 7
  </Circle>
  <Circle bkg="red" fade="8" radius="35">
    red 8
  </Circle>
  <Circle bkg="red" fade="9" radius="35">
    red 9
  </Circle>
</Row>
              `}
              code={
                <Row gap="10">
                  <Circle bkg="red" fade="1" radius="35">
                    red 1
                  </Circle>
                  <Circle bkg="red" fade="2" radius="35">
                    red 2
                  </Circle>
                  <Circle bkg="red" fade="3" radius="35">
                    red 3
                  </Circle>
                  <Circle bkg="red" fade="4" radius="35">
                    red 4
                  </Circle>
                  <Circle bkg="red" fade="5" radius="35">
                    red 5
                  </Circle>
                  <Circle bkg="red" fade="6" radius="35">
                    red 6
                  </Circle>
                  <Circle bkg="red" fade="7" radius="35">
                    red 7
                  </Circle>
                  <Circle bkg="red" fade="8" radius="35">
                    red 8
                  </Circle>
                  <Circle bkg="red" fade="9" radius="35">
                    red 9
                  </Circle>
                </Row>
              }
            />
            <Example
              text={`
<Row gap="10">
  <Circle bkg="purple" fade="1" radius="35">
    purple 1
  </Circle>
  <Circle bkg="purple" fade="2" radius="35">
    purple 2
  </Circle>
  <Circle bkg="purple" fade="3" radius="35">
    purple 3
  </Circle>
  <Circle bkg="purple" fade="4" radius="35">
    purple 4
  </Circle>
  <Circle bkg="purple" fade="5" radius="35">
    purple 5
  </Circle>
  <Circle bkg="purple" fade="6" radius="35">
    purple 6
  </Circle>
  <Circle bkg="purple" fade="7" radius="35">
    purple 7
  </Circle>
  <Circle bkg="purple" fade="8" radius="35">
    purple 8
  </Circle>
  <Circle bkg="purple" fade="9" radius="35">
    purple 9
  </Circle>
</Row>
              `}
              code={
                <Row gap="10">
                  <Circle bkg="purple" fade="1" radius="35">
                    purple 1
                  </Circle>
                  <Circle bkg="purple" fade="2" radius="35">
                    purple 2
                  </Circle>
                  <Circle bkg="purple" fade="3" radius="35">
                    purple 3
                  </Circle>
                  <Circle bkg="purple" fade="4" radius="35">
                    purple 4
                  </Circle>
                  <Circle bkg="purple" fade="5" radius="35">
                    purple 5
                  </Circle>
                  <Circle bkg="purple" fade="6" radius="35">
                    purple 6
                  </Circle>
                  <Circle bkg="purple" fade="7" radius="35">
                    purple 7
                  </Circle>
                  <Circle bkg="purple" fade="8" radius="35">
                    purple 8
                  </Circle>
                  <Circle bkg="purple" fade="9" radius="35">
                    purple 9
                  </Circle>
                </Row>
              }
            />
            <Example
              code={
                <Row gap="10">
                  <Circle bkg="pink" fade="1" radius="35">
                    pink 1
                  </Circle>
                  <Circle bkg="pink" fade="2" radius="35">
                    pink 2
                  </Circle>
                  <Circle bkg="pink" fade="3" radius="35">
                    pink 3
                  </Circle>
                  <Circle bkg="pink" fade="4" radius="35">
                    pink 4
                  </Circle>
                  <Circle bkg="pink" fade="5" radius="35">
                    pink 5
                  </Circle>
                  <Circle bkg="pink" fade="6" radius="35">
                    pink 6
                  </Circle>
                  <Circle bkg="pink" fade="7" radius="35">
                    pink 7
                  </Circle>
                  <Circle bkg="pink" fade="8" radius="35">
                    pink 8
                  </Circle>
                  <Circle bkg="pink" fade="9" radius="35">
                    pink 9
                  </Circle>
                </Row>
              }
              text={`
<Row gap="10">
  <Circle bkg="pink" fade="1" radius="35">
    pink 1
  </Circle>
  <Circle bkg="pink" fade="2" radius="35">
    pink 2
  </Circle>
  <Circle bkg="pink" fade="3" radius="35">
    pink 3
  </Circle>
  <Circle bkg="pink" fade="4" radius="35">
    pink 4
  </Circle>
  <Circle bkg="pink" fade="5" radius="35">
    pink 5
  </Circle>
  <Circle bkg="pink" fade="6" radius="35">
    pink 6
  </Circle>
  <Circle bkg="pink" fade="7" radius="35">
    pink 7
  </Circle>
  <Circle bkg="pink" fade="8" radius="35">
    pink 8
  </Circle>
  <Circle bkg="pink" fade="9" radius="35">
    pink 9
  </Circle>
</Row>
              `}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="layout">
              <H1>Layout</H1>
            </Row>
            <Example
              name="Row"
              prepend="import { Row } from '../components';"
              code={
                <>
                  <Row bkg="blue" fade="5" paddingAll expand>
                    Default (top - left)
                  </Row>
                  <Row bkg="blue" fade="3" paddingAll expand center>
                    Center
                  </Row>
                  <Row bkg="blue" fade="5" paddingAll expand right>
                    Right
                  </Row>
                </>
              }
              text={`
<Row bkg="blue" fade="5" paddingAll expand>
  Default (top - left)
</Row>
<Row bkg="blue" fade="3" paddingAll expand center>
  Center
</Row>
<Row bkg="blue" fade="5" paddingAll expand right>
  Right
</Row>
              `}
              gray
            />
            <Example
              name="Column"
              prepend="import { Column } from '../components';"
              code={
                <>
                  <Column bkg="green" fade="5" paddingAll stretch>
                    Default (top - left)
                  </Column>
                  <Column bkg="green" fade="3" paddingAll stretch center>
                    Center
                  </Column>
                  <Column bkg="green" fade="5" paddingAll stretch right>
                    Right
                  </Column>
                </>
              }
              text={`
<Column bkg="green" fade="5" paddingAll stretch>
  Default (top - left)
</Column>
<Column bkg="green" fade="3" paddingAll stretch center>
  Center
</Column>
<Column bkg="green" fade="5" paddingAll stretch right>
  Right
</Column>
              `}
              gray
            />
            <Example
              last
              name="Responsive"
              comment={
                <Column>
                  <P>
                    Reduce/increase your browser size to see how the layout
                    changes from Row to Column, when reaching 'tablet'
                    breackpoint.
                  </P>
                  <P>Other posible breakpoint values are:</P>
                  <Column marginLeft>
                    <P>
                      <b>- mobileS</b> (320px),
                    </P>
                    <P>
                      <b>- mobileM</b> (425px),
                    </P>
                    <P>
                      <b>- mobileL</b> (550px),
                    </P>
                    <P>
                      <b>- mobileXL</b> (640px),
                    </P>
                    <P>
                      <b>- tablet</b> (768px),
                    </P>
                    <P>
                      <b>- tabletL</b> (900px),
                    </P>
                    <P>
                      <b>- laptop</b> (1024px),
                    </P>
                    <P>
                      <b>- laptopL</b> (1118px),
                    </P>
                    <P>
                      <b>- desktop</b> (1400px)
                    </P>
                    <P>
                      <b>- desktopL</b> (2560px)
                    </P>
                  </Column>
                </Column>
              }
              prepend="import { Row, Circle } from '../components';"
              code={
                <Row bkg="red" gap="10" toColumn="tablet" paddingAll>
                  <Circle radius="10" bkg="white" />
                  <Circle radius="10" bkg="white" />
                  <Circle radius="10" bkg="white" />
                </Row>
              }
              text={`
<Row bkg="red" gap="10" toColumn="tablet" paddingAll>
  <Circle radius="10" bkg="white" />
  <Circle radius="10" bkg="white" />
  <Circle radius="10" bkg="white" />
</Row>
              `}
              gray
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="icons">
              <H1>Icons</H1>
            </Row>
            <Example prepend="import { Icon } from '../components';" />
            <Column marginLeft>
              <Example
                inline
                last
                code={<Icon name="add" />}
                text={`<Icon name="add" />`}
              />
              <Example
                inline
                last
                code={<Icon name="arrowDropDown" />}
                text={`<Icon name="arrowDropDown" />`}
              />
              <Example
                inline
                last
                code={<Icon name="arrowLeft" />}
                text={`<Icon name="arrowLeft" />`}
              />
              <Example
                inline
                last
                code={<Icon name="arrowLeft" small />}
                text={`<Icon name="arrowLeft" small />`}
              />
              <Example
                inline
                last
                code={<Icon name="arrowRight" />}
                text={`<Icon name="arrowRight" />`}
              />
              <Example
                inline
                last
                code={<Icon name="arrowRight" small />}
                text={`<Icon name="arrowRight" small/>`}
              />
              <Example
                inline
                last
                code={<Icon name="arrowDown" />}
                text={`<Icon name="arrowDown" />`}
              />
              <Example
                inline
                last
                code={<Icon name="chevronRight" />}
                text={`<Icon name="chevronRight" />`}
              />
              <Example
                inline
                last
                code={<Icon name="chevronDown" />}
                text={`<Icon name="chevronDown" />`}
              />
              <Example
                inline
                last
                code={<Icon name="calendar" />}
                text={`<Icon name="calendar" />`}
              />
              <Example
                inline
                last
                code={<Icon name="checkContainer" />}
                text={`<Icon name="checkContainer" />`}
              />
              <Example
                inline
                last
                code={<Icon name="checkFilled" />}
                text={`<Icon name="checkFilled" />`}
              />
              <Example
                inline
                last
                code={<Icon name="check" />}
                text={`<Icon name="check" />`}
              />
              <Example
                inline
                last
                code={<Icon name="close" />}
                text={`<Icon name="close" />`}
              />
              <Example
                inline
                last
                code={<Icon name="dots" />}
                text={`<Icon name="dots" />`}
              />
              <Example
                inline
                last
                code={<Icon name="down" />}
                text={`<Icon name="down" />`}
              />
              <Example
                inline
                last
                code={<Icon name="down" small />}
                text={`<Icon name="down" small />`}
              />
              <Example
                inline
                last
                code={<Icon name="info" />}
                text={`<Icon name="info" />`}
              />
              <Example
                inline
                last
                code={<Icon name="search" />}
                text={`<Icon name="search" />`}
              />
              <Example
                inline
                last
                code={<Icon name="up" />}
                text={`<Icon name="up" />`}
              />
              <Example
                inline
                last
                code={<Icon name="up" small />}
                text={`<Icon name="up" small />`}
              />
              <Example
                inline
                last
                code={<Icon name="download" />}
                text={`<Icon name="download" />`}
              />
              <Example
                inline
                last
                code={<Icon name="share" />}
                text={`<Icon name="share" />`}
              />
              <Example
                inline
                last
                code={<Icon name="edit" />}
                text={`<Icon name="edit" />`}
              />
              <Example
                inline
                last
                code={<Icon name="email" />}
                text={`<Icon name="email" />`}
              />
              <Example
                inline
                last
                code={<Icon name="drawer" />}
                text={`<Icon name="drawer" />`}
              />
              <Example
                inline
                last
                code={<Icon name="retweet" />}
                text={`<Icon name="retweet" />`}
              />
              <Example
                inline
                last
                code={<Icon name="reply" />}
                text={`<Icon name="reply" />`}
              />
              <Example
                inline
                last
                code={<Icon name="warning" />}
                text={`<Icon name="warning" />`}
              />
              <Example
                inline
                last
                code={<Icon name="link" />}
                text={`<Icon name="link" />`}
              />
              <Example
                inline
                last
                code={<Icon name="cursor" />}
                text={`<Icon name="cursor" />`}
              />
              <Example
                inline
                last
                code={<Icon name="reload" />}
                text={`<Icon name="reload" />`}
              />
              <Example
                inline
                last
                code={<Icon name="send" />}
                text={`<Icon name="send" />`}
              />
              <Example
                inline
                last
                code={<Icon name="chat" />}
                text={`<Icon name="chat" />`}
              />
              <Example
                inline
                last
                code={<Icon name="copy" />}
                text={`<Icon name="copy" />`}
              />
              <Example
                inline
                last
                code={<Icon name="play" />}
                text={`<Icon name="play" />`}
              />
              <Example
                inline
                last
                code={<Icon name="facebook" />}
                text={`<Icon name="facebook" />`}
              />
              <Example
                inline
                last
                code={<Icon name="noImage" />}
                text={`<Icon name="noImage" />`}
              />
              <Example
                inline
                last
                code={<Icon name="saveOn" />}
                text={`<Icon name="saveOn" />`}
              />
              <Example
                inline
                last
                code={<Icon name="saveOff" />}
                text={`<Icon name="saveOff" />`}
              />

              <Column marginTop="40">
                <Row marginBottom>
                  <H3>Custom size</H3>
                </Row>
                <Example
                  code={<Icon name="add" size="100" />}
                  text={`<Icon name="add" size="100" />`}
                />
              </Column>

              <Row marginBottom>
                <H3>Custom color</H3>
              </Row>
              <Example
                code={<Icon name="add" color="gray" fade="4" />}
                text={`<Icon name="add" color="gray" fade="4" />`}
              />

              <Row marginBottom>
                <H3>Special</H3>
              </Row>
              <Example
                code={<Icon name="twitter" color="twitter" />}
                text={`<Icon name="twitter" color="twitter" />`}
              />
              <Example
                code={<Icon name="positive" color="green" />}
                text={`<Icon name="positive" color="green" />`}
              />
              <Example
                code={<Icon name="neutral" color="gray" fade="4" />}
                text={`<Icon name="neutral" color="gray" fade="4" />`}
              />
              <Example
                last
                code={<Icon name="negative" color="purple" fade="4" />}
                text={`<Icon name="negative" color="purple" fade="4" />`}
              />
            </Column>
          </Card>

          <Card>
            <Row marginBottom="30" id="spinner">
              <H1>Spinner</H1>
            </Row>
            <Example
              name="Default"
              prepend={`import { Spinner } from '../components';`}
              code={<Spinner />}
              text={`<Spinner />`}
            />

            <Example
              name="Custom"
              prepend={`import { Spinner } from '../components';`}
              code={<Spinner color="blue" small />}
              text={`<Spinner color="blue" small />`}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="spinner">
              <H1>BlinkCursor</H1>
            </Row>

            <Example
              name="Custom"
              prepend={`import { BlinkCursor } from '../components';`}
              code={<BlinkCursor color="green" />}
              text={`<BlinkCursor color="green" />`}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="avatar">
              <H1>Avatar</H1>
            </Row>
            <Example
              name="By name"
              prepend={`import { Avatar } from '../components';`}
              code={<Avatar name="AZ" />}
              text={`<Avatar name="AZ" />`}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="buttons">
              <H1>Buttons</H1>
            </Row>
            <Example
              name="Button"
              prepend={`import { Button } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Button label="small" small />
                  <Button label="default" />
                  <Button label="large" large />
                </Row>
              }
              text={`
<Row gap="10" middle>
  <Button label="small" small />
  <Button label="default" />
  <Button label="large" large />
</Row>
              `}
            />
            <Example
              name="Secondary Button"
              prepend={`import { Button } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Button secondary label="small" small />
                  <Button secondary label="default" />
                  <Button secondary label="large" large />
                </Row>
              }
              text={`
  <Row gap="10" middle>
    <Button secondary label="small" small />
    <Button secondary label="default" />
    <Button secondary label="large" large />
  </Row>
              `}
            />
            <Example
              name="Danger Button"
              prepend={`import { Button } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Button danger label="small" small />
                  <Button danger label="default" />
                  <Button danger label="large" large />
                </Row>
              }
              text={`
<Row gap="10" middle>
    <Button danger label="small" small />
    <Button danger label="default" />
    <Button danger label="large" large />
  </Row>
              `}
            />
            <Example
              name="Secondary Danger Button"
              prepend={`import { Button } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Button secondary danger label="small" small />
                  <Button secondary danger label="default" />
                  <Button secondary danger label="large" large />
                </Row>
              }
              text={`
<Row gap="10" middle>
  <Button secondary danger label="small" small />
  <Button secondary danger label="default" />
  <Button secondary danger label="large" large />
</Row>
              `}
            />
            <Example
              name="Button with icon"
              prepend={`import { Button } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Button label="default" icon="arrowLeft" />
                  <Button secondary label="secondary" icon="arrowLeft" />
                </Row>
              }
              text={`
<Row gap="10" middle>
  <Button label="default" icon="arrowLeft" />
  <Button secondary label="secondary" icon="arrowLeft" />
</Row>
              `}
            />
            <Example
              name="Icon button"
              prepend={`import { IconButton } from '../components';`}
              code={<IconButton back />}
              text={`<IconButton back />`}
            />
            <Example
              name="Anchor"
              prepend={`import { Anchor } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Anchor label="default" />
                  <Anchor label="large" large />
                </Row>
              }
              text={`
<Row gap="10" middle>
  <Anchor label="default" />
  <Anchor label="large" large />
</Row>
              `}
            />
            <Example
              name="Icon toggle button"
              prepend={`import { IconToggleButton } from '../components';`}
              code={
                <Row gap="10">
                  <IconToggleButton onIcon="saveOn" offIcon="saveOff" />
                  <IconToggleButton onIcon="saveOn" offIcon="saveOff" active />
                </Row>
              }
              text={`<IconToggleButton onIcon="saveOn" offIcon="saveOff" active={boolean} />`}
            />
            <Example
              name="Anchor"
              prepend={`import { Anchor } from '../components';`}
              code={
                <Row gap="10" middle>
                  <Anchor label="default" />
                  <Anchor label="large" large />
                </Row>
              }
              text={`
<Row gap="10" middle>
  <Anchor label="default" />
  <Anchor label="large" large />
</Row>
              `}
            />
            <Example
              last
              name="Anchor width icon"
              prepend={`import { Anchor } from '../components';`}
              code={
                <Row gap="20" middle>
                  <Anchor label="default" icon="arrowLeft" />
                  <Anchor label="large" icon="arrowLeft" large />
                </Row>
              }
              text={`
<Row gap="20" middle>
  <Anchor label="default" icon="arrowLeft" />
  <Anchor label="large" icon="arrowLeft" large />
</Row>
              `}
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="inputs">
              <H1>Inputs</H1>
            </Row>
            <Example
              name="Input"
              prepend={`import { Input } from '../components';`}
              code={
                <Column gap="20">
                  <Input placeholder="Enter some text" value="small" small />
                  <Input placeholder="Enter some text" value="default" />
                  <Input placeholder="Enter some text" value="large" large />
                </Column>
              }
              text={`
<Column gap="20">
  <Input placeholder="Enter some text" value="small" small />
  <Input placeholder="Enter some text" value="default" />
  <Input placeholder="Enter some text" value="large" large />
</Column>
              `}
            />
            <Example
              name="Input"
              prepend={`import { Input } from '../components';`}
              code={
                <Column gap="20">
                  <Input
                    placeholder="Enter some text"
                    value="small"
                    small
                    rounded
                  />
                  <Input
                    placeholder="Enter some text"
                    value="default"
                    rounded
                  />
                </Column>
              }
              text={`
<Column gap="20">
  <Input
    placeholder="Enter some text"
    value="small"
    small
    rounded
  />
  <Input
    placeholder="Enter some text"
    value="default"
    rounded
  />
</Column>
              `}
            />
            <Example
              name="Input + icon"
              prepend={`import { Input } from '../components';`}
              code={<Input placeholder="Search" icon="search" value="" />}
              text={`<Input placeholder="Search" icon="search" value="" />`}
            />
            <Example
              code={
                <Input
                  reverse
                  placeholder="Search"
                  icon="close"
                  iconAction={() => {}}
                  value=""
                  iconSize="18"
                />
              }
              text={`
<Input
  reverse
  placeholder="Search"
  icon="close"
  iconAction={() => {}}
  value=""
  iconSize="18"
/>
              `}
            />
            <Example
              name="TextArea"
              prepend={`import { TextArea } from '../components';`}
              code={<TextArea placeholder="Enter some text" value="" />}
              text={`<TextArea placeholder="Enter some text" value="" />`}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="toggles">
              <H1>Toggles</H1>
            </Row>
            <Example
              name="Switch"
              prepend={`import { Switch } from '../components';`}
              code={
                <Column>
                  <Switch label="Enabled" checked onChange={() => {}} />
                  <Switch label="Disabled" onChange={() => {}} />
                </Column>
              }
              text={`
<Column>
  <Switch label="Enabled" checked onChange={() => {}} />
  <Switch label="Disabled" onChange={() => {}} />
</Column>
              `}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="dropdowns">
              <H1>Dropdowns</H1>
            </Row>
            <Example
              name="Default"
              prepend={`import { Dropdown } from '../components';`}
              code={
                <Dropdown
                  label="Posts"
                  items={[
                    { id: 1, label: "All" },
                    { id: 2, label: "None" },
                  ]}
                />
              }
              text={`
<Dropdown
  label="Posts"
  items={[
    { id: 1, label: "All" },
    { id: 2, label: "None" },
  ]}
/>
              `}
            />
            <Example
              name="With check icon"
              code={
                <Dropdown
                  expand
                  select
                  width="300"
                  label="Posts by"
                  items={[
                    { id: 1, label: "Everyone" },
                    { id: 2, label: "Only from followed physicians" },
                  ]}
                />
              }
              text={`
<Dropdown
  expand
  select
  width="300"
  label="Posts by"
  items={[
    { id: 1, label: "Everyone" },
    { id: 2, label: "Only from followed physicians" },
  ]}
/>
              `}
            />
            <Example
              name="Multiple"
              code={
                <Dropdown multiple width="300" label="Region" items={States} />
              }
              text={`<Dropdown multiple width="300" label="Region" items={States} />`}
            />
            <Example
              name="With custom button"
              code={
                <Dropdown
                  trigger={<Avatar name="John" />}
                  items={[
                    { id: 1, label: "Item 1", border: true },
                    { id: 2, label: "Item 2" },
                  ]}
                />
              }
              text={`
<Dropdown
  trigger={<Avatar name="John" />}
  items={[
    { id: 1, label: "Item 1", border: true },
    { id: 2, label: "Item 2" },
  ]}
/>
              `}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="tabs">
              <H1>Tabs</H1>
            </Row>
            <Example
              prepend={`import { Tabs } from '../components';`}
              code={
                <Tabs
                  style={{ position: "static" }}
                  active={1}
                  tabs={[
                    { id: 1, label: "Users" },
                    { id: 2, label: "Statistics" },
                  ]}
                />
              }
              text={`
<Tabs
  style={{ position: "static" }}
  active={1}
  tabs={[
    { id: 1, label: "Users" },
    { id: 2, label: "Statistics" },
  ]}
/>
              `}
              last
            />
          </Card>

          <Card>
            <Row marginBottom="30" id="filters">
              <H1>Filters</H1>
            </Row>
            <Example
              prepend={`import { Filters } from '../components';`}
              code={
                <Filters expand>
                  <Row expand>Something inside</Row>
                </Filters>
              }
              text={`
<Filters expand>
  <Row expand>Something inside</Row>
</Filters>
              `}
              last
            />
          </Card>
        </Column>
      </Content>
    </MainWrapper>
  );
};
