import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useDynamicRefs from "use-dynamic-refs";
import useKeyPress from "../utils/useKeyPress";

import { Icon } from "./Icon";
import { Label } from "./Typography";
import { Column, Row } from "./Layout";
import { Color } from "../utils/variables";
import { Input } from "./Input";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const Dropdown = (props) => {
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  // const tabPress = useKeyPress("Tab");

  const [results, setResults] = React.useState(props.items);
  const { disabled = false } = props;
  const getInitValue = (value) =>
    results?.filter((i) => i.id === value).shift();
  const [selectedItem, setSelectedItem] = React.useState(
    getInitValue(props.value) || results?.[0]
  );
  const [selectedItems, setSelectedItems] = React.useState(props.values || []);
  const [isListVisible, setListVisibility] = React.useState(false);
  const [itemFocused, setItemFocused] = React.useState(-1);
  const [getRef, setRef] = useDynamicRefs();
  const [textToFilter, setTextToFilter] = React.useState("");
  const changeSelectedItem = React.useCallback((i) => {
    if (props.multiple) {
      let tempResults = [];
      const item = selectedItems.find((a) => a.id === results[i].id);
      const allItem = results.find((a) => a.id === "ALL");
      if (results[i].id === "ALL") {
        if (item?.id === "ALL") {
          tempResults = [];
        } else {
          tempResults = results;
        }
      } else {
        if (item) {
          const newItems = [...selectedItems];
          tempResults = newItems.filter(
            (a) => a.id !== item.id && a.id !== "ALL"
          );
        } else {
          if (allItem && results.length === selectedItems.length + 2) {
            tempResults = results;
          } else {
            const newItems = [...selectedItems];
            tempResults = newItems.concat(results[i]);
          }
        }
      }
      setSelectedItems(tempResults);
      // if (props.items.length === tempResults.length) {
      //   props.onChange([]);
      // } else {
      props.onChange(tempResults);
      // }
    } else {
      if (i > -1) {
        setSelectedItem(results[i]);
        props.onChange?.(results[i]);
      }
      setListVisibility(false);
    }
  });

  React.useEffect(() => {
    if (props.value || props.value === 0) {
      if (results) {
        setSelectedItem(results.filter((elem) => elem.id === props.value)[0]);
      }
    } else {
      setSelectedItem("");
    }
  }, [props.value, results]);

  React.useEffect(() => {
    if (props.value && props.multiple) {
      setSelectedItems(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.excludeItems?.length > 0) {
      let resultSet = props.items;
      props.excludeItems.forEach((item) => {
        resultSet = resultSet.filter((r) =>
          props.excludeIdName
            ? r.id !== item[props.excludeIdName]
            : r.id !== item.id
        );
      });
      setResults(resultSet);
    } else {
      setResults(props.items);
    }
  }, [props.items]);

  React.useEffect(() => {
    if (upPress && isListVisible) {
      if (itemFocused > 0) {
        setItemFocused(itemFocused - 1);
        getRef(`resultItem-${itemFocused - 1}`).current.focus();
      }
      if (itemFocused === 0) {
        setListVisibility(false);
      }
    }
  }, [upPress]);
  React.useEffect(() => {
    if (downPress && isListVisible && itemFocused < results.length - 1) {
      setItemFocused(itemFocused + 1);
      getRef(`resultItem-${itemFocused + 1}`).current.focus();
    }
  }, [downPress]);
  React.useEffect(() => {
    if (enterPress && isListVisible) {
      changeSelectedItem(itemFocused);
    }
  }, [enterPress]);
  // React.useEffect(() => {
  //   if (tabPress && isListVisible) {
  //     setListVisibility(false);
  //   }
  // }, [tabPress]);

  const toggleList = React.useCallback(() => {
    setListVisibility(!isListVisible);
  });

  const getText = (selectedItem) => {
    if (typeof selectedItem === "string" && selectedItem !== "") {
      return selectedItem;
    } else {
      return selectedItem?.label;
    }
  };

  const handleMouseLeave = () => {
    if (isListVisible && !props.filter) {
      setTimeout(() => {
        setListVisibility(false);
      }, 500);
    }
  };

  let text = "";

  if (props.multiple) {
    const allItem = results.find((a) => a.id === "ALL");

    if (selectedItems[0]?.id === "ALL") {
      text = "All " + props.multipleLabel;
    } else if (selectedItems.length > 1) {
      text = "Multiple " + props.multipleLabel;
    } else {
      text =
        selectedItems[0]?.label || allItem
          ? "All " + props.multipleLabel
          : "Select " + props.multipleLabel;
    }
  } else {
    text = getText(selectedItem);
  }

  const handleClick = () => {
    !disabled && toggleList();
  };

  const handleFilterItems = (t) => {
    setTextToFilter(t);
    // if (selectedItem) {
    //   setSelectedItem("");
    // }
    if (props.filter) {
      setResults(
        props.items.filter((item) =>
          item.label.toLowerCase().includes(t.toLowerCase())
        )
      );
    }
  };

  React.useEffect(() => {
    setTextToFilter(text);
  }, [text]);

  return (
    <Container {...props} id={props.id}>
      {props.readOnly ? (
        <Row paddingBottom="10" paddingTop="5">
          <Label>{text}</Label>
        </Row>
      ) : (
        <Column onMouseLeave={handleMouseLeave}>
          <ButtonContainer
            className={isListVisible ? "focus" : ""}
            {...props}
            id={`${props.id}-button`}
            data-name={props.dataNameButton || "select-button"}
          >
            <Row expand fit onClick={handleClick}>
              {props.trigger ||
                (props.filter ? (
                  <Row expand middle marginLeft="20">
                    <Input
                      className="filter-input"
                      placeholder={props.placeholder}
                      small
                      expand
                      value={textToFilter}
                      onChange={(e) => handleFilterItems(e.target.value)}
                    />
                    <Row noShrink paddingLeft="10">
                      <Icon name="arrowDropDown" />
                    </Row>
                  </Row>
                ) : (
                  <Row expand middle>
                    {props.label && (
                      <Row paddingLeft noShrink>
                        <Label bold truncate>
                          {props.label}
                        </Label>
                      </Row>
                    )}
                    {!props.labelOnly && (
                      <Row expand={props.expand}>
                        {text ? (
                          <Input
                            paddingLeft={props.label ? "10" : "20"}
                            small
                            expand={props.expand}
                            value={text}
                            readOnly
                            onFocus={() =>
                              !disabled &&
                              isListVisible &&
                              setListVisibility(true)
                            }
                          />
                        ) : (
                          <Row expand={props.expand}>
                            {props.placeholder && (
                              <Input
                                small
                                paddingLeft={props.label ? "10" : "20"}
                                expand={props.expand}
                                placeholder={props.placeholder}
                                readOnly
                                onFocus={() =>
                                  !disabled &&
                                  isListVisible &&
                                  setListVisibility(true)
                                }
                              />
                            )}
                          </Row>
                        )}
                      </Row>
                    )}
                    <Row noShrink paddingLeft="10">
                      <Icon name="arrowDropDown" />
                    </Row>
                  </Row>
                ))}
            </Row>
            {props.onClear && selectedItem && (
              <Row
                noShrink
                paddingLeft="5"
                marginRight="-5"
                onClick={() => {
                  setSelectedItem("");
                  props.filter && setTextToFilter("");
                  props.onClear();
                }}
              >
                <Icon name="close" color="gray" fade="8" size={16} />
              </Row>
            )}
          </ButtonContainer>

          {results?.length > 0 ? (
            <Options
              {...props}
              id={`${props.id}-options`}
              className={isListVisible ? "visible" : ""}
            >
              {results.map((item, i) => (
                <Option
                  id={`${props.id}-${item.id}`}
                  className={
                    props.select && item.id === selectedItem?.id ? "active" : ""
                  }
                  border={item.border}
                  key={item.id}
                  onClick={() => changeSelectedItem(i)}
                >
                  <ConditionalWrapper
                    condition={item.to}
                    wrapper={(children) => <Link to={item.to}>{children}</Link>}
                  >
                    {props.select && (
                      <Row noWrap expand middle>
                        {item.id === selectedItem?.id ? (
                          <Row marginLeft="10" marginRight="6">
                            <Icon name="check" color="green" fade="6" />
                          </Row>
                        ) : (
                          <Row marginLeft="40" />
                        )}
                        <input
                          tabIndex="-1"
                          value={item && item.label ? item.label : ""}
                          ref={setRef(`resultItem-${i}`)}
                          readOnly
                        />
                      </Row>
                    )}
                    {props.multiple && (
                      <Row noWrap expand middle>
                        <Row relative marginLeft="10" marginRight="6">
                          {selectedItems.find((a) => a.id === item.id) ? (
                            <Icon name="checkFilled" color="green" fade="5" />
                          ) : (
                            <Icon name="checkContainer" color="gray" fade="7" />
                          )}
                        </Row>
                        <input
                          tabIndex="-1"
                          value={item && item.label ? item.label : ""}
                          ref={setRef(`resultItem-${i}`)}
                          readOnly
                        />
                      </Row>
                    )}

                    {!props.select && !props.multiple && (
                      <Row paddingLeft="16" expand>
                        <input
                          tabIndex="-1"
                          value={item && item.label ? item.label : ""}
                          ref={setRef(`resultItem-${i}`)}
                          readOnly
                        />
                      </Row>
                    )}
                  </ConditionalWrapper>
                </Option>
              ))}
              {props.children}
            </Options>
          ) : (
            <Options
              {...props}
              id={`${props.id}-options`}
              className={isListVisible ? "visible" : ""}
              onClick={() => setListVisibility(false)}
            >
              <Row marginLeft marginY="10">
                <Label>No options found</Label>
              </Row>
            </Options>
          )}
        </Column>
      )}
      {isListVisible && (
        <CloseWrapper onClick={() => setListVisibility(false)} />
      )}
    </Container>
  );
};

const CloseWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 10;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "400px")};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
  width: 100%;

  & > div {
    position: relative;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    !props.trigger &&
    css`
      background-color: ${Color("gray", 2)};
      border-radius: 15px;
      padding: 0 16px 0 0;
    `}
  height: 44px;
  min-width: ${(props) => (props.width ? props.width + "px" : "unset")};

  .input-wrapper {
    padding: 0;
  }

  input {
    cursor: pointer;
  }

  ${(props) =>
    !props.trigger &&
    css`
      .filter-input {
        background-color: ${Color("gray", 2)};
      }

      &:hover,
      &.focus {
        background-color: ${Color("gray", 3)};
        .input-wrapper {
          background-color: ${Color("gray", 3)};
        }
        .filter-input {
          background-color: ${Color("gray", 3)};
        }
      }

      &:focus {
        outline: 0;
        background-color: ${Color("gray", 3)};
      }
    `}
`;

export const Options = styled.div`
  position: absolute;
  top: ${(props) => props.top || "8px"};
  ${(props) => (props.right ? "right: 0" : "left: 0")};
  min-width: ${(props) => (props.width ? props.width + "px" : "200px")};
  width: ${(props) => (props.width ? props.width : "100%")};
  ${(props) => !props.autoHeight && "max-height: 290px"};
  overflow-y: auto;
  display: block;
  background-color: white;
  border-radius: 15px;
  color: white;
  padding: 10px 0;
  opacity: 0;
  z-index: 12;
  transform: translateY(20px);
  border: 1px solid ${Color("gray", "1")};
  box-shadow: 0px 10px 10px rgba(54, 65, 241, 0.1),
    0px 5px 5px rgba(0, 0, 0, 0.15);

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  transition: all 0.2s ease;
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: translateY(
      ${(props) => (props.medium ? "64px" : props.static ? "44px" : "36px")}
    );
    pointer-events: all;
  }
`;

const Option = styled.span`
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  ${(props) =>
    props.border &&
    `
      margin-bottom: 20px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        background: ${Color("gray", "3")};
        height: 1px;
        left: 0;
        right: 0;
        bottom: -10px;
      }
    `};

  &.active,
  &:focus,
  &:hover {
    background: ${Color("gray", "1")};
  }

  input {
    background: transparent;
    border: none;
    color: black;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 14px 12px 6px;
    width: 100%;
    min-width: 18px;
    cursor: pointer;
    ${(props) => props.center && `text-align: center`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: 0;
      color: ${Color("green", "6")};
    }
  }
`;

Dropdown.propTypes = {
  items: PropTypes.array,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  disabled: PropTypes.bool,
  icons: PropTypes.array,
  label: PropTypes.string,
};
